(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetDialogController',
			AssetDialogController)

	.directive("fileread", [ function() {
		'use strict';

		return {
			restrict : "A",

			link : function($scope, element) {

				element.change(function(event) {
					$scope.$apply(function() {
						$scope.myFile = element[0].files[0];
						$scope.asset = $scope.assetname;

					});
				});
			}

		};
	} ]);

	AssetDialogController.$inject = [ '$timeout', '$scope', '$stateParams','AssetImages','AlertService',
			'entity', 'Asset', 'User', 'AssetType', '$state', 'AssetUpload',
			'AllAssetsTypes', 'AllCustomAssetTypeAttribute', 'AssetIDs', 'AssetByID'];

	function AssetDialogController($timeout, $scope, $stateParams, AssetImages, AlertService, entity,
			Asset, User, AssetType, $state, AssetUpload, AllAssetsTypes,
			AllCustomAssetTypeAttribute, AssetIDs, AssetByID) {
		var vm = this;

		vm.asset = entity;
		var entity_old = angular.copy(vm.asset);
		vm.clear = clear;
		vm.save = save;
		vm.assetType = entity.assetType;

		vm.upload = upload;
		vm.decodeImage = decodeImage;
		vm.assettypes = loadPage();
		vm.selectattributes = selectedAssetTypeAttributes;
		vm.coOrdinates = [];
		vm.coOrdinate = null;
		var newFence = false;
		var latlng;
		var locations = {};
		var centerlatlng = null;
		vm.customAttributesMap = {};
		var formData;
		var spreadAssets = [];
		vm.asset.assetImages = [];
		
		function playSound(url) {
		    var a = new Audio(url);
		    a.play();
		}
		
		$scope.allimages = false;
		$scope
		$scope.toggle = function() {
			$scope.allimages = !$scope.allimages;
			// console.log($scope.allimages);
			if ($scope.allimages) {

				AssetImages.query({
					assetId : vm.asset.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {

					vm.assetImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}

			}
		};
		
		function decodeImage(img) {
			return window.atob(img);
		}
		

		function beep()
        {
            try
            {
                var snd = new Audio("data:audio/wav;rnd=" + getRandom(1,100000) + ";base64,<<raw base64 data>>");
                snd.load();
                snd.currentTime = 0;
                snd.play();
            }
            catch(e)
            {
                alert(e);
            }
        }

        function getRandom(min, max) {
            return Math.random() * (max - min) + min;
        }
        
		function loadPage() {
			
			
			
			vm.multiassets = [];
			var selctedAssets = [];

			if (vm.asset.id != null) {
				vm.asset.ownedBy.forEach(function(asset) {
					selctedAssets.push(asset.id);
				})
			}

			var assetTypeAttributeValuesList = vm.asset.assetTypeAttributeValues;

			try {
				for ( var index in vm.asset.assetType.assetTypeAttributes) {
					if(!checkIfAssetTypeExists(vm.asset.assetType.assetTypeAttributes[index], vm.asset.assetTypeAttributeValues)){
						var assetTypeAttribute1 = vm.asset.assetType.assetTypeAttributes[index];
						assetTypeAttributeValuesList.push({'assetTypeAttribute':assetTypeAttribute1, 'attributeValue':vm.asset.assetType.assetTypeAttributes[index].defaultValue});
					}
				}
			} catch (e) {}

			function checkIfAssetTypeExists(assetType, assetTypeAttrVal){
				  var hasAssetType = false;
				  for(var i=0; i<assetTypeAttrVal.length; i++){
					  var name = assetTypeAttrVal[i].assetTypeAttribute.name;
					  if(name == assetType.name){
						  hasAssetType = true;
						  break;
					  }
				  }
				  return hasAssetType;
			}
			
			assetTypeAttributeValuesList
					.sort(function(a, b) {
						var nameA = a.assetTypeAttribute.name.toLowerCase(), nameB = b.assetTypeAttribute.name
								.toLowerCase()
						if (nameA < nameB) // sort string ascending
							return -1
						if (nameA > nameB)
							return 1
						return 0 // default return value (no sorting)
					});
			vm.asset.assetTypeAttributeValues = assetTypeAttributeValuesList;

			AssetIDs.getAll(function(response) {
				response.forEach(function(asset) {
					if (selctedAssets.indexOf(asset.id) !== -1) {
						vm.multiassets.push({
							lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
							lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
							name : asset.name,
							assetType : asset.assetType,
							assetCoordinates : asset.assetCoordinates,
							id : asset.id,
							ownedBy : asset.ownedBy,
							ticked : true
						});
					} else {
						try {
							vm.multiassets.push({
								lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
								lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
								name : asset.name,
								assetType : asset.assetType,
								assetCoordinates : asset.assetCoordinates,
								id : asset.id,
								ownedBy : asset.ownedBy,
								ticked : false
							});
						} catch (e) {
							// TODO: handle exception
						}
						
					}
				});
			});
			


			AllAssetsTypes.getAll(function(response) {
				vm.assettypes = response;
			});

			AllCustomAssetTypeAttribute
					.getAll(function(response) {
						// vm.customCaseTypeAttributes = response;
						response
								.forEach(function(arrayItem) {
									vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
								});

					});
		}

		function upload() {
			formData = new FormData();

			formData.append("assetname", $scope.assetname);
			formData.append('file', $scope.myFile);

			AssetUpload.assetupload(formData, function(response, headers) {
				if (response.status == 200) {
					vm.error = null;
					vm.success = 'OK';
					$scope.spreadassetname = '';

					$timeout(function() {
						vm.success = '';
					}, 4000);

				}
				if (response.status == 400) {
					vm.success = null;
					vm.error = 'error';

				}
			});

		}

		$(":file").filestyle({
			buttonBefore : true
		});
		// $(":file").filestyle('placeholder', 'Choose File');
		$(":file").filestyle('buttonText', 'Browse File');

//		$scope.importxlsx = /^(.*?)\.(xlsx)$/;
		$scope.importxlsx = /^(.*?)\.(xlsx|kml)$/;

		/* import end */
		vm.createNewFence = function() {
			newFence = true;
			vm.asset.assetCoordinates = [];
			vm.createfence();
		}

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		$("#assetImages").change(function() {

			var filesSelected = document.getElementById("assetImages").files;
			for (var i = 0; i < filesSelected.length; i++) {
				var fileReader = new FileReader();

				fileReader.onload = function(fileLoadedEvent) {
					vm.asset.assetImages.push({
						image : window.btoa(fileLoadedEvent.target.result),
						asset : {
							id : entity_old.id
						}
					});
				}
				fileReader.readAsBinaryString(filesSelected[i]);
			}

		});
		
		$scope.toggle = function() {
			if($('#soundtrack') != null){
				if($('#soundtrack').size >= 1){
					$('#soundtrack').get(0).play();
				}
			}
			$scope.allimages = !$scope.allimages;
			if ($scope.allimages) {

				AssetImages.query({
					assetId : vm.asset.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.assetImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}

			}
		};
		
		function save() {
			vm.isSaving = true;
			if (vm.asset.id !== null) {
				Asset.update(vm.asset, onSaveSuccess, onSaveError);
			} else {
				Asset.save(vm.asset, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:assetUpdate', result);
			vm.isSaving = false;
			$state.go('asset');
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function selectedAssetTypeAttributes() {
			if (vm.assetType) {
				vm.asset.assetType = vm.assetType;
				if (entity_old.assetType
						&& vm.assetType.id === entity_old.assetType.id) {
					vm.asset.assetTypeAttributeValues = [];
					vm.asset.assetTypeAttributeValues = entity_old.assetTypeAttributeValues;
				} else {
					vm.asset.assetTypeAttributeValues = [];
					$.each(
						vm.assetType.assetTypeAttributes,
						function(key, value) {
							vm.asset.assetTypeAttributeValues
								.push({
									assetTypeAttribute : vm.assetType.assetTypeAttributes[key]
								});
						});
				}

			}

		}
		vm.createfence = function() {
			var elem = document.getElementById("map_canvas");

			 if (elem.requestFullscreen) {
			   elem.requestFullscreen();
			 } else if (elem.mozRequestFullScreen) { /* Firefox */
			   elem.mozRequestFullScreen();
			 } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
			   elem.webkitRequestFullscreen();
			 } else if (elem.msRequestFullscreen) { /* IE/Edge */
			   elem.msRequestFullscreen();
			 }
			if (vm.asset.ownedBy == "") {
				var map = new google.maps.Map(document
						.getElementById('map_canvas'), {
					center : {
						lat : 12.9716,
						lng : 77.5946
					},
					zoom : 13,
					fullscreenControl: true
				});
			} else {
				var coordinates = [];
				for (var i = 0; i < vm.multiassets.length; i++) {

					if (vm.multiassets[i].id == vm.asset.ownedBy[0].id) {
						coordinates.push({
							lat : vm.multiassets[i].lat,
							lng : vm.multiassets[i].lng
						});
					}
				}
				var map = new google.maps.Map(document
						.getElementById('map_canvas'), {
					center : coordinates[0],
					zoom : 13,
					fullscreenControl: true
				});
			}
			
			if (vm.assetType.layout == 'SPREAD') {
				drawingManagerSpread();
				drawingManagerValue('polylinecomplete', map);
			} else if (vm.assetType.layout == 'FIXED') {
				if (vm.asset.ownedBy == "") {
					drawingManagerFixed();
					drawingManagerValue('click', map);
					
				}else{
					drawingManagerSpread();
					drawingManagerValue('polylinecomplete', map);
				}
			}
			
			if (entity.id && entity.id != null && !newFence) {
				if (vm.assetType.layout == 'SPREAD') {
					drawPolyline(function(polyline) {
						polyline.setMap(map);
						google.maps.event.addListener(polyline.getPath(),
								'insert_at', function() {
									setGoefenceCoordinate(polyline);
								});
						google.maps.event.addListener(polyline.getPath(),
								'set_at', function(path) {
									setGoefenceCoordinate(polyline);
								});

						vm.drawingManager.setOptions({
							drawingControl : false
						});
						vm.drawingManager.setMap(null);

					})
				} else if (vm.assetType.layout == 'FIXED') {
					if(vm.asset.assetCoordinates.length > 1){
						drawPolyline(function(polyline) {
							polyline.setMap(map);
							google.maps.event.addListener(polyline.getPath(),
									'insert_at', function() {
										setGoefenceCoordinate(polyline);
									});
							google.maps.event.addListener(polyline.getPath(),
									'set_at', function(path) {
										setGoefenceCoordinate(polyline);
									});

							vm.drawingManager.setOptions({
								drawingControl : false
							});
							vm.drawingManager.setMap(null);

						})
					}else{
						var latitudelongitude = {
								latitude : vm.asset.assetCoordinates.latitude,
								longitude : vm.asset.assetCoordinates.longitude
							};

							setValues();
							drawingManagerFixed();
							drawMarker(map, vm.coOrdinate);
							// drawFixedAssetImages(vm.coOrdinate);
					}
				}

			}
		

			function drawPolyline(callback) {

				setValues();
				JSON.parse(vm.coOrdinate).forEach(function(value, key) {
					centerlatlng = {
						lat : value.lat,
						lng : value.lng
					};
				});
				callback(new google.maps.Polyline({
					path : JSON.parse(vm.coOrdinate),
					geodesic : true,
					strokeColor : vm.asset.assetType.colorcode,
					strokeOpacity : 0.8,
					strokeWeight : 2,
					fillColor : vm.asset.assetType.colorcode,
					fillOpacity : 0.3,
					editable : true
				}));
				map.setCenter(centerlatlng);
			}
			
			spreadAssets = [];
			var multiAssetList = vm.multiassets;
			var latlngs = [];
			for (var i = 0; i < multiAssetList.length; i++) {
				var assetCoordinateValues = multiAssetList[i].assetCoordinates;
				latlngs.push({
						lat : assetCoordinateValues[assetCoordinateValues.length-1].latitude,
						lng : assetCoordinateValues[assetCoordinateValues.length-1].longitude,
						name: multiAssetList[i].name
				});
				drawPolylineForGeneratedLoc(assetCoordinateValues, map, "yellow");
			}
			
			for (var i = 0; i < latlngs.length; i++) {
				var myLatLng = ({lat:latlngs[i].lat, lng:latlngs[i].lng});
				createMarkerOfAllAssets(myLatLng, map, latlngs[i].name);
			}
				
			for (var i = 0; i < multiAssetList.length; i++) {
				var array_element = multiAssetList[i];
				if(array_element.assetType.layout == "SPREAD"){
					spreadAssets.push(array_element);
				}
			}
			for (var i = 0; i < spreadAssets.length; i++) {
				var spreadAssetAC = spreadAssets[i].assetCoordinates;
				for(var j = 0; j < spreadAssetAC.length;j++){
                  if(j > 0){
                      var locValues =[];
                      locValues.push(spreadAssetAC[j-1]);
                      locValues.push(spreadAssetAC[j]);
                      drawPolylineForGeneratedLoc(locValues,map, "black");
                  }
              }
			}

		};

		function createMarkerOfAllAssets(myLatLng, map, myLocName, index) {
				var marker = new google.maps.Marker(
						{
							position : myLatLng,
							map : map,
							icon : {
								scaledSize : new google.maps.Size(24, 24),
								origin : new google.maps.Point(0, 0),
								url :'content/images/yellow.png'
							},
							label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: myLocName, backgroundcolor: "#ff9800" },
						});
				
		}
		
		function drawingManagerSpread() {
			vm.drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode : google.maps.drawing.OverlayType.POLYLINE,
				drawingControl : true,
				drawingControlOptions : {
					position : google.maps.ControlPosition.TOP_CENTER,
					drawingModes : [ 'polyline' ]
				},
				polylineOptions : {
					fillColor : '#ffff00',
					fillOpacity : 1,
					strokeWeight : 5,
					clickable : true,
				
					editable : true,
					zIndex : 1,
					geodesic : true
				}
			});
		}

		function drawingManagerFixed() {
			vm.drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode : google.maps.drawing.OverlayType.MARKER,
				drawingControl : true,
				drawingControlOptions : {
					position : google.maps.ControlPosition.TOP_CENTER,
					drawingModes : [ 'marker' ]
				},
				markerOptions : {
					icon : {
						size : new google.maps.Size(220, 220),
						scaledSize : new google.maps.Size(32, 32),
						origin : new google.maps.Point(0, 0),
						// url : "data:image/png;base64," +
						// vm.asset.assetType.image,
						// url : vm.asset.assetType.imagePath,
						anchor : new google.maps.Point(16, 16)
					},
				}

			});
		}

		var recursiveOwnedByAsset = function(ownedBy) {
			var names = [];
			if(ownedBy.length > 0){
				if(ownedBy[0].ownedBy != null){
					if (ownedBy[0].ownedBy.length > 0) {
						var assetCoordinateValues2 = ownedBy[0].ownedBy;
						names
								.push({
									lat : assetCoordinateValues2[0].assetCoordinates[0].latitude,
									lng : assetCoordinateValues2[0].assetCoordinates[0].longitude
								});
						if (assetCoordinateValues2.length > 0) {
							Array.prototype.push.apply(names,
									recursiveOwnedByAsset(assetCoordinateValues2));
						}
					}
				}
			}
			return names;
		};

		function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function(index, value) {

                spreadAssetCoordinates.push({
                    lat : value.latitude,
                    lng : value.longitude
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                        lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
                        lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
                    };
                var infowindow = new google.maps.InfoWindow(
                        {
                            content : '<div class="infoWindowhead">'
                                    + "name"
                                    + '<div class="infoWindowContent"> <b>Description :</b> '
                                    + "des" + '</div></div>'
                        });
                infowindow.setPosition(midLatLng);

                var ColorValue = '#ff0000';
                if(color == "red"){
                    ColorValue = '#ff0000';
                }else if(color == "black"){
                	ColorValue = '#000000';
                }else if(color == "yellow"){
                	ColorValue = '#F4A500';
                }else{
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path : spreadAssetCoordinates,
                    geodesic : true,
                    strokeColor : ColorValue,
                    strokeOpacity : 1.0,
                    strokeWeight : 2.5,
                    fillColor : ColorValue,
                    fillOpacity : 0.3,
                    editable : false
                });

                polylinepath.setOptions({
                    strokeColor : ColorValue
                });
                
                polylinepath.setMap(map);
            }
        }
		
		function drawingManagerValue(option, map) {
			var parent = {};
			for (var i = 0; i < vm.multiassets.length; i++) {
				if (vm.multiassets[i].ticked == true) {
					parent = vm.multiassets[i];
				}
			}
			AssetByID.get(parent.id,function(response) {
				parent = response;
				var latlngs = [];
				try {
					var len = parent.ownedBy;
					var assetCoordinateValuesParent = parent.assetCoordinates;
					latlngs.push({
						lat : assetCoordinateValuesParent[assetCoordinateValuesParent.length-1].latitude,
						lng : assetCoordinateValuesParent[assetCoordinateValuesParent.length-1].longitude
					});

					if (len.length > 0) {
						var assetCoordinateValues = len[0].assetCoordinates;
						latlngs.push({
							lat : assetCoordinateValues[assetCoordinateValues.length-1].latitude,
							lng : assetCoordinateValues[assetCoordinateValues.length-1].longitude
						});
						
						if (recursiveOwnedByAsset(len) != null) {
							Array.prototype.push.apply(latlngs,
									recursiveOwnedByAsset(len));
						}
					}

					for (var i = 0; i < latlngs.length; i++) {
						createMarker(latlngs[i], map, i);
					}

					if (latlngs.length > 0) {
						moveToLocation(latlngs[0].lat, latlngs[0].lng, map);
						map.setZoom(16);
					}
					
					
				} catch (err) {
					var event232 = err;
				}
			});
			
		
			if (option == 'polylinecomplete') {
				google.maps.event.addListener(vm.drawingManager, option,
						function(event) {
							google.maps.event.addListener(event.getPath(),
									'insert_at', function() {
										setGoefenceCoordinate(event);
									});
							google.maps.event.addListener(event.getPath(),
									'set_at', function(path) {
										setGoefenceCoordinate(event);
									});
							vm.drawingManager.setOptions({
								drawingControl : false
							});
							vm.drawingManager.setMap(null);
							setGoefenceCoordinate(event);
						});
				vm.drawingManager.setMap(map);
			} else if (option == 'click') {
				google.maps.event.addListener(map, 'click', function(e) {
					placeMarker(e.latLng, map);
					vm.fixedArray = [];
					var coordinates = {};
					coordinates.latitude = e.latLng.lat();
					coordinates.longitude = e.latLng.lng();
					vm.fixedArray.push(coordinates);
					vm.asset.assetCoordinates = vm.fixedArray;
				});
			}
		}

		function moveToLocation(lat, lng, map) {
			var center = new google.maps.LatLng(lat, lng);
			// using global variable:
			map.panTo(center);
		}

		function createMarker(myLatLng, map, index) {
			if (index == 0) {
				var marker = new google.maps.Marker(
						{
							position : myLatLng,
							map : map,
							icon : 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
							animation : google.maps.Animation.DROP
						});
			} else {
				var marker = new google.maps.Marker(
						{
							position : myLatLng,
							map : map,
							icon : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
						});
			}
		}

		var marker;
		function placeMarker(location, map) {

			if (marker) {
				marker.setPosition(location);
			} else {
				marker = new google.maps.Marker({
					position : location,
					map : map,
					icon : {
						size : new google.maps.Size(220, 220),
						scaledSize : new google.maps.Size(32, 32),
						origin : new google.maps.Point(0, 0),
						// url : "data:image/png;base64," +
						// vm.asset.assetType.image,
						// url : vm.asset.assetType.imagePath,
						anchor : new google.maps.Point(16, 16)
					},
				});
			}
		}
		$scope.mapEvents = {
			"click" : function() {

			}
		}

		function setGoefenceCoordinate(event) {
			vm.assetCoordinateValues = [];
			vm.asset.assetCoordinates = [];
			var radius = event.getPath();
			for (var i = 0; i < radius.getLength(); i++) {
				var coordinates = {};
				coordinates.latitude = radius.getAt(i).lat();
				coordinates.longitude = radius.getAt(i).lng();
				vm.asset.assetCoordinates.push(coordinates);
			}
		}

		function setValues() {
			vm.coOrdinates = [];
			for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
				vm.coOrdinates.push({
					lat : vm.asset.assetCoordinates[i].latitude,
					lng : vm.asset.assetCoordinates[i].longitude
				});
				if (i == vm.asset.assetCoordinates.length - 1) {
					vm.coOrdinate = JSON.stringify(vm.coOrdinates);
				}
			}
		}

		function drawMarker(map, response) {
			$.each(JSON.parse(response), function(index, value) {
				centerlatlng = {
					lat : value.lat,
					lng : value.lng
				};

				var startMarker = new google.maps.Marker({
					map : map,
					position : new google.maps.LatLng(value.lat, value.lng),

					icon : {
						size : new google.maps.Size(220, 220),
						scaledSize : new google.maps.Size(32, 32),
						origin : new google.maps.Point(0, 0),
						// url : "data:image/png;base64," +
						// vm.asset.assetType.image,
						// url : vm.asset.assetType.imagePath,
						anchor : new google.maps.Point(16, 16)
					},
				});
			});
			map.setCenter(centerlatlng);
		}
	}
})();
