(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetReportController', AssetReportController)

	AssetReportController.$inject = [ '$timeout', '$scope', 'Report', '$stateParams', '$q', '$filter', 'CaseType', 'AssetSearchForMap', 'AllCaseTypes' ];

	function AssetReportController($timeout, $scope, Report, $stateParams, $q, $filter, CaseType, AssetSearchForMap, AllCaseTypes) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.today = today;
		vm.today();
		vm.assetList = "";
		var usersMap = {};
		vm.caseType = "";

		vm.filterAssetsList = filterAssetsList;

		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
		}
		vm.caseTypes = loadPage();// CaseType.query();

		function loadPage() {
			AllCaseTypes.getAll(function(response) {
				vm.caseTypes = response;
			});
		}

		// Added for asset search start
		function filterAssetsList() {
			if (vm.assetType != null && vm.assetType != "" && !angular.isUndefined(vm.assetType)) {
				AssetSearchForMap.searchassetsformap(vm.assetType, function(response) {
					vm.assetList = response;
				});
			}
		}
		;
		// Added for asset search end ////

		// Added for caseType Attribute start /////
		// vm.caseType = vm.caseTypes;
		vm.selectattributes = function() {
			if (vm.caseType) {
				vm.attributeValues = [];
				$.each(vm.caseType.caseTypeAttribute, function(key, value) {
					// console.log(vm.caseType.caseTypeAttribute[key].dataType)
					if (vm.caseType.caseTypeAttribute[key].dataType === 'INTEGER' || vm.caseType.caseTypeAttribute[key].dataType === 'FLOAT')
						vm.attributeValues.push({
							caseTypeAttribute : vm.caseType.caseTypeAttribute[key]
						});
				});
			}

		}
		// clear search fields
		$scope.clearSearch = function() {
			vm.caseType = "";
			vm.attribute = "";
		};
		// Added for caseType Attribute end ////////
		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;
		
		if (vm.fromDate > vm.toDate) {
             return false;
         }

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		$scope.assetReport = function() {

			// vm.caseType = vm.caseType.id;
			// vm.attribute = vm.attribute.caseTypeAttribute.name;
			angular.forEach(vm.assetList, function(value, key) {
				if (vm.assetType === value.name) {
					vm.assetIdReport = value.id;
				}
			});

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();

			if (vm.assetIdReport != null && vm.caseType.id == null) {
				Report.getAssetCaseTypeReport(vm.assetIdReport, vm.fromTime, vm.toTime, function(response) {
					$scope.assetBar = response;
					$scope.assetCaseCount = [];
					$scope.caseType = [];

					angular.forEach($scope.assetBar, function(value, key) {
						// console.log(value.caseType);
						// console.log(value.count);
						$scope.assetCaseCount.push(value.count);
						$scope.caseType.push(value.caseType);

					});
					// $scope.assetValue = [10, 15, 20, 25];
					// $scope.createTime = ['2017-3-9', '2017-3-10',
					// '2017-3-11', '2017-3-12' ];

					Highcharts.chart('asset_graph', {
						chart : {
							type : 'column'
						},
						title : {
							text : 'Asset Usage Report'
						},

						subtitle : {
							text : vm.assetType
						},

						yAxis : {
							title : {
								text : "Task Count"
							}
						},
						legend : {
							layout : 'vertical',
							align : 'right',
							verticalAlign : 'middle'
						},
						xAxis : {
							categories : $scope.caseType
						},

						series : [ {
							name : ' ',
							data : $scope.assetCaseCount
						} ],
						credits : {
							enabled : false
						},
						exporting : {
							buttons : {
								contextButton : {
									menuItems : [ {
										textKey : 'downloadPNG',
										onclick : function() {
											var fileName = "Asset_report";
											this.exportChart({
												filename : fileName
											});
										}
									}, {
										textKey : 'downloadPDF',
										onclick : function() {
											this.exportChart({
												type : 'application/pdf'
											});
										}
									} ]
								}
							}
						}

					});

				})
			}
			// if end
			else if (vm.assetIdReport != null && vm.caseType.id != null) {
				Report.getAssetReport(vm.assetIdReport, vm.caseType.id, vm.attribute.caseTypeAttribute.name, vm.fromTime, vm.toTime, function(response) {
					$scope.assetLine = response;
					$scope.assetValue = [];
					vm.totalcount = null;
					$scope.createTime = [];
					angular.forEach($scope.assetLine, function(value, key) {
						;
						vm.totalcount += value.value;
						// console.log(vm.totalcount)
						// console.log(value.datetime);
						$scope.assetValue.push(value.value);
						$scope.createTime.push($filter('date')(value.datetime, dateFormat));

					});
					// $scope.assetValue = [10, 15, 20, 25];
					// $scope.createTime = ['2017-3-9', '2017-3-10',
					// '2017-3-11', '2017-3-12' ];

					Highcharts.chart('asset_graph', {

						title : {
							text : 'Asset Usage Report'
						},

						subtitle : {
							text : vm.assetType
						},

						yAxis : {
							title : {
								text : vm.attribute.caseTypeAttribute.name
							}
						},
						legend : {
							title : {
								text : 'Total :' + vm.totalcount
							},
							layout : 'vertical',
							align : 'right',
							verticalAlign : 'middle'
						},
						xAxis : {
							categories : $scope.createTime
						},

						series : [ {
							name : ' ',
							data : $scope.assetValue
						} ],
						credits : {
							enabled : false
						},
						exporting : {
							buttons : {
								contextButton : {
									menuItems : [ {
										textKey : 'downloadPNG',
										onclick : function() {
											var fileName = "Asset_report";
											this.exportChart({
												filename : fileName
											});
										}
									}, {
										textKey : 'downloadPDF',
										onclick : function() {
											this.exportChart({
												type : 'application/pdf'
											});
										}
									} ]
								}
							}
						}

					});

				})
			}// else end

		}

	}
})();
