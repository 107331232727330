(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
		.state('report', {
            abstract: true,
            parent: 'app'
        })
        .state('generic-report', {
            parent: 'report',
            url: '/generic-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.generic-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/report.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
           
           
        })
        
        .state('geofence-report', {
            parent: 'report',
            url: '/geofence-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.geofence-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/geofence-report.html',
                    controller: 'GeofenceReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        
        .state('case-report', {
            parent: 'report',
            url: '/case-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.generic-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/case-report.html',
                    controller: 'CaseReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
           
           
        })
        
        .state('user-agent-report', {
            parent: 'report',
            url: '/user-agent-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.user-agent-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/user-agent-report.html',
                    controller: 'UserAgentReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

            .state('fiber-status-report', {
                parent: 'report',
                url: '/fiber-status-report',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_USER_ADMIN'],
                    pageTitle: 'trakeyeApp.report.fiber-status-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/report/fiber-status-report.html',
                        controller: 'FiberStatusReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('logSource');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                }]
                }
            })

            .state('site-status-report', {
                parent: 'report',
                url: '/site-status-report',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_USER_ADMIN'],
                    pageTitle: 'trakeyeApp.report.site-status-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/report/site-status-report.html',
                        controller: 'SiteStatusReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('logSource');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                }]
                }
            })

            .state('asset-type-geofence-report', {
                parent: 'report',
                url: '/asset-type-geofence-report',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_USER_ADMIN'],
                    pageTitle: 'trakeyeApp.report.asset-type-geofence-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/report/asset-type-geofence-report.html',
                        controller: 'AssetTypeGeofenceReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('logSource');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                }]
                }
            })

         .state('case-aging-report', {
            parent: 'report',
            url: '/case-aging-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.generic-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/case-aging-report.html',
                    controller: 'CaseAgingReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('caseAgingReport');
                    return $translate.refresh();
                }]
            }
           
           
        })
        
        .state('distance-report', {
            parent: 'report',
            url: '/distance-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.distance-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/distance-report.html',
                    controller: 'DistanceReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
           
           
        })
		
		.state('battery-report', {
            parent: 'report',
            url: '/battery-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.battery-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/battery-report.html',
                    controller: 'ReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        
        })
		.state('distance-travelled',{
			parent: 'report',
			url: '/distance-travelled',
			data: {
				authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.distance-travelled'
			},
			views: {
				'content@':{ 
					templateUrl: 'app/report/distance-travelled.html',
					controller: 'ReportController',
                    controllerAs: 'vm'
				}
			},
			resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
		})
		.state('asset-report',{
			parent: 'report',
			url: '/asset-report',
			data: {
				authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.asset-report'
			},
			views: {
				'content@':{ 
					templateUrl: 'app/report/asset-report.html',
					controller: 'AssetReportController',
                    controllerAs: 'vm'
				}
			},
			resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
		})
        
        
		.state('asset-case-report',{
			parent: 'report',
			url: '/asset-case-report',
			data: {
				authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.report.asset-case-report'
			},
			views: {
				'content@':{ 
					templateUrl: 'app/report/asset-case-report.html',
					controller: 'AssetCaseReportController',
                    controllerAs: 'vm'
				}
			},
			resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
		})
		
		
		 .state('case-tat-report', {
            parent: 'report',
            url: '/case-tat-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.tat.tat-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/case-tat-report.html',
                    controller: 'CaseTatReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('caseTatReport');
                    return $translate.refresh();
                }]
            }
           
           
        })
        
        
        .state('team-report', {
            parent: 'report',
            url: '/team-report',
            data: {
                authorities: ['ROLE_USER','ROLE_USER_ADMIN'],
                pageTitle: 'trakeyeApp.teamreport.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/report/team-report.html',
                    controller: 'TeamReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reports');
                    $translatePartialLoader.addPart('logSource');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('teamReport');
                    return $translate.refresh();
                }]
            }
           
           
        })
        
        
    }

})();
