(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('FeasibilityVerifyController', FeasibilityVerifyController);

    FeasibilityVerifyController.$inject = ['$stateParams', '$timeout','Feasibility'];

    function FeasibilityVerifyController ($stateParams, $timeout,Feasibility) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        //vm.confirmPassword = null;
        //vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
       // vm.login = LoginService.open;
       // vm.resetAccount = {};
        vm.success = null;
       
       
       // vm.emaillinkinvalid = null;
       // vm.verifyaccount=null;
        
       /* Auth.verify($stateParams.key ,function (response) {
        	vm.verifyaccount = response.data;
            
        });*/
       // $timeout(function (){angular.element('#password').focus();});
       // var geocoder = new google.maps.Geocoder();
        function finishReset() {
           // vm.doNotMatch = null;
            vm.error = null;
            
          /*  if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                }).catch(function (response) {
                    vm.success = null;
                    if(response.status === 400 && response.data === 'email link invalid'){
                   	 vm.emaillinkinvalid = 'ERROR';
                    }else{
                    	vm.error = 'ERROR';
                    }
                    
                });
            }*/
            
            
            if (navigator.geolocation) {
                var location_timeout = setTimeout("geolocFail()", 10000);

                navigator.geolocation.getCurrentPosition(function(position) {
                    clearTimeout(location_timeout);

                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
//                   alert("Your location has been shared to service provider. You will receive a confirmation about broadband connection feasibility at your location  Lat [" + lat + "]  lan ["+ lng+"]");
                    alert("Your location has been shared to service provider. You will receive a confirmation about broadband connection feasibility at your location shortly.");
                   //console.log('User location is Lat['+lat+"] lng["+lng+"");
                   Feasibility.fesibilityVerify({key: $stateParams.key, latitude: lat,longitude: lng }).then(function () {
                    vm.success = 'OK';
                }).catch(function (response) {
                    vm.success = null;
                    if(response.status === 400 && response.data === 'email link invalid'){
                   	 vm.emaillinkinvalid = 'ERROR';
                    }else{
                    	vm.error = 'ERROR';
                    }
                    
                })
                   
                   
                   // geocodeLatLng(lat, lng);
                }, function(error) {
                      alert(error.message);
                    clearTimeout(location_timeout);
                    geolocFail();
                });
            } else {
                  alert("Turn on the location service to make the deposit");
                // Fallback for no geolocation
                geolocFail();
            }
            
            
            
        }
        
        
        
        function geolocFail(){
        	 alert("Turn on the location service to Verify feasibility");
        	 document.write("Turn on the location service to Verify feasibility");
        	}
        
        function geocodeLatLng(lat, lng) {
        	var latlng = new google.maps.LatLng(lat, lng);
       	geocoder.geocode({'latLng': latlng}, function(results, status) {
        	if (status == google.maps.GeocoderStatus.OK) {
        	console.log(results)
        	if (results[1]) {
        	//formatted address
       	var add= results[0].formatted_address
        	alert(add);

        	//city data
        	//alert(city.short_name + " " + city.long_name)

        	} else {
        	alert("No results found");
        	}
        	} else {
        	alert("Geocoder failed due to: " + status);
        	}
        	});
        	}
    }
})();
