(function() {
	'use strict';

	angular.module('trakeyeApp').controller('GeofenceReportController', GeofenceReportController)

	GeofenceReportController.$inject = [ '$scope', '$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval' ];

	function GeofenceReportController($scope, $state, Report, $rootScope, $timeout, $window, $filter, $interval) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.batteryusers = [];
		$scope.reporthide = false;
		vm.select2 = select2;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		// vm.today();
		vm.select2();
		Report.getUsers('', function(response) {
			vm.batteryusers = response;

		})

		vm.geofences = loadPage();
		function loadPage(){
			Report.getGeofences('', function(response) {
				vm.geofences = response;
			})
			vm.reportType = 'geofence';
		}
		
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		$scope.report = function() {
			if (vm.reportType == 'geofence' && vm.geofence == 'all') {
				vm.id = 0;
			}
			Report.getReport({
				id : vm.id,
				fromTime : vm.fromTime,
				toTime : vm.toTime,
				reportType : vm.reportType
			}, function(response) {

				if (response instanceof Array) {
					vm.reportRecords = response;
				} else {
					vm.reportRecords = [];
					vm.reportRecords.push(response);
				}
				$scope.search = "";
				$scope.reporthide = true;
			})

		}
		vm.detailedReport = detailedReport;

		vm.selecterange = selectRange;
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		function selectRange(range) {
			if (range == 'week') {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7)
				vm.fromTime = date.getTime();

			} else if (range == 'month') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			} else if (range == 'year') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setYear(date.getFullYear() - 1);
				vm.fromTime = date.getTime();
			}
		}
		function select2() {
			$(".select2").select2();
		}

		// Added for generating pdf start
		$scope.close = function() {
			$('#genericReport').show();
			$scope.reporthide = true;
			$('#report').hide();
		}
		$scope.loader = false;
		$(function() {
			$scope.getPdf = function() {
				$scope.loader = true;
				html2canvas(reportpdf, {
					onrendered : function(canvas) {
						var img = canvas.toDataURL("image/png");
						var pdf = new jsPDF('landscape', 'mm', 'a2');
						pdf.addImage(img, 'JPEG', 0, 0);
						pdf.save($scope.pdftype);
						$scope.loader = false;
					}
				});
				$interval(function() {
				}, 1000);
			}
		});
		// Added for generating pdf end
		function detailedReport(id) {
			Report.getDetailedReport({
				id : id,
				fromTime : vm.fromTime,
				toTime : vm.toTime,
				reportType : vm.reportType
			}, function(response) {
				vm.detailedReportCounts = response;

				$('#report').show();
				$scope.reporthide = false;
				$('#genericReport').hide();

				if (vm.reportType == 'geofence') {
					$scope.pdftype = 'Geofence.pdf';
					var coordicates = JSON.parse(response.coordinates);
					var map = new google.maps.Map(document.getElementById('map_canvas'), {
						zoom : 13,
						center : new google.maps.LatLng(coordicates[0].lat, coordicates[0].lng)
					});

					var polygon = new google.maps.Polygon({
						path : coordicates,
						strokeColor : "#ff0000",
						strokeOpacity : 0.8,
						strokeWeight : 2,
						fillColor : "#ff0000",
						fillOpacity : 0.3,
						editable : true
					})
					polygon.setMap(map);
					setTimeout(function() {
						google.maps.event.trigger(map, "resize");
						map.setCenter(new google.maps.LatLng(coordicates[0].lat, coordicates[0].lng));
					}, 100);
				}

			})
		}

		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		$scope.batteryreport = function() {
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();

			Report.getbatteryReport(vm.userid, vm.fromTime, vm.toTime, function(response) {
				$scope.batteryline = response;
				$scope.battery = [];
				$scope.createTime = [];
				angular.forEach($scope.batteryline, function(value, key) {
					$scope.battery.push(value.batteryValue);
					$scope.createTime.push($filter('date')(value.createTime, dateFormat));
				});

				Highcharts.chart('battery_graph', {

					title : {
						text : 'Battery Usage Report'
					},

					subtitle : {
						text : ''
					},

					yAxis : {
						title : {
							text : 'Battery Percentage'
						}
					},
					legend : {
						layout : 'vertical',
						align : 'right',
						verticalAlign : 'middle'
					},
					xAxis : {
						categories : $scope.createTime
					},

					series : [ {
						name : ' ',
						data : $scope.battery
					} ],
					credits : {
						enabled : false
					},
					exporting : {
						buttons : {
							contextButton : {
								menuItems : [ {
									textKey : 'downloadPNG',
									onclick : function() {
										var fileName = "battery_report";
										this.exportChart({
											filename : fileName
										});
									}
								}, {
									textKey : 'downloadPDF',
									onclick : function() {
										this.exportChart({
											type : 'application/pdf'
										});
									}
								} ]
							}
						}
					}

				});

			})
		}

		$scope.distancereport = function() {
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();

			Report.getDistanceReport(vm.userid, vm.fromTime, vm.toTime, function(response) {
				console.log(vm.fromTime);
				$scope.distanceData = response;
				$scope.distance = [];
				$scope.date = [];

				angular.forEach($scope.distanceData, function(value, key) {
					$scope.distance.push(value.distance);

					// $scope.date.push($filter('date')(value.date,"dd EEE"));
					$scope.date.push(value.date);
					// console.log(value.date);
					// console.log($filter('date')(value.date,dateFormat));
				});

				console.log($scope.distanceData);
				console.log(JSON.stringify($scope.distanceData));
				console.log($scope.distance)
				console.log($scope.date)
				Highcharts.chart('distance_graph', {

					title : {
						text : 'Distance Travelled Report'
					},

					subtitle : {
						text : ''
					},

					yAxis : {
						title : {
							text : 'Distance Travelled in KM'
						}
					},
					legend : {
						layout : 'vertical',
						align : 'right',
						verticalAlign : 'middle'
					},
					xAxis : {
						categories : $scope.date
					},
					series : [ {
						name : 'Distance in km',
						data : $scope.distance
					} ],
					credits : {
						enabled : false
					},
					exporting : {
						buttons : {
							contextButton : {
								menuItems : [ {
									textKey : 'downloadPNG',
									onclick : function() {
										var fileName = "distance_report";
										this.exportChart({
											filename : fileName
										});
									}
								}, {
									textKey : 'downloadPDF',
									onclick : function() {
										this.exportChart({
											type : 'application/pdf'
										});
									}
								} ]
							}
						}
					}

				});

			})
		}

	}

})();
