(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrServiceDetailController', TrServiceDetailController);

    TrServiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrServiceImages'];

    function TrServiceDetailController($scope, $rootScope, $stateParams, previousState, entity, TrServiceImages) {
        var vm = this;

        vm.trService = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;

        var unsubscribe = $rootScope.$on('trakeyeApp:trServiceUpdate', function (event, result) {
            vm.trService = result;
        });
        
        $scope.$on('$destroy', unsubscribe);

        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;

            if ($scope.allimages) {
                TrServiceImages.query({
                    caseId: vm.trService.id
                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.serviceImages = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        };

        function decodeImage(img) {
            return window.atob(img);
        }
    }
})();
