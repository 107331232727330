(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrCaseDetailController', TrCaseDetailController);

    TrCaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrCase', 'FeasibilityCheck', 'User', 'CaseType', 'TrCaseImages', 'TrCaseRecordings', 'AssetsForMap', '$window'];

    function TrCaseDetailController($scope, $rootScope, $stateParams, previousState, entity, TrCase, FeasibilityCheck, User, CaseType, TrCaseImages, TrCaseRecordings, AssetsForMap, $window) {
        var vm = this;

        vm.trCase = entity;
        vm.trCase.feasibilityPath = null;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        vm.loadPage = loadPage;
        var openedInfoWindow = null;
        loadPage();
        
        
        function loadPage(page) {
        	if(vm.trCase.caseType.name == "Feasibility Path Case"){
	            var caseTypeAttributeValues = vm.trCase.caseTypeAttributeValues;
	            caseTypeAttributeValues.forEach(function(value){
	            	if(value.caseTypeAttribute.name == "CustId"){
	            		FeasibilityCheck.get({
	                        id: value.attributeValue
	                    }, onSuccess);

	                    function onSuccess(data) {
	                    	vm.trCase.feasibilityUser = data;
	                    }
	            	}
	            });
        	}
        }
        
        $scope.openMapModal = function () {
        	var value = vm.trCase.feasibilityUser;
        	if(value.latitude != null && value.longitude!=null){
        		
	        	var latlng = {lat: value.latitude, lng: value.longitude};
	        	 
	            $("#nearbyAssetsModal").show();
	            var myCenter = new google.maps.LatLng(12.909515, 77.646717);
	            var ele = document.getElementById("nearbyAssetsModal");
	            $(ele).modal('show');
	            //
	            var locMap = new google.maps.Map(document
	                .getElementById('map_canvas_nearby_assets'), {
	                    center: myCenter,
	                    zoom: 14
	                });
	
	            var userLocation = new google.maps.LatLng(latlng.lat, latlng.lng);
	            
	            drawPolylineForSelectedPath(userLocation,value.nearestAssetPath, locMap, "red", value);	
	            
	            AssetsForMap.assetsformap(function (response) {
 	            	if(response != null && response.length > 0){
 	        			response.forEach(function(value) {
 	        				if (value.assetType != null && value.assetType.layout == 'FIXED') {
 								drawFixedAsset(value, locMap);
 							}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
 								if(value.assetCoordinates.length > 0){
 									drawPolyline(value, locMap);
 								}
 							}
 	        			});
 	            	}
 	            });
	            
	            $('#nearbyAssetsModal').on('show.bs.modal', function (event) {
	                $("#location-map-nearby-assets").css("width", "100%");
	                $("#map_canvas_nearby_assets").css("width", "100%");
	            });
        	 }else{
             	console.log(value.customerName + " - Location details missing!!!");
             }
        }
        
        $scope.openAssetMapModal = function () {
        	var value = vm.trCase.asset;
        	if(vm.trCase.asset.assetCoordinates.length > 0){
        		$("#assetsModal").show();
	            var myCenter = new google.maps.LatLng(vm.trCase.pinLat, vm.trCase.pinLong);
	            var ele = document.getElementById("assetsModal");
	            $(ele).modal('show');
	            //
	            var locMap = new google.maps.Map(document
	                .getElementById('map_canvas_assets'), {
	                    center: myCenter,
	                    zoom: 14
	                });
	
	            var markerA = new google.maps.Marker({
                    position: myCenter,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    map: locMap
                });
                
                var markerContent = '<div class="infoWindowhead"><b>'+ vm.trCase.description
    			+ '</b></div><div class="infoWindowContent">'
    			+'<b>Lat : </b>'+ vm.trCase.pinLat
    			+'<br><b>Lng : </b>'+ vm.trCase.pinLong
    			+'</div>';
                
                var infoWindow1 = new google.maps.InfoWindow({
                	content: markerContent
                });
                
    	        markerA.addListener('click', function () {
    	        	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
    	        	infoWindow1.open(map, markerA);
    	        	openedInfoWindow = infoWindow1;
    	        });
    	        
	            if(value.assetType != null){
	            	if (value.assetType != null && value.assetType.layout == 'FIXED') {
						drawFixedAsset(value, locMap);
					}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
						if(value.assetCoordinates.length > 0){
							drawPolyline(value, locMap);
						}
					}
	            }
	            
	            $('#assetsModal').on('show.bs.modal', function (event) {
	                $("#location-map-assets").css("width", "100%");
	                $("#map_canvas_assets").css("width", "100%");
	            });
        	 }else{
             	console.log(value.customerName + " - Location details missing!!!");
             }
        }
        
        function drawFixedAsset(fixed, locMap) {
        	
        	if(fixed.assetCoordinates.length > 0){
        		 if (openedInfoWindow != null) {
                     openedInfoWindow.close();
                 }
        		var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(fixed.assetCoordinates[0].latitude, fixed.assetCoordinates[0].longitude),
                    map: locMap,
                    icon: {
                        scaledSize: new google.maps.Size(
                            30, 30),
                        origin: new google.maps.Point(
                            0, 0),
                        url: fixed.assetType.imagePath,
                        anchor: new google.maps.Point(
                            16, 16),

                    }
                 });
                 
                 marker.setMap(locMap);
                 
                 var infowindow = new google.maps.InfoWindow({
                    content:fixed.name
                 });
     				
                 google.maps.event.addListener(marker, 'mouseover', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                     infowindow.open(locMap,marker);
                     openedInfoWindow = infowindow;
                 });
                 google.maps.event.addListener(marker, 'mouseout', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                 });
                 openedInfoWindow = infowindow;
    		}
        }
        
        function drawPolyline(spread, locMap) {

        	var polylineColor = null;
            
        	var spreadAssetCoordinates = [];
        	var assetCoordinatesList = spread.assetCoordinates;

            assetCoordinatesList.forEach(function(value) {
	              spreadAssetCoordinates.push({
		              lat: value.latitude,
		              lng: value.longitude
		          });
            });
            
            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };              

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                });

                if (spread.assetType.layout == "SPREAD") {
                    polylineColor = spread.assetType.colorcode;
                }
                infowindow.setPosition(midLatLng);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                  
                    infowindow.setContent('<div class="infoWindowhead">' +
                            spread.name +
                            '</div>'
                    );

                    infowindow.mousemove
                    infowindow.setPosition(event.latLng);
                    infowindow.open(locMap);
                    openedInfoWindow = infowindow;
                });

                if (spread.status != null && spread.status === false) {
                    polylinepath.setOptions({
                        strokeColor: polylineColor
                    });
                }

                polylinepath.setMap(locMap);
            }
        }
        
        function drawPolylineForSelectedPath(pointA, spreadAssetCoordinates, map, color, userDetails) {
            if (spreadAssetCoordinates.length > 0) {
                var markerA = new google.maps.Marker({
                    position: pointA,
                    title: "point A",
                    label: "A",
                    map: map
                });
                
                var markerContent = '<div class="infoWindowhead"><b>'+ userDetails.customerName.toUpperCase()
    			+ '</b></div><div class="infoWindowContent">'
    			+'<b>Lat : </b>'+ userDetails.latitude
    			+'<br><b>Lng : </b>'+ userDetails.longitude
    			+'<br><b>Note : </b>'+ userDetails.notes
    			+'</div>';
                
                var infoWindow1 = new google.maps.InfoWindow({
                	content: markerContent
                });
                
    	        markerA.addListener('click', function () {
    	        	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
    	        	infoWindow1.open(map, markerA);
    	        	openedInfoWindow = infoWindow1;
    	        });

    	        map.setCenter(pointA);
    	        map.setZoom(17);
    	        
                var ColorValue = '#ff0000';
                if (color == "red") {
                    ColorValue = '#ff0000';
                } else if (color == "black") {
                    ColorValue = '#000000';
                } else {
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path: JSON.parse(spreadAssetCoordinates),
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                var pointContent= document.createElement('div');
    			
                pointContent.innerHTML = '<div class="infoWindowhead">' + userDetails.nearestAssetName
    				+ '</div><div class="infoWindowContent">'
    				+'</div>';

                var infoWindow2 = new google.maps.InfoWindow({
    				content : pointContent,
    			});
                
                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);
                
                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                	infoWindow2.open(map);
                	infoWindow2.setPosition(event.latLng);
    				openedInfoWindow = infoWindow2;
                });
            }
        }
        
        var unsubscribe = $rootScope.$on('trakeyeApp:trCaseUpdate', function(event, result) {
            vm.trCase = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
          $scope.go_back = function() { 
        	  $window.history.back();
        	};
        
        $scope.allimages = false;
//		$scope
		$scope.toggle = function() {
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/case_"+vm.trCase.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrCaseImages.query({
					caseId : vm.trCase.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.caseImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		};

//		$scope.playAudio = function() {
////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/case_"+vm.trCase.id+".mp3";
//            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/case_15570572.mp3";
//	        var audio = new Audio(urlStr);
//	        audio.play();
//	    };
		
		
        function decodeImage(img){
     	   return window.atob(img);
        }
       
    }
})();
