(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('FeasibilityCheckController', FeasibilityCheckController);

    FeasibilityCheckController.$inject = ['$state', '$scope', 'FeasibilityCheck', 'AssetsForMap', 'FeasibilityCheckSearch', 'FeasibilityNearestAssets', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function FeasibilityCheckController($state, $scope, FeasibilityCheck, AssetsForMap, FeasibilityCheckSearch, FeasibilityNearestAssets, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        $scope.search = pagingParams.search;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        var openedInfoWindow = null;
        var nearestPaths = [];
        var selectUserForPath = null;

        loadAll();
        
        var locMap = null;

        function loadAll() {
            FeasibilityCheck.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.feasibilityChecks = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function hideModal() {
            $("#nearbyAssetsModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#nearbyAssetsModal").hide();
            selectUserForPath = null;
        }
        
        $scope.openMapModal = function (value) {
        	selectUserForPath = value;
        	if(value.latitude != null && value.longitude!=null){
        		
	        	var latlng = {lat: value.latitude, lng: value.longitude};
	        	 
	            $("#nearbyAssetsModal").show();
	            var myCenter = new google.maps.LatLng(12.909515, 77.646717);
	            var ele = document.getElementById("nearbyAssetsModal");
	            $(ele).modal('show');
	            //
	            locMap = new google.maps.Map(document
	                .getElementById('map_canvas_nearby_assets'), {
	                    center: myCenter,
	                    zoom: 14
	                });
	
	            var userLocation = new google.maps.LatLng(latlng.lat, latlng.lng);
	            
	            if(value.nearestAssetPath != null){
	            	drawPolylineForSelectedPath(userLocation,value.nearestAssetPath, locMap, "red", value);	
	            }else{
					var color = ['blue', 'red', 'green'];
					FeasibilityNearestAssets.update(latlng, onSuccess, onError);
					
					function onSuccess(data) {
						for (var i = 0; i < data.length; i++) {
					 		 var nearestAssetLatLng = new google.maps.LatLng(data[i].latLng.lat, data[i].latLng.lng);
					 		 var colorVal = "blue";
					 		 if(data.length <= 3){
					 			colorVal = color[i];
					 		 }
					 		 drawPathBetweenTwoPoints(userLocation, nearestAssetLatLng, locMap, colorVal, i+1, data[i], value);
						}
					}

					function onError(error) {
						AlertService.error(error.data.message);
					}
	            }
	            AssetsForMap.assetsformap(function (response) {
	            	if(response != null && response.length > 0){
	        			response.forEach(function(value) {
	        				if (value.assetType != null && value.assetType.layout == 'FIXED') {
								drawFixedAsset(value);
							}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
								if(value.assetCoordinates.length > 0){
									drawPolyline(value);
								}
							}
	        			});
	            	}
	            });
	
	            $('#nearbyAssetsModal').on('show.bs.modal', function (event) {
	
	                $("#location-map-nearby-assets").css("width", "100%");
	                $("#map_canvas_nearby_assets").css("width", "100%");
	            });
        	 }else{
             	AlertService.error(value.customerName + " - Location details missing!!!");
             }
        }
        
        function drawPolyline(spread) {

        	var polylineColor = null;
            
        	var spreadAssetCoordinates = [];
        	var assetCoordinatesList = spread.assetCoordinates;

            assetCoordinatesList.forEach(function(value) {
	              spreadAssetCoordinates.push({
		              lat: value.latitude,
		              lng: value.longitude
		          });
            });
            
            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };              

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                });

                if (spread.assetType.layout == "SPREAD") {
                    polylineColor = spread.assetType.colorcode;
                }
                infowindow.setPosition(midLatLng);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                  
                    infowindow.setContent('<div class="infoWindowhead">' +
                            spread.name +
                            '</div>'
                    );

                    infowindow.mousemove
                    infowindow.setPosition(event.latLng);
                    infowindow.open(locMap);
                    openedInfoWindow = infowindow;
                });

                if (spread.status != null && spread.status === false) {
                    polylinepath.setOptions({
                        strokeColor: polylineColor
                    });
                }

                polylinepath.setMap(locMap);
            }
        }

        function drawFixedAsset(fixed) {
        	
        	if(fixed.assetCoordinates.length > 0){
        		 if (openedInfoWindow != null) {
                     openedInfoWindow.close();
                 }
        		var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(fixed.assetCoordinates[0].latitude, fixed.assetCoordinates[0].longitude),
                    map: locMap,
                    icon: {
                        scaledSize: new google.maps.Size(
                            30, 30),
                        origin: new google.maps.Point(
                            0, 0),
                        url: fixed.assetType.imagePath,
                        anchor: new google.maps.Point(
                            16, 16),

                    }
                 });
                 
                 marker.setMap(locMap);
                 
                 var infowindow = new google.maps.InfoWindow({
                    content:fixed.name
                 });
     				
                 google.maps.event.addListener(marker, 'mouseover', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                     infowindow.open(locMap,marker);
                     openedInfoWindow = infowindow;
                 });
                 google.maps.event.addListener(marker, 'mouseout', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                 });
                 openedInfoWindow = infowindow;
    		}
        }
        
 		$scope.editPath = function () {
 			var value = selectUserForPath;
 			
 			if(value.latitude != null && value.longitude!=null){
        		
	        	var latlng = {lat: value.latitude, lng: value.longitude};
	        	 
	            $("#nearbyAssetsModal").show();
	            var myCenter = new google.maps.LatLng(12.909515, 77.646717);
	            var ele = document.getElementById("nearbyAssetsModal");
	            $(ele).modal('show');
	            //
	            locMap = new google.maps.Map(document
	                .getElementById('map_canvas_nearby_assets'), {
	                    center: myCenter,
	                    zoom: 16
	                });
	
	            var userLocation = new google.maps.LatLng(latlng.lat, latlng.lng);
	            
				var color = ['blue', 'red', 'green'];
				FeasibilityNearestAssets.update(latlng, onSuccess, onError);
				
				function onSuccess(data) {
					for (var i = 0; i < data.length; i++) {
				 		 var nearestAssetLatLng = new google.maps.LatLng(data[i].latLng.lat, data[i].latLng.lng);
				 		 var colorVal = "blue";
				 		 if(data.length <= 3){
				 			colorVal = color[i];
				 		 }
				 		 drawPathBetweenTwoPoints(userLocation, nearestAssetLatLng, locMap, colorVal, i+1, data[i], value);
					}
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				
	            AssetsForMap.assetsformap(function (response) {
	            	if(response != null && response.length > 0){
	        			response.forEach(function(value) {
	        				if (value.assetType != null && value.assetType.layout == 'FIXED') {
								drawFixedAsset(value);
							}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
								if(value.assetCoordinates.length > 0){
									drawPolyline(value);
								}
							}
	        			});
	            	}
	            });
	
	            $('#nearbyAssetsModal').on('show.bs.modal', function (event) {
	
	                $("#location-map-nearby-assets").css("width", "100%");
	                $("#map_canvas_nearby_assets").css("width", "100%");
	            });
        	 }else{
             	AlertService.error(value.customerName + " - Location details missing!!!");
             }
        };

        function drawPolylineForSelectedPath(pointA, spreadAssetCoordinates, map, color, userDetails) {
            if (spreadAssetCoordinates.length > 0) {
                var markerA = new google.maps.Marker({
                    position: pointA,
                    title: "point A",
                    label: "A",
                    map: map
                });
                
                var markerContent = '<div class="infoWindowhead"><b>'+ userDetails.customerName.toUpperCase()
    			+ '</b></div><div class="infoWindowContent">'
    			+'<b>Lat : </b>'+ userDetails.latitude
    			+'<br><b>Lng : </b>'+ userDetails.longitude
    			+'<br><b>Note : </b>'+ userDetails.notes
    			+'</div>';
                
                var infoWindow1 = new google.maps.InfoWindow({
                	content: markerContent
                });
                
    	        markerA.addListener('click', function () {
    	        	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
    	        	infoWindow1.open(map, markerA);
    	        	openedInfoWindow = infoWindow1;
    	        });

    	        map.setCenter(pointA);
    	        map.setZoom(14);
    	        
                var ColorValue = '#ff0000';
                if (color == "red") {
                    ColorValue = '#ff0000';
                } else if (color == "black") {
                    ColorValue = '#000000';
                } else {
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path: JSON.parse(spreadAssetCoordinates),
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                var pointContent= document.createElement('div');
    			
                pointContent.innerHTML = '<div class="infoWindowhead">' + userDetails.nearestAssetName
    				+ '</div><div class="infoWindowContent">'
    				+'</div>';

                var infoWindow2 = new google.maps.InfoWindow({
    				content : pointContent,
    			});
                
                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);
                
                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                	infoWindow2.open(map);
                	infoWindow2.setPosition(event.latLng);
    				openedInfoWindow = infoWindow2;
                });
            }
        }
        
        function drawPathBetweenTwoPoints(pointA, pointB, map, color, count, asset, userDetails) {
        	
            var colorCodeVal = '#0041C2';
            if (color == 'blue') {
                colorCodeVal = '#0041C2'
            } else if (color == 'red') {
                colorCodeVal = '#800000'
            } else if (color == 'green') {
                colorCodeVal = '#008000'
            }

            // Instantiate a directions service.
            var directionsService = new google.maps.DirectionsService;
            var polylineOptionsActual = new google.maps.Polyline({
                strokeColor: colorCodeVal,
                strokeOpacity: 0.5,
                strokeWeight: 4
            });
            var directionsDisplay = new google.maps.DirectionsRenderer({
                map: map,
                polylineOptions: polylineOptionsActual,
                suppressMarkers: true
            });
            
            var pointContent= document.createElement('div'), button;
			
            pointContent.innerHTML = '<div class="infoWindowhead">P' + count + ' - ' + asset.assetName
				+ '</div><div class="infoWindowContent"><b>Distance : </b>'+asset.distance
				+'<br><b>Lat : </b>'+ asset.latLng.lat
				+'<br><b>Lng : </b>'+ asset.latLng.lng
				+'</div>';
            button = pointContent.appendChild(document.createElement('input'));
            button.type = 'button';
            button.value = 'Click to select';
            button.className = "button"; 
            
            google.maps.event.addDomListener(button, 'click', function () {
            	userDetails.nearestAssetId = asset.assetId;
            	userDetails.nearestAssetName = asset.assetName;
            	userDetails.nearestAssetPath = "";
            	
            	nearestPaths.forEach(function(value){
            		if(asset.assetId == value.assetId){
            			userDetails.nearestAssetPath = convertPathArrayToString(value.path);
            		}
            	});
            	
            	FeasibilityCheck.update(userDetails, onSaveSuccess, onSaveError);
            	
            	function onSaveSuccess (result) {
            		if(result != null){
            			hideModal();
            		}
                }

                function onSaveError () {
                	AlertService.error('failure');
                }
            	
            	
            })
                
            var infoWindow2 = new google.maps.InfoWindow({
				content : pointContent,
			});
            
            var markerA = new google.maps.Marker({
                position: pointA,
                title: "point A",
                label: "A",
                map: map
            });
            
            var markerContent = '<div class="infoWindowhead"><b>'+ userDetails.customerName.toUpperCase()
			+ '</b></div><div class="infoWindowContent">'
			+'<b>Lat : </b>'+ userDetails.latitude
			+'<br><b>Lng : </b>'+ userDetails.longitude
			+'<br><b>Note : </b>'+ userDetails.notes
			+'</div>';
            
            var infoWindow1 = new google.maps.InfoWindow({
            	content: markerContent
            });
            

	        markerA.addListener('click', function () {
	        	if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
	        	infoWindow1.open(map, markerA);
	        	openedInfoWindow = infoWindow1;
	        });
	        
            var markerB = new google.maps.Marker({
                position: pointB,
                title: "point P"+count,
                label: {
                    text:"P"+count,
                    color: 'black',
                    fontSize: "12px"
                  },
                icon: {
                    scaledSize: new google.maps.Size(
                        38, 38),
                    url: 'https://maps.gstatic.com/mapfiles/ms2/micons/green.png',
                    labelOrigin: new google.maps.Point(20,12)
                },
                map: map
            });
            
            google.maps.event.addDomListener(markerB, "click", function(event) {
            	if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
				infoWindow2.open(map, markerB);
				infoWindow2.setPosition(event.latLng);
				openedInfoWindow = infoWindow2;
			});
           
            google.maps.event.addListener(polylineOptionsActual, 'mouseover', function (event) {
            	if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
            	infoWindow2.open(map, markerB);
            	infoWindow2.setPosition(event.latLng);
				openedInfoWindow = infoWindow2;
            });

            google.maps.event.addListener(map, "click", function() {
            	if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
            });
            calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, asset.assetId);
        }

        function calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, assetId) {
			directionsService.route({
		        origin: pointA,
		        destination: pointB,
		        avoidTolls: true,
		        avoidHighways: false,
		        travelMode: google.maps.TravelMode.DRIVING
		    }, function (response, status) {
		        if (status == google.maps.DirectionsStatus.OK) {
		            directionsDisplay.setDirections(response);
		            nearestPaths.push({assetId:assetId,path: response.routes[0].overview_path});
		        } else {
		            window.alert('Directions request failed due to ' + status);
		        }
		    });
        }

		function convertPathArrayToString(path){
			var coOrdinates=[];
		
			for (var i =0; i < path.length; i++) {
				coOrdinates.push({ lat:path[i].lat(),lng:path[i].lng()}); 
			}
			return JSON.stringify(coOrdinates);
		}
	      
        function filterSearch() {
            if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
                FeasibilityCheckSearch.query({
                    searchText: $scope.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.feasibilityChecks = data;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

            } else {
                loadAll();
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search,
            });
            
        }
       
    }
})();
