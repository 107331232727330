(function() {
	'use strict';

	angular.module('trakeyeApp').controller('TrCaseController', TrCaseController);

	TrCaseController.$inject = [ '$filter', '$scope', '$http', '$state', 'TrCase', '$compile', 'ParseLinks', 'AlertService', '$stateParams', 'pagingParams', 'paginationConstants', 'TrCaseUser',
			'TrCaseSearch', 'TrCasePriority', 'TrCasePrioritySearch', 'TrCaseEdit','TrCaseForMap','TrCaseSearchForMap','TrCaseLastLocation' ,'$timeout', '$interval' ];
	var priority;
	function TrCaseController($filter, $scope, $http, $state, TrCase, $compile, ParseLinks, AlertService, $stateParams, pagingParams, paginationConstants, TrCaseUser, TrCaseSearch, TrCasePriority,
			TrCasePrioritySearch, TrCaseEdit,TrCaseForMap, TrCaseSearchForMap, TrCaseLastLocation ,$timeout, $interval) {
		var vm = this;
		$scope.search = pagingParams.search;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.filterSearch = filterSearch;
		vm.filterSearchMap = filterSearchMap;
		// vm.trCases = [];
		var latlng;
		var userPaths = {};
		var locations = {};
		var nearbyAgentsMarkers = [];
		
		$scope.tab = 1;
//		$scope.$on('$destroy', function() {
//            $interval.cancel(updateUserAgentPath);
//        });
		$scope.isSet = function(tabNum) {
			return $scope.tab === tabNum;
		};

		$scope.setTab = function(newTab) {
			$scope.tab = newTab;
			if ($scope.tab == 2 || $scope.tab == 3) {
				filterSearchMap();
			}
			 else {
				vm.itemsPerPage = paginationConstants.itemsPerPage;
				filterSearch();
			}
		};
		
		if ($scope.tab == 1){
			if ($scope.search === null || $scope.search === "" || angular.isUndefined($scope.search)) {
				loadAll();
			} else {
				filterSearch();
			}
		}
		function filterSearch() {

			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				if ($stateParams.priority == undefined) {
					TrCaseSearch.query({
						search : $scope.search,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				} else {

					TrCasePrioritySearch.query({
						priority : $stateParams.priority,
						searchtext : $scope.search,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				}
				function sort() {
					var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
					if (vm.predicate !== 'id') {
						// result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					vm.trCases = data;
					vm.page = pagingParams.page;

				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			} else {
				loadAll();

			}

		}
		
		function loadAll() {
			if ($stateParams.priority == undefined) {

				TrCase.query({
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			} else {
				TrCasePriority.query({
					priority : $stateParams.priority,
					page : pagingParams.page - 1,
					size : vm.itemsPerPage,
					sort : sort()
				}, onSuccess, onError);
			}

			function sort() {
				var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					// result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.trCases = data;
				vm.page = pagingParams.page;
			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}
		
		
		function filterSearchMap() {
             var data;
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				
					TrCaseSearchForMap.searchtrcaseformap($scope.search, function(response) {
						drawResponseMap( response);
					});
				
			} else {
				if ($stateParams.priority == undefined) {
					
					TrCaseForMap.getAllCases( function(response) {
						drawResponseMap( response);
					});
				}else {
					
					TrCaseSearchForMap.prioritysearchtrcaseformap($stateParams.priority, function(response) {
						drawResponseMap( response);
					});
				}				
			}
		};
		
		function animatedMove(marker, t, current, moveto) {
		  var lat = current.lat();
		  var lng = current.lng();

		  var deltalat = (moveto.lat() - current.lat()) / 100;
		  var deltalng = (moveto.lng() - current.lng()) / 100;

		  var delay = 10 * t;
		  for (var i = 0; i < 100; i++) {
		    (function(ind) {
		      setTimeout(
		        function() {
		          var lat = marker.position.lat();
		          var lng = marker.position.lng();
		          lat += deltalat;
		          lng += deltalng;
		          latlng = new google.maps.LatLng(lat, lng);
		          marker.setPosition(latlng);
		        }, delay * ind
		      );
		    })(i)
		  }
		}
		
		function drawResponseMap(data){
			var myOptions = {
					zoom : 10,
					center : new google.maps.LatLng("12.9716", "77.5946"),
					mapTypeId : google.maps.MapTypeId.ROADMAP
				};
	
				if ($scope.tab == 2) {
	
					locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas1');
					drawLatestTrCases(data);
				} else if ($scope.tab == 3) {
	
					locations.map = new google.maps.Map(document.getElementById("map_canvas2"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas2');
					drawCasesAndNearByAgents(data);
					TrCaseLastLocation.getlastlogs( function(response) {
						var lastLocByAgents = response;
						var markerIcon = "inactive";
						var imageUrl = "";
						switch (markerIcon) {
							case "inactive":
								imageUrl = "content/images/marker_inactive.png"
								break;
							case "idle":
								imageUrl = "content/images/marker_idle.png"
								break;
							case "active":
								imageUrl = "content/images/marker_active.png"
								break;
							case "active_case":
								imageUrl = "content/images/marker_active_case.png"
								break;
							default:
								imageUrl = "content/images/green.png"
								break;
						}

						var lastLocVal = [];
						removeMarkers();
						for (var i = 0; i < lastLocByAgents.length; i++) {
							var path = lastLocByAgents[i].path;
							if(path.length > 1){
									var marker = new google.maps.Marker({
										position: new google.maps.LatLng(path[0].lat, path[0].lng),
										map: locations.map,
										icon : {
											scaledSize : new google.maps.Size(
													42, 40),
													origin : new google.maps.Point(0, 0),
													url : imageUrl,
													anchor : new google.maps.Point(
																	16, 16),
					
										},
										label: { color: '#000000', fontWeight: 'bold', fontSize: '16px', text: lastLocByAgents[i].userName, backgroundcolor: "#ff9800" },
										title : lastLocByAgents[i].userName
									});
//									var markerContent = '<div class="infoWindowhead">'
//										+ lastLocByAgents[i].userName
//										+ '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> '
//										+ path[0].lat
//										+ '<br> <b>Lng :</b> '
//										+ path[0].lng
//										+ '</div>';
//									var infowindow = new google.maps.InfoWindow({
//										content: markerContent
//									});
//									marker.addListener('click', function() {
//										infowindow.open(map, marker);
//									});

									animatedMove(marker, 50, marker.position, new google.maps.LatLng(path[1].lat, path[1].lng));
									nearbyAgentsMarkers.push(marker);
							}else if(path.length == 1){
								var marker = new google.maps.Marker({
									position: new google.maps.LatLng(path[0].lat, path[0].lng),
									map: locations.map,
									icon : {
										scaledSize : new google.maps.Size(
												42, 40),
												origin : new google.maps.Point(0, 0),
												url : imageUrl,
												anchor : new google.maps.Point(
																16, 16),
				
									},
									label: { color: '#000000', fontWeight: 'bold', fontSize: '16px', text: lastLocByAgents[i].userName, backgroundcolor: "#ff9800" },
									title : lastLocByAgents[i].userName
								});
							}
						}
					});
					
/*//					 var updateUserAgentPath = $interval(function(){
						TrCaseLastLocation.getlastlogs( function(response) {
							var lastLocByAgents = response;
							var markerIcon = "inactive";
							var imageUrl = "";
							switch (markerIcon) {
								case "inactive":
									imageUrl = "content/images/marker_inactive.png"
									break;
								case "idle":
									imageUrl = "content/images/marker_idle.png"
									break;
								case "active":
									imageUrl = "content/images/marker_active.png"
									break;
								case "active_case":
									imageUrl = "content/images/marker_active_case.png"
									break;
								default:
									imageUrl = "content/images/green.png"
									break;
							}

							var lastLocVal = [];
							removeMarkers();
							for (var i = 0; i < lastLocByAgents.length; i++) {
								var path = lastLocByAgents[i].path;
								if(path.length > 1){
										var marker = new google.maps.Marker({
											position: new google.maps.LatLng(path[0].lat, path[0].lng),
											map: locations.map,
											icon : {
												scaledSize : new google.maps.Size(
														42, 40),
														origin : new google.maps.Point(0, 0),
														url : imageUrl ,
														anchor : new google.maps.Point(
																		16, 16),
						
											},
											label: { color: '#000000', fontWeight: 'bold', fontSize: '16px', text: lastLocByAgents[i].userName, backgroundcolor: "#ff9800" },
											title : lastLocByAgents[i].userName
										});
//										var markerContent = '<div class="infoWindowhead">'
//											+ name
//											+ '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> '
//											+ '<br> <b>Lng :</b> '
//											+ '</div>';
//										var infowindow = new google.maps.InfoWindow({
//											content: markerContent
//										});
//										marker.addListener('click', function() {
//											infowindow.open(locations.map, marker);
//										});
										animatedMove(marker, 50, marker.position, new google.maps.LatLng(path[1].lat, path[1].lng));
										nearbyAgentsMarkers.push(marker);
									}
							}
						});
//					}, 10000);
*/				}
			
		}	
		function removeMarkers(){
		    for(var i=0; i<nearbyAgentsMarkers.length; i++){
		    	nearbyAgentsMarkers[i].setMap(null);
		    }
		    nearbyAgentsMarkers = [];
		}
		/*function filterTrcases() {
			vm.itemsPerPage = 1000;
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				if ($stateParams.priority == undefined) {

					TrCaseSearch.query({
						search : $scope.search,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				} else {
					TrCasePrioritySearch.query({
						searchtext : $scope.search,
						priority : $stateParams.priority,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				}
			} else {
				if ($stateParams.priority == undefined) {

					TrCase.query({
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				} else {
					TrCasePriority.query({
						priority : $stateParams.priority,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				}
			}
			function sort() {
				var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					// result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.page = pagingParams.page;
				var myOptions = {
					zoom : 10,
					center : new google.maps.LatLng("12.9716", "77.5946"),
					mapTypeId : google.maps.MapTypeId.ROADMAP
				};

				if ($scope.tab == 2) {

					locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas1');
					drawLatestTrCases(data);
				} else if ($scope.tab == 3) {

					locations.map = new google.maps.Map(document.getElementById("map_canvas2"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas2');
					drawCasesAndNearByAgents(data);
				}

			}
			function onError(error) {
				AlertService.error(error.data.message);
			}

		}*/
		
		
		/*if ($scope.search === null || $scope.search === "" || angular.isUndefined($scope.search)) {
			loadAll();
		} else {
			if ($scope.tab == 2) {
				filterTrcases();
				
			}
			if ($scope.tab == 3) {
				TrCaseUser.gettrcasesuser(function(response) {
					$scope.trcaseuser = response;
				});
				filterTrcases();
				
			} else if ($scope.tab == 1) {
				vm.itemsPerPage = paginationConstants.itemsPerPage;
				filterSearch();
			}
		}*/

		

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : $scope.search,
				priority : pagingParams.priority
			});
		}

		$scope.openInfoWindow = function(e, selectedMarker) {
			e.preventDefault();
			google.maps.event.trigger(selectedMarker, 'click');
		}

		

		function drawLatestTrCases(response) {

			if (response) {
				initializeTrcaseMap(response, function(latlang) {
					locations.latlng = latlang;
					// function() {
					$.each(response, function(index, value) {
						var infoWindow = new google.maps.InfoWindow();
						var startMarker = new google.maps.Marker({
							map : locations.map,
							position : new google.maps.LatLng(value.pinLat, value.pinLong),
							title : value.description,

						});
						// console.log("adding Case " + value.description);
						var infoWindow2 = new google.maps.InfoWindow();
						// if(value.userCase=='C'){
						if (value.priority == 'LOW') {
							startMarker.setIcon('content/images/Pointers-L4.png');
						} else if (value.priority == 'MEDIUM') {
							startMarker.setIcon('content/images/Pointers-M4.png');
						} else if (value.priority == 'HIGH') {
							startMarker.setIcon('content/images/Pointers-H4.png');
						} else if (value.priority == 'CRITICAL') {
							startMarker.setIcon('content/images/Pointer-C4.png');
						}
						var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
						
						if(value.caseDistance != null){
							startMarker.content = '<div class="infoWindowContent"> <b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> ' + value.assignedToUser
								+ '<br> <b>Address :</b> ' + value.address + '<br> <b>Distance(Km) :</b> ' + value.caseDistance + '</div>';
						}else{
							startMarker.content = '<div class="infoWindowContent"> <b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> ' + value.assignedToUser
							+ '<br> <b>Address :</b> ' + value.address + '</div>';
						}
						$compile(startMarker.content)($scope);
						google.maps.event.addListener(startMarker, 'mouseover', function() {
							infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
							infoWindow.open(locations.map, startMarker);
						});


						// }
						// on mouseout
						google.maps.event.addListener(startMarker, 'mouseout', function() {
							infoWindow.close();
						});

						
//						var activeInfoWindow;
//						// add content to InfoWindow for click event
//						infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
//
//						// add listener on InfoWindow for click event
//						google.maps.event.addListener(startMarker, 'click', function() {
//
//							if (activeInfoWindow != null)
//								activeInfoWindow.close();
//							// Open InfoWindow - on click
//							infoWindow2.open(locations.map, startMarker);
//							$(".userpath").click(function() {
//								var ele = document.getElementById("userPathModal");
//								$(ele).modal('show');
//								vm.drawUserPath($(this).attr('userid'));
//							});
//
//							// Store new open InfoWindow in global variable
//							activeInfoWindow = infoWindow2;
//						});
//						resizeTrCaseMap();
					});
					resizeTrCaseMap();
					// };
				});
			}

			/*
			 * function setupTrcaseMap(callback) { var myOptions = { zoom : 10,
			 * center : new google.maps.LatLng("12.9716", "77.5946"), mapTypeId :
			 * google.maps.MapTypeId.ROADMAP }; locations.map = new
			 * google.maps.Map(document.getElementById("map_canvas1"),
			 * myOptions); locations.overlay = new google.maps.OverlayView();
			 * locations.overlay.draw = function() { };
			 * locations.overlay.setMap($scope.map); locations.element =
			 * document.getElementById('map_canvas1'); callback(); }
			 */
		}

//		function resizeTrCaseMap() {
//			setTimeout(function() {
//				google.maps.event.trigger(locations.map, "resize");
//				locations.map.setCenter(locations.latlng);
//			}, 1000);
//		}

		function initializeTrcaseMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function(position) {

						latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						callback(latlng);
					}, function() {

						callback(new google.maps.LatLng("12.9716", "77.5946"));
					});
				} else {
					callback(new google.maps.LatLng("12.9716", "77.5946"));
				}
			}
		}

		/*
		 * function drawCasesA() { drawLatestCases($scope.trcaseuser); }
		 */
		function drawCasesAndNearByAgents(response) {

			if (response) {
				initializeMap(response, function(latlang) {
					locations.latlng = latlang;
					// setupMap(function() {
					$.each(response, function(index, value) {
						var infoWindow = new google.maps.InfoWindow();
						var startMarker = new google.maps.Marker({
							map : locations.map,
							position : new google.maps.LatLng(value.pinLat, value.pinLong),
							title : value.description,

						});

						var infoWindow2 = new google.maps.InfoWindow();

						if (value.priority == 'LOW') {
							startMarker.setIcon('content/images/Pointers-L4.png');
						} else if (value.priority == 'MEDIUM') {
							startMarker.setIcon('content/images/Pointers-M4.png');
						} else if (value.priority == 'HIGH') {
							startMarker.setIcon('content/images/Pointers-H4.png');
						} else if (value.priority == 'CRITICAL') {
							startMarker.setIcon('content/images/Pointer-C4.png');
						}
						var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
//						startMarker.content = '<div class="infoWindowContent"><b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> '
//								+ value.assignedToUser + '<br> <b>Address :</b> ' + value.address + '</div>';

						if(value.caseDistance != null){
							startMarker.content = '<div class="infoWindowContent"> <b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> ' + value.assignedToUser
								+ '<br> <b>Address :</b> ' + value.address + '<br> <b>Distance(Km) :</b> ' + value.caseDistance + '</div>';
						}else{
							startMarker.content = '<div class="infoWindowContent"><b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> '
							+ value.assignedToUser + '<br> <b>Address :</b> ' + value.address + '</div>';
						}
						$compile(startMarker.content)($scope);
						google.maps.event.addListener(startMarker, 'mouseover', function() {
							infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
							infoWindow.open(locations.map, startMarker);
						});

						// on mouseout
						google.maps.event.addListener(startMarker, 'mouseout', function() {
							infoWindow.close();
						});

						resizeMap();
					});
					// });
				});
			}
//			drawAgents();
		}

		function drawAgents() {

			var infoWindow = null;
			var markers = [ {
				Lat : "0.0",
				Lng : "0.0",
				name : "default"
			} ];
			TrCaseUser.getAgents(function(response) {
				$.each(response.liveLogs,
					function(index, value) {

						infoWindow = new google.maps.InfoWindow();

						var imgbg = document.createElement('img');
						imgbg.className = "marker_back_image";

						if (value.status == 'ACTIVE') {
							imgbg.src = 'content/images/greenmarker.png';
						} else if (value.status == 'INACTIVE') {
							imgbg.src = 'content/images/redmarker.png';
						} else if (value.status == 'IDLE') {
							imgbg.src = 'content/images/yellowmarker.png';
						}

						function customMarker() {
							this.latlng = new google.maps.LatLng(value.latitude, value.longitude);
							this.setMap(locations.map);
							this.title = value.login;
							this.icon = imgbg;
						}
						var userContent;
						if (value.userImage != null) {
							userContent = '<div class="infoWindowhead">' + value.login
									+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
									+ '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :'
									+ value.batteryPercentage + ' %</p></div></div></div>';
						} else {
							userContent = '<div class="infoWindowhead">'
									+ value.login
									+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="content/images/userimg.gif" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>'
									+ value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :' + value.batteryPercentage + ' %</p></div></div></div>';
						}
						var infowindowPopup = new google.maps.InfoWindow({
							content : userContent,
							pixelOffset : new google.maps.Size(0, -50),
						});
						infowindowPopup.setPosition(new google.maps.LatLng(value.latitude, value.longitude));

						customMarker.prototype = new google.maps.OverlayView();
						customMarker.prototype.draw = function() {
							var self = this;
							var div = this.div;

							if (!div) {

								div = this.div = document.createElement('div');
								div.id = 'marker';
								div.style.width = '100px';
								div.style.height = '100px';

								var div_pointer = document.createElement('div');
								div_pointer.className = 'triangle';

								var image_container = document.createElement('div');
								image_container.className = 'image_container';

								var img = document.createElement('img');
								img.className = "marker_image";
								img.src = "data:image/png;base64,";
								img.src += value.userImage;
								if (value.userImage == undefined) {
									img.src = "content/images/userimg.gif";
								}

								var name_container = document.createElement('div');
								name_container.className = 'name_container';

								div.appendChild(image_container);
								image_container.appendChild(img);
								image_container.appendChild(imgbg);

								name_container.onmouseover = function() {
									name_container.style.opacity = '0.6';
									div.style.zIndex = '1000'
								};
								name_container.onmouseout = function() {
									name_container.style.opacity = '0';
									div.style.zIndex = '100'
								};

								google.maps.event.addDomListener(imgbg, "click", function(event) {
									google.maps.event.trigger(self, "click");
									infowindowPopup.open(locations.map);
								});

								var panes = this.getPanes();

								panes.overlayImage.appendChild(div);

							}

							var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

							if (point) {
								div.style.left = (point.x - 50) + 'px';
								div.style.top = (point.y - 125) + 'px';
							}
						}

						customMarker.prototype.getPosition = function() {
							return this.latlng;
						}
						markers.forEach(function(marker) {
							var overlay = new customMarker();
						});
					})
			});
		}
		$scope.caseLocation = function(selectedCase){
				$("#caseLocationModal").show();
				var myCenter = new google.maps.LatLng(selectedCase.pinLat, selectedCase.pinLong);
				var ele = document.getElementById("caseLocationModal");
				$(ele).modal('show');

				var map = new google.maps.Map(document
						.getElementById('map_canvas_case_location'), {
					center : myCenter,
					zoom : 18
				});
			 
				var markerIcon = '';
				if (selectedCase.priority == 'LOW') {
					markerIcon = 'content/images/Pointers-L4.png';
				} else if (selectedCase.priority == 'MEDIUM') {
					markerIcon = 'content/images/Pointers-M4.png';
				} else if (selectedCase.priority == 'HIGH') {
					markerIcon = 'content/images/Pointers-H4.png';
				} else if (selectedCase.priority == 'CRITICAL') {
					markerIcon = 'content/images/Pointer-C4.png';
				}
				
				var markerContent = null;
				if(selectedCase.caseDistance != null){
					markerContent = '<div class="infoWindowhead">'
					+ '<b>Case Id : </b>' + selectedCase.id +'<br><b>Description : </b>' + selectedCase.description + '<br> <b>Lat : </b>' + selectedCase.pinLat + '<br> <b>Lng : </b>' + selectedCase.pinLong
					+ '<br><b>Address : </b>' + selectedCase.address+'<br><b>Distance (km) : </b>' + selectedCase.caseDistance + '</div>';
				}else{
					markerContent = '<div class="infoWindowhead">'
					+ '<b>Case Id : </b>' + selectedCase.id +'<br><b>Description : </b>' + selectedCase.description + '<br> <b>Lat : </b>' + selectedCase.pinLat + '<br> <b>Lng : </b>' + selectedCase.pinLong
					+ '<br><b>Address : </b>' + selectedCase.address+'</div>';
				}
				 var marker = new google.maps.Marker({
						position: myCenter,
						content:markerContent,
            icon: {
                url: markerIcon,
                size: new google.maps.Size(70, 86), //marker image size
                origin: new google.maps.Point(0, 0), // marker origin
                anchor: new google.maps.Point(35, 86) // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker).
            }
				});
				
				var infowindow = new google.maps.InfoWindow({
					content: markerContent
				});
				infowindow.open(map, marker);
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
        marker.setMap(map);
				
				$('#caseLocationModal').on('show.bs.modal', function(event) {

					$("#location-map-case-location").css("width", "100%");
					$("#map_canvas_case_location").css("width", "100%");
				});
		}
		
		function resizeMap() {
			setTimeout(function() {
				google.maps.event.trigger(locations.map, "resize");
				locations.map.setCenter(locations.latlng);
			}, 1000);
		}
		
		function initializeMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function(position) {

						latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						callback(latlng);
					}, function() {

						callback(new google.maps.LatLng("12.9716", "77.5946"));
					});
				} else {
					callback(new google.maps.LatLng("12.9716", "77.5946"));
				}
			}
		}

		$('.select2').select2();

		function assigncasetouser(userId, userLogin) {
			vm.editUserId = userId;
			$scope.edituserLogin = userLogin;
			TrCaseEdit.getalltrcases(function(response) {
				$scope.trallcases = response;

			});

		}
		vm.success = null;
		vm.error = null;
		function editcase() {
			var data = {
				userId : vm.editUserId,
				caseId : vm.seachCaseId
			}

			/*
			 * TrCaseEdit.edittrcase(data, function(response){
			 * $scope.edittrcaseresponse=response; vm.success = 'OK';
			 * 
			 * });
			 */
			TrCaseEdit.edittrcase(data, function(response, headers) {

				if (response.status == 200) {
					vm.error = null;
					vm.success = 'OK';

				}
				if (response.status == 400) {
					vm.success = null;
					vm.error = 'No case found with this case id';

				}
			});
			$timeout(function() {
				$('#assigncaseModal').modal('hide');
				vm.success = null;
				vm.error = null;
				vm.seachCaseId = null;
				vm.caseIds = null;
			}, 2000);
		}

	}

			
})();
