(function() {
	'use strict';

	angular.module('trakeyeApp').controller('CaseTatReportController', CaseTatReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    	};
	}]);

	CaseTatReportController.$inject = [ '$scope','$sce' ,'$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval','Principal' ];

	function CaseTatReportController($scope, $sce,$state, Report, $rootScope, $timeout, $window, $filter, $interval,Principal) {
		var vm = this;
		vm.users = [];
		$scope.reporthide = false;
		vm.reportUser = $rootScope.userName;
		vm.openCalendar = openCalendar;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		vm.datePickerOpenStatus = {};
		vm.tat = 5;
		vm.users = loadPage();
		$scope.loader = false;
		vm.search='';
		
		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		
		function loadPage(){
			Report.getUsers('', function(response) {
				vm.users = response;
			})
		}
		
		
		$scope.report = function() {
		 
			Report.getCasesTatReport({
					reportType:"USER",
					ageType:vm.dateRange,
					login:vm.reportUser,
					 tat : vm.tat,
					 fromDateTime : vm.fromTime,
					 toDateTime : vm.toTime
				}, function(response) {
					$scope.myhtml = response;
				});

		}
		
	}

})();
