(function () {
    'use strict';

    angular.module('trakeyeApp').controller('SiteStatusReportController', SiteStatusReportController)

    SiteStatusReportController.$inject = ['$scope', 'UsersList', 'TrCaseForMap', 'CasesForMapByAdmin', 'Principal'];

    function SiteStatusReportController($scope, UsersList, TrCaseForMap, CasesForMapByAdmin, Principal) {
        var vm = this;
        vm.userlist = [];
        $scope.reporthide = false;
        vm.select2 = select2;
        vm.select2();
        vm.users = loadPage();
        vm.multiassets = [];
        vm.selectedVendor = "";

        function loadPage() {
            var loggedInUserId = 0;
  
            Principal.identity().then(function (identity) {
                if (identity) {
                    loggedInUserId = identity.id;
                }
            })
            UsersList.getusers(function (response) {
                var users = [];
                response.forEach(function (item, index) {
                    if (item.authorities.includes("ROLE_USER_ADMIN") && (item.id != loggedInUserId)) {
                        users.push(item)
                    }
                });
                vm.userlist = users;
                var vendorData = [];
                vm.userlist.forEach(function (item) {

                    CasesForMapByAdmin.casesformapbyadmin(item.id, function (response) {
                        var OnAirCaseCount = 0;
                        response.forEach(function (item1, index) {
                            var caseAV = item1.caseTypeAttributeValues;
                            caseAV.forEach(function (item2) {
                                if (item2.caseDataTypeName == 'Fiber Status') {
                                    if (item2.attributeValue == 'On Air') {
                                        OnAirCaseCount++;
                                    }
                                }
                            });
                        });
                        vendorData.push({
                            label: item.firstName + " " + item.lastName,
                            value: OnAirCaseCount
                        });

                        var donut = new Morris.Donut({
                            element: 'vendor-chart',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });
                        
                        var donut1 = new Morris.Donut({
                            element: 'vendor-chart1',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });
                        $scope.reporthide = true;
                    });

                });

            });

            vm.reportType = 'agent';
        }

		     

//        vm.caseTypeClick = function (caseTypeName) {
//            if (vm.selectedVendor != "") {
//            	
//            	
//                var vendorName = "";
//
//                vm.userlist.forEach(function (userVal) {
//                    if (userVal.id == vm.selectedVendor) {
//                        vendorName = userVal.firstName + " " + userVal.lastName;
//                    }
//                });
//
//                var vendorData = [];
//                CasesForMapByAdmin.casesformapbyadmin(vm.selectedVendor, function (response) {
//                	console.log(response);
//                	
//	                    var OnAirCaseCount = 0;
//	                    var result = [];
//	                    var grouped = {};
//	
//	                    response.forEach(function (item) {
//	                    	if(item.caseTypeName == caseTypeName){
//		                        var caseAV = item.caseTypeAttributeValues;
//		                        var onAirCount = 0;
//		                        var underDeploymentCount = 0;
//		                        var plannedCount = 0;
//		                        caseAV.forEach(function (item1) {
//		                            if (item1.caseDataTypeName == 'Fiber Status') {
//		                                if (item1.attributeValue == 'On Air') {
//		                                    onAirCount++;
//		                                    OnAirCaseCount++;
//		                                } else if (item1.attributeValue == 'Under Deployment') {
//		                                    underDeploymentCount++;
//		                                } else if (item1.attributeValue == 'Planned') {
//		                                    plannedCount++;
//		                                }
//		                            }
//		                        });
//		                        result.push({
//		                            name: item.caseTypeName,
//		                            onair: onAirCount,
//		                            deployed: underDeploymentCount,
//		                            planned: plannedCount
//		                        });
//	                    	}
//	                    });
//	
//	                    result.forEach(function (a) {
//	                        grouped[a.name] = grouped[a.name] || [];
//	                        grouped[a.name].push({
//	                            name: a.name,
//	                            onair: a.onair,
//	                            deployed: a.deployed,
//	                            planned: a.planned
//	                        });
//	                    });
//	                    result = Object.values(grouped);
//	                    result.forEach(function (item) {
//	                        var name = "";
//	                        var onAirVal = 0;
//	                        var deployedVal = 0;
//	                        var plannedVal = 0;
//	                        item.forEach(function (item1) {
//	                            name = item1.name;
//	                            onAirVal += item1.onair;
//	                            deployedVal += item1.deployed;
//	                            plannedVal += item1.planned;
//	                        });
//	                        vm.multiassets.push({
//	                            name: name,
//	                            onair: onAirVal,
//	                            deployed: deployedVal,
//	                            planned: plannedVal
//	                        });
//	                    });
//	                    vendorData.push({
//	                        label: vendorName,
//	                        value: OnAirCaseCount
//	                    });
//	
//	                    var donut = new Morris.Donut({
//	                        element: 'vendor-chart',
//	                        resize: true,
//	                        colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
//	                        data: vendorData,
//	                        hideHover: 'auto'
//	                    });
//	                    console.log(vm.multiassets);
//                });
//            }else{
//              	vm.selectedVendor = "";
//                var vendorData = [];
//                vm.userlist.forEach(function (item) {
//                    CasesForMapByAdmin.casesformapbyadmin(item.id, function (response) {
//                        var OnAirCaseCount = 0;
//                        response.forEach(function (item1, index) {
//                        	if(item1.caseTypeName == caseTypeName){
//	                            var caseAV = item1.caseTypeAttributeValues;
//	                            caseAV.forEach(function (item2) {
//	                                if (item2.caseDataTypeName == 'Fiber Status') {
//	                                    if (item2.attributeValue == 'On Air') {
//	                                        OnAirCaseCount++;
//	                                    }
//	                                }
//	                            });
//                        	}
//                        });
//                        vendorData.push({
//                            label: item.firstName + " " + item.lastName,
//                            value: OnAirCaseCount
//                        });
//
//                        var donut = new Morris.Donut({
//                            element: 'vendor-chart',
//                            resize: true,
//                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
//                            data: vendorData,
//                            hideHover: 'auto'
//                        });
//                    });
//                });
//                TrCaseForMap.getAllCases(function (response) {
//                    var result = [];
//                    var grouped = {};
//
//                    response.forEach(function (item) {
//                    	if(item.caseTypeName == caseTypeName){
//	                        var caseAV = item.caseTypeAttributeValues;
//	                        var onAirCount = 0;
//	                        var underDeploymentCount = 0;
//	                        var plannedCount = 0;
//	                        caseAV.forEach(function (item1) {
//	                            if (item1.caseDataTypeName == 'Fiber Status') {
//	                                if (item1.attributeValue == 'On Air') {
//	                                    onAirCount++;
//	                                } else if (item1.attributeValue == 'Under Deployment') {
//	                                    underDeploymentCount++;
//	                                } else if (item1.attributeValue == 'Planned') {
//	                                    plannedCount++;
//	                                }
//	                            }
//	                        });
//	                        result.push({
//	                            name: item.caseTypeName,
//	                            onair: onAirCount,
//	                            deployed: underDeploymentCount,
//	                            planned: plannedCount
//	                        });
//                    	}
//                    });
//
//                    result.forEach(function (a) {
//                        grouped[a.name] = grouped[a.name] || [];
//                        grouped[a.name].push({
//                            name: a.name,
//                            onair: a.onair,
//                            deployed: a.deployed,
//                            planned: a.planned
//                        });
//                    });
//                    result = Object.values(grouped);
//                    result.forEach(function (item) {
//                        var name = "";
//                        var onAirVal = 0;
//                        var deployedVal = 0;
//                        var plannedVal = 0;
//                        item.forEach(function (item1) {
//                            name = item1.name;
//                            onAirVal += item1.onair;
//                            deployedVal += item1.deployed;
//                            plannedVal += item1.planned;
//                        });
//                        vm.multiassets.push({
//                            name: name,
//                            onair: onAirVal,
//                            deployed: deployedVal,
//                            planned: plannedVal
//                        });
//                    });
//                });
//            }
//        };

        function select2() {
            $(".select2").select2();
        }


        $("#userlist").change(function () {
            vm.multiassets = [];
            var result = $("#userlist").val();
            if (result != "") {
            	vm.selectedVendor = result;
                var vendorName = "";

                vm.userlist.forEach(function (userVal) {
                    if (userVal.id == result) {
                        vendorName = userVal.firstName + " " + userVal.lastName;
                    }
                });

                var vendorData = [];
                CasesForMapByAdmin.casesformapbyadmin(result, function (response) {
                    var OnAirCaseCount = 0;
                    var result = [];
                    var grouped = {};

                    response.forEach(function (item) {
                        var caseAV = item.caseTypeAttributeValues;
                        var onAirCount = 0;
                        var underDeploymentCount = 0;
                        var plannedCount = 0;
                        caseAV.forEach(function (item1) {
                            if (item1.caseDataTypeName == 'Fiber Status') {
                                if (item1.attributeValue == 'On Air') {
                                    onAirCount++;
                                    OnAirCaseCount++;
                                } else if (item1.attributeValue == 'Under Deployment') {
                                    underDeploymentCount++;
                                } else if (item1.attributeValue == 'Planned') {
                                    plannedCount++;
                                }
                            }
                        });
                        result.push({
                            name: item.caseTypeName,
                            onair: onAirCount,
                            deployed: underDeploymentCount,
                            planned: plannedCount
                        });
                    });

                    result.forEach(function (a) {
                        grouped[a.name] = grouped[a.name] || [];
                        grouped[a.name].push({
                            name: a.name,
                            onair: a.onair,
                            deployed: a.deployed,
                            planned: a.planned
                        });
                    });
                    result = Object.values(grouped);
                    result.forEach(function (item) {
                        var name = "";
                        var onAirVal = 0;
                        var deployedVal = 0;
                        var plannedVal = 0;
                        item.forEach(function (item1) {
                            name = item1.name;
                            onAirVal += item1.onair;
                            deployedVal += item1.deployed;
                            plannedVal += item1.planned;
                        });
                        vm.multiassets.push({
                            name: name,
                            onair: onAirVal,
                            deployed: deployedVal,
                            planned: plannedVal
                        });
                    });
                    vendorData.push({
                        label: vendorName,
                        value: OnAirCaseCount
                    });

                    var donut = new Morris.Donut({
                        element: 'vendor-chart',
                        resize: true,
                        colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                        data: vendorData,
                        hideHover: 'auto'
                    });
                    var donut1 = new Morris.Donut({
                        element: 'vendor-chart1',
                        resize: true,
                        colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                        data: vendorData,
                        hideHover: 'auto'
                    });
                });
            } else {
            	vm.selectedVendor = "";
                var vendorData = [];
                vm.userlist.forEach(function (item) {
                    CasesForMapByAdmin.casesformapbyadmin(item.id, function (response) {
                        var OnAirCaseCount = 0;
                        response.forEach(function (item1, index) {
                            var caseAV = item1.caseTypeAttributeValues;
                            caseAV.forEach(function (item2) {
                                if (item2.caseDataTypeName == 'Fiber Status') {
                                    if (item2.attributeValue == 'On Air') {
                                        OnAirCaseCount++;
                                    }
                                }
                            });
                        });
                        vendorData.push({
                            label: item.firstName + " " + item.lastName,
                            value: OnAirCaseCount
                        });

                        var donut = new Morris.Donut({
                            element: 'vendor-chart',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });
                        
                        var donut1 = new Morris.Donut({
                            element: 'vendor-chart1',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });
                    });
                });
                TrCaseForMap.getAllCases(function (response) {
                    var result = [];
                    var grouped = {};

                    response.forEach(function (item) {
                        var caseAV = item.caseTypeAttributeValues;
                        var onAirCount = 0;
                        var underDeploymentCount = 0;
                        var plannedCount = 0;
                        caseAV.forEach(function (item1) {
                            if (item1.caseDataTypeName == 'Fiber Status') {
                                if (item1.attributeValue == 'On Air') {
                                    onAirCount++;
                                } else if (item1.attributeValue == 'Under Deployment') {
                                    underDeploymentCount++;
                                } else if (item1.attributeValue == 'Planned') {
                                    plannedCount++;
                                }
                            }
                        });
                        result.push({
                            name: item.caseTypeName,
                            onair: onAirCount,
                            deployed: underDeploymentCount,
                            planned: plannedCount
                        });
                    });

                    result.forEach(function (a) {
                        grouped[a.name] = grouped[a.name] || [];
                        grouped[a.name].push({
                            name: a.name,
                            onair: a.onair,
                            deployed: a.deployed,
                            planned: a.planned
                        });
                    });
                    result = Object.values(grouped);
                    result.forEach(function (item) {
                        var name = "";
                        var onAirVal = 0;
                        var deployedVal = 0;
                        var plannedVal = 0;
                        item.forEach(function (item1) {
                            name = item1.name;
                            onAirVal += item1.onair;
                            deployedVal += item1.deployed;
                            plannedVal += item1.planned;
                        });
                        vm.multiassets.push({
                            name: name,
                            onair: onAirVal,
                            deployed: deployedVal,
                            planned: plannedVal
                        });
                    });
                });
            }
        });

        $('#userlist').trigger('change');

        // Added for generating pdf start
        $scope.close = function () {
            $('#genericReport').show();
            $scope.reporthide = true;
            $('#report').hide();
        }
        $scope.loader = false;


    }
})();
