(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('Report', ['$rootScope','$http',function($rootScope,$http){
        	var service ={};
        	
        	service.getUsers = function (name,callback){
        		
        		$http.get('api/userslist').then(function(response){
            		callback(response.data);
            	});
        	};
        	service.getGeofences = function (name,callback){
        		
        		$http.get('api/geofenceslist').then(function(response){
            		callback(response.data);
            	});
        	};
        	service.getReport = function (data,callback){
        		
        		$http.post('api/reports/view',data).then(function(response){
            		callback(response.data);
            	});
        	};
			service.getDetailedReport = function (data,callback){
        		
        		$http.post('api/reports/detailed-report',data).then(function(response){
            		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};
        	
        	service.getbatteryReport = function (login, fromtime, totime,callback){
        		
        		$http.get('api/reports/batteryreport/'+login+'/'+fromtime+'/'+totime).then(function(response){
            		callback(response.data);
            	});
        	};
        	
        	
        	service.getDistanceReport = function (login, fromtime, totime,callback){
        		
        		$http.get('api/reports/distancereport/'+login+'/'+fromtime+'/'+totime).then(function(response){
            		callback(response.data);
            	});
        	};
        	service.getCasesReport = function (data,callback){
        		$http.post('api/reports/cases',data).then(function(response){
             		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};
        	
        	service.getCasesAgingReport = function (data,callback){
        		$http.post('api/reports/caseagingreport',data).then(function(response){
             		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};
        	
        	service.getCasesTatReport = function (data,callback){
        		$http.post('api/reports/casetatreport',data).then(function(response){
             		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};

        	service.getCasesMttrReport = function (ageType,fromDate,toDate,callback){
        		$http.get('api/tr-cases-report/'+ageType+'/'+fromDate+'/'+toDate).then(function(response){
            		callback(response.data);
            	});
        	};
        	
        	service.getTeamReport = function (data,callback){
        		$http.post('api/reports/teamreport',data).then(function(response){
             		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};
        	
        	service.getDistReport = function (data,callback){
        		$http.post('api/reports/distance',data).then(function(response){
             		callback(response.data);
            	},function(error){
            		callback({counts:{}});
            	})
        	};
			service.getAssetReport = function (assetIdReport, casetypeid, attname,fromDate,toDate,callback){
        		$http.get('api/reports/assetreport/'+assetIdReport+'/'+casetypeid+'/'+attname+'/'+fromDate+'/'+toDate).then(function(response){
            		callback(response.data);
					
            	});
        	};
			service.getAssetCaseTypeReport = function (assetIdReport,fromDate,toDate,callback){
        		$http.get('api/reports/assetcasetypereport/'+assetIdReport+'/'+fromDate+'/'+toDate).then(function(response){
            		callback(response.data);
					
            	});
        	};
			
        	service.getAssetCaseReport = function (casetypeid,fromDate,toDate,callback){
        		$http.get('api/reports/assetcasereport/'+casetypeid+'/'+fromDate+'/'+toDate).then(function(response){
            		callback(response.data);
					
            	});
        	};
        	
        	service.getAssetCaseReportSearch = function (casetypeid,fromDate,toDate,searchText,callback){
        		$http.get('api/reports/assetcasereport/'+casetypeid+'/'+fromDate+'/'+toDate+'/'+searchText).then(function(response){
            		callback(response.data);
					
            	});
        	};
			
        	return service;
        }]);

    
    
})();