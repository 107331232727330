(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomServiceTypeAttributeDialogController', CustomServiceTypeAttributeDialogController);

    CustomServiceTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams',  'entity', 'CustomServiceTypeAttribute'];

    function CustomServiceTypeAttributeDialogController ($timeout, $scope, $state, $stateParams,  entity, CustomServiceTypeAttribute) {
        var vm = this;

        vm.customServiceTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
       
        
       // vm.customservicetypeattributevalues = CustomServiceTypeAttributeValue.query();

        
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customServiceTypeAttribute.customServiceTypeAttributeValues != null && vm.customServiceTypeAttribute.customServiceTypeAttributeValues.length != 0) {
			vm.items = vm.customServiceTypeAttribute.customServiceTypeAttributeValues;
		}
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};
        
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customServiceTypeAttribute.id !== null) {
            	 vm.customServiceTypeAttribute.customServiceTypeAttributeValues = [];
 				for (var i = 0; i < vm.items.length; i++) {
 					vm.customServiceTypeAttribute.customServiceTypeAttributeValues.push({
 						"id" : vm.items[i].id,
 						"value" : vm.items[i].value
 					});

 				}
                CustomServiceTypeAttribute.update(vm.customServiceTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	vm.customServiceTypeAttribute.customServiceTypeAttributeValues = [];

				for (var i = 0; i < vm.items.length; i++) {
					vm.customServiceTypeAttribute.customServiceTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
            	
                CustomServiceTypeAttribute.save(vm.customServiceTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customServiceTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-service-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
