(function() {
	'use strict';

	angular.module('trakeyeApp').controller('TrCaseDialogController', TrCaseDialogController).directive('mySrc', function() {
		return {
			restrict : 'A',
			scope : {
				callback : '&'
			},
			link : function(scope, elem, attrs) {
				elem.attr('src', scope.callback());
			}
		};
	})
	TrCaseDialogController.$inject = [ '$timeout', '$scope', '$stateParams', '$q', 'entity', 'TrCase', 'User', 'CaseType', 'Principal', 'Asset', '$state', 'ActivatedUsers', 'UserValueSearch',
			'AlertService', 'ActivatedUserSearch', 'TrCaseUpload', 'TrCaseRecordings', 'AssetSearchForMap', 'AssetSearch', 'AllCaseTypes', 'TrCaseImages','AllCustomCaseTypeAttribute', 'AssetsForMap', 'FeasibilityCheck', '$ngConfirm' ];

	function TrCaseDialogController($timeout, $scope, $stateParams, $q, entity, TrCase, User, CaseType, Principal, Asset, $state, ActivatedUsers, UserValueSearch, AlertService, ActivatedUserSearch,
			TrCaseUpload, TrCaseRecordings, AssetSearchForMap, AssetSearch, AllCaseTypes, TrCaseImages, AllCustomCaseTypeAttribute, AssetsForMap, FeasibilityCheck, $ngConfirm) {
		var vm = this;

		vm.trCase = entity;

		var entity_old = angular.copy(vm.trCase);
		// vm.caseImages; // = entity_old.caseImages;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.save = save;
		vm.decodeImage = decodeImage;
		vm.filterUser = filterUser;
		vm.filterActivatedUser = filterActivatedUser;
		vm.assetList = "";
		vm.filterAssetsList = filterAssetsList;
		vm.customAttributesMap = {};
		vm.trCase.feasibilityPath = null;
		var openedInfoWindow = null;
		var usersMap = {};

		$scope.allimages = false;
		vm.trCase.caseImages = [];
		
		$scope.toggle = function() {
			$scope.allimages = !$scope.allimages;
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/case_"+vm.trCase.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			if ($scope.allimages) {

				TrCaseImages.query({
					caseId : vm.trCase.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.caseImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		};
		

		$(".select2").select2(); // Added search for select box
		// Added for upload
		$(":file").filestyle({
			buttonBefore : true
		});
		$(":file").filestyle('buttonText', 'Browse File');
		vm.caseTypes = loadPage();// CaseType.query();
		vm.selectattributes = selectedCaseTypeAttributes;
		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function loadPage() {
			
			ReverseGeocode(parseFloat(vm.trCase.pinLat), parseFloat(vm.trCase.pinLong));
			AllCaseTypes.getAll(function(response) {
				vm.caseTypes = response;
			});
			
			AllCustomCaseTypeAttribute.getAll(function(response) {
	    		 //vm.customCaseTypeAttributes = response;
	     		 response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
					});
	    		 
				});
			
			if(vm.trCase.id != null){
				if(vm.trCase.caseType.name == "Feasibility Path Case"){
		            var caseTypeAttributeValues = vm.trCase.caseTypeAttributeValues;
		            caseTypeAttributeValues.forEach(function(value){
		            	if(value.caseTypeAttribute.name == "CustId"){
		            		FeasibilityCheck.get({
		                        id: value.attributeValue
		                    }, onSuccess);

		                    function onSuccess(data) {
		                    	vm.trCase.feasibilityUser = data;
		                    }
		            	}
		            });
	        	}
			}
		}
		
		function ReverseGeocode(latitude, longitude){
			var address = new google.maps.Geocoder();

			address.geocode({
				'latLng' : {lat:latitude, lng:longitude}
			}, function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						vm.trCase.address = results[0].formatted_address;
					}
				}
			});
		}
		
        $scope.openMapModal = function () {
        	var value = vm.trCase.feasibilityUser;
        	if(value.latitude != null && value.longitude!=null){
        		
	        	var latlng = {lat: value.latitude, lng: value.longitude};
	        	 
	            $("#nearbyAssetsModal").show();
	            var myCenter = new google.maps.LatLng(12.909515, 77.646717);
	            var ele = document.getElementById("nearbyAssetsModal");
	            $(ele).modal('show');
	            //
	            var locMap = new google.maps.Map(document
	                .getElementById('map_canvas_nearby_assets'), {
	                    center: myCenter,
	                    zoom: 14
	                });
	
	            var userLocation = new google.maps.LatLng(latlng.lat, latlng.lng);
	            
	            drawPolylineForSelectedPath(userLocation,value.nearestAssetPath, locMap, "red", value);	
	            
	            AssetsForMap.assetsformap(function (response) {
 	            	if(response != null && response.length > 0){
 	        			response.forEach(function(value) {
 	        				if (value.assetType != null && value.assetType.layout == 'FIXED') {
 								drawFixedAsset(value, locMap);
 							}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
 								if(value.assetCoordinates.length > 0){
 									drawPolyline(value, locMap);
 								}
 							}
 	        			});
 	            	}
 	            });
	            
	            $('#nearbyAssetsModal').on('show.bs.modal', function (event) {
	                $("#location-map-nearby-assets").css("width", "100%");
	                $("#map_canvas_nearby_assets").css("width", "100%");
	            });
        	 }else{
             	console.log(value.customerName + " - Location details missing!!!");
             }
        }
        
        $scope.openAssetMapModal = function () {
        	var value = vm.trCase.asset;
        	if(vm.trCase.asset.assetCoordinates.length > 0){
        		$("#assetsModal").show();
	            var myCenter = new google.maps.LatLng(vm.trCase.pinLat, vm.trCase.pinLong);
	            var ele = document.getElementById("assetsModal");
	            $(ele).modal('show');
	            //
	            var locMap = new google.maps.Map(document
	                .getElementById('map_canvas_assets'), {
	                    center: myCenter,
	                    zoom: 14
	                });
	
	            var markerA = new google.maps.Marker({
                    position: myCenter,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    map: locMap
                });
                
                var markerContent = '<div class="infoWindowhead"><b>'+ vm.trCase.description
    			+ '</b></div><div class="infoWindowContent">'
    			+'<b>Lat : </b>'+ vm.trCase.pinLat
    			+'<br><b>Lng : </b>'+ vm.trCase.pinLong
    			+'</div>';
                
                var infoWindow1 = new google.maps.InfoWindow({
                	content: markerContent
                });
                
    	        markerA.addListener('click', function () {
    	        	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
    	        	infoWindow1.open(map, markerA);
    	        	openedInfoWindow = infoWindow1;
    	        });
    	        
	            if(value.assetType != null){
	            	if (value.assetType != null && value.assetType.layout == 'FIXED') {
						drawFixedAsset(value, locMap);
					}else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
						if(value.assetCoordinates.length > 0){
							drawPolyline(value, locMap);
						}
					}
	            }
	            
	            $('#assetsModal').on('show.bs.modal', function (event) {
	                $("#location-map-assets").css("width", "100%");
	                $("#map_canvas_assets").css("width", "100%");
	            });
        	 }else{
             	console.log(value.customerName + " - Location details missing!!!");
             }
        }
        
       function drawFixedAsset(fixed, locMap) {
        	
        	if(fixed.assetCoordinates.length > 0){
        		 if (openedInfoWindow != null) {
                     openedInfoWindow.close();
                 }
        		var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(fixed.assetCoordinates[0].latitude, fixed.assetCoordinates[0].longitude),
                    map: locMap,
                    icon: {
                        scaledSize: new google.maps.Size(
                            30, 30),
                        origin: new google.maps.Point(
                            0, 0),
                        url: fixed.assetType.imagePath,
                        anchor: new google.maps.Point(
                            16, 16),

                    }
                 });
                 
                 marker.setMap(locMap);
                 
                 var infowindow = new google.maps.InfoWindow({
                    content:fixed.name
                 });
     				
                 google.maps.event.addListener(marker, 'mouseover', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                     infowindow.open(locMap,marker);
                     openedInfoWindow = infowindow;
                 });
                 google.maps.event.addListener(marker, 'mouseout', function () {
                	 if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     }
                 });
                 openedInfoWindow = infowindow;
    		}
        }
        
        function drawPolyline(spread, locMap) {

        	var polylineColor = null;
            
        	var spreadAssetCoordinates = [];
        	var assetCoordinatesList = spread.assetCoordinates;

            assetCoordinatesList.forEach(function(value) {
	              spreadAssetCoordinates.push({
		              lat: value.latitude,
		              lng: value.longitude
		          });
            });
            
            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };              

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                });

                if (spread.assetType.layout == "SPREAD") {
                    polylineColor = spread.assetType.colorcode;
                }
                infowindow.setPosition(midLatLng);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                  
                    infowindow.setContent('<div class="infoWindowhead">' +
                            spread.name +
                            '</div>'
                    );

                    infowindow.mousemove
                    infowindow.setPosition(event.latLng);
                    infowindow.open(locMap);
                    openedInfoWindow = infowindow;
                });

                if (spread.status != null && spread.status === false) {
                    polylinepath.setOptions({
                        strokeColor: polylineColor
                    });
                }

                polylinepath.setMap(locMap);
            }
        }
        
        function drawPolylineForSelectedPath(pointA, spreadAssetCoordinates, map, color, userDetails) {
            if (spreadAssetCoordinates.length > 0) {
                var markerA = new google.maps.Marker({
                    position: pointA,
                    title: "point A",
                    label: "A",
                    map: map
                });
                
                var markerContent = '<div class="infoWindowhead"><b>'+ userDetails.customerName.toUpperCase()
    			+ '</b></div><div class="infoWindowContent">'
    			+'<b>Lat : </b>'+ userDetails.latitude
    			+'<br><b>Lng : </b>'+ userDetails.longitude
    			+'<br><b>Note : </b>'+ userDetails.notes
    			+'</div>';
                
                var infoWindow1 = new google.maps.InfoWindow({
                	content: markerContent
                });
                
    	        markerA.addListener('click', function () {
    	        	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
    	        	infoWindow1.open(map, markerA);
    	        	openedInfoWindow = infoWindow1;
    	        });

    	        map.setCenter(pointA);
    	        map.setZoom(17);
    	        
                var ColorValue = '#ff0000';
                if (color == "red") {
                    ColorValue = '#ff0000';
                } else if (color == "black") {
                    ColorValue = '#000000';
                } else {
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path: JSON.parse(spreadAssetCoordinates),
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                var pointContent= document.createElement('div');
    			
                pointContent.innerHTML = '<div class="infoWindowhead">' + userDetails.nearestAssetName
    				+ '</div><div class="infoWindowContent">'
    				+'</div>';

                var infoWindow2 = new google.maps.InfoWindow({
    				content : pointContent,
    			});
                
                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);
                
                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                	if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                	infoWindow2.open(map);
                	infoWindow2.setPosition(event.latLng);
    				openedInfoWindow = infoWindow2;
                });
            }
        }
        
		function save() {
			vm.isSaving = true;
			var assignedToUser = $("#field_assignedToUser").val();
			vm.trCase.assignedTo = usersMap[assignedToUser];
			angular.forEach(vm.assetList, function(value, key) {
				if (vm.trCase.asset.name === value.name) {
					vm.trCase.asset = value;
				}
			});

			if (vm.trCase.id !== null) {
				TrCase.update(vm.trCase, onSaveSuccess, onSaveError);
			} else {
				TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
			}
		}
		$("#caseImages").change(function() {

			var filesSelected = document.getElementById("caseImages").files;
			for (var i = 0; i < filesSelected.length; i++) {
				var fileReader = new FileReader();

				fileReader.onload = function(fileLoadedEvent) {
					vm.trCase.caseImages.push({
						image : window.btoa(fileLoadedEvent.target.result),
						trCase : {
							id : entity_old.id
						}
					});
				}
				fileReader.readAsBinaryString(filesSelected[i]);
			}

		});
		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:trCaseUpdate', result);
			$state.go('tr-case');
			// $uibModalInstance.close(result);
			vm.isSaving = false;
		}
		vm.caseType = entity.caseType;
		function selectedCaseTypeAttributes() {
			if (vm.caseType) {
				vm.trCase.caseType = vm.caseType;
				if (entity_old.caseType && vm.caseType.id === entity_old.caseType.id) {
					vm.trCase.caseTypeAttributeValues = [];
					vm.trCase.caseTypeAttributeValues = entity_old.caseTypeAttributeValues;
				} else {
					vm.trCase.caseTypeAttributeValues = [];
					$.each(vm.caseType.caseTypeAttribute, function(key, value) {
						vm.trCase.caseTypeAttributeValues.push({
							caseTypeAttribute : vm.caseType.caseTypeAttribute[key]
						});
					});
				}
			}

		}
		function onSaveError() {
			vm.isSaving = false;
		}

		vm.datePickerOpenStatus.createDate = false;
		vm.datePickerOpenStatus.updateDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		function decodeImage(img) {
			return window.atob(img);
		}

		function filterUser() {
			if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
				UserValueSearch.query({
					search : vm.trCase.assignedToUser,
				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.users = data;
					angular.forEach(vm.users, function(value, key) {
						usersMap[value.login] = value.id;
					});
				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			}
		}

		function filterActivatedUser() {

			if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
				ActivatedUserSearch.query({
					userId : vm.trCase.assignedToUser,
				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.activatedusers = data;

					angular.forEach(vm.activatedusers, function(value, key) {
						usersMap[value.login] = value.id;

					});
				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			}
		}

		// Added for asset search start
		function filterAssetsList() {
			if (vm.trCase.asset.name != null && vm.trCase.asset.name != "" && !angular.isUndefined(vm.trCase.asset.name)) {
				/*
				 * AssetSearchForMap.searchassetsformap(vm.trCase.asset.name,
				 * function (response) { vm.assetList = response; });
				 */

				AssetSearch.query({
					search : vm.trCase.asset.name,

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					vm.assetList = data;

				}
				function onError(error) {
					AlertService.error(error.data.message);
				}

			}
		};

		// Added for getting lat alng values from map
		var latlng = new google.maps.LatLng(12.9716, 77.5946);
		var settings = {
			zoom : 11,
			center : latlng,
			mapTypeControl : true,
			scaleControl : true,
			mapTypeControlOptions : {
				style : google.maps.MapTypeControlStyle.DROPDOWN_MENU
			},
			navigationControl : true,
			navigationControlOptions : {
				style : google.maps.NavigationControlStyle.DEFAULT
			},
			mapTypeId : google.maps.MapTypeId.ROADMAP,
			backgroundColor : 'white'
		};

		var map = new google.maps.Map(document.getElementById('map_case_dialog_canvas'), settings);
		var address = new google.maps.Geocoder();
		google.maps.event.addListener(map, 'click', function(event) {

			address.geocode({
				'latLng' : event.latLng
			}, function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						$ngConfirm({
							title : 'Are you sure you want to select this address?',
							content : '<strong>Address: </strong>' + results[0].formatted_address + '<br/>' + '<strong>Latitude: </strong>' + event.latLng.lat() + '</br>'
									+ '<strong>Longitude: </strong>' + event.latLng.lng(),
							type : "blue",
							typeAnimated : true,
							buttons : {
								OK : {
									text : 'OK',
									btnClass : 'btn-primary',
									action : function() {
										$scope.$apply(function() {
											vm.trCase.pinLat = event.latLng.lat();
											vm.trCase.pinLong = event.latLng.lng();
											vm.trCase.address = results[0].formatted_address;
											// alert('Lat: ' +
											// event.latLng.lat() + ' Lng: ' +
											// event.latLng.lng())
										})
									}
								},
								Cancel : {
									text : 'Cancel',
									btnClass : 'btn-danger',
									action : function() {

									}

								},

							}
						});

					}
				}
			});
		});
		// Added for getting lat alng values from map end

	}
})();
