(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('FeasibilityCheck', FeasibilityCheck);

    FeasibilityCheck.$inject = ['$resource'];

    function FeasibilityCheck ($resource) {
        var resourceUrl =  'api/feasibility-checks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('FeasibilityCheckSearch', FeasibilityCheckSearch);

	FeasibilityCheckSearch.$inject = [ '$resource' ];

	function FeasibilityCheckSearch($resource) {
		var service = $resource('api/feasibility-checks/search/:searchText', {
			searchText : '@searchText'
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('FeasibilityNearestAssets', FeasibilityNearestAssets);

    FeasibilityNearestAssets.$inject = ['$resource'];

    function FeasibilityNearestAssets ($resource) {
        var resourceUrl =  'api/feasibility-nearest-assets';

        return $resource(resourceUrl, {}, {
            'update': { method:'POST',
				isArray : true }
        });
    }
})();


