(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('ServiceTypeDialogController', ServiceTypeDialogController);

    ServiceTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'ServiceType', 'User','AllCustomServiceTypeAttribute'];

    function ServiceTypeDialogController ($timeout, $scope, $stateParams, $state, entity, ServiceType, User,AllCustomServiceTypeAttribute) {
        var vm = this;
        vm.serviceType = entity;       
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.customServiceTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.attributetypes = ['INTEGER', 'FLOAT', 'STRING','BOOLEAN'];
       // vm.items=[{name: 'SLA',dataType: 'INTEGER',value :'4'}];
        vm.items=[{ name: '',attributeDataType: {},value :''}];
        if(vm.serviceType.serviceTypeAttribute!=null && vm.serviceType.serviceTypeAttribute.length!=0){
        	vm.items=vm.serviceType.serviceTypeAttribute;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        function loadPage() {
	    	 AllCustomServiceTypeAttribute.getAll(function(response) {
	    		 vm.customServiceTypeAttributes = response;
	     		 response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customServiceTypeAttributeValues;
					});
	    		 
				});
	    	    
	    	 }
        
        function save () {
            vm.isSaving = true;
            
            if (vm.serviceType.id !== null) {
            	vm.serviceType.serviceTypeAttribute=[];
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
            	   // vm.serviceType.serviceTypeAttribute.push({"name" :  vm.items[i].name, "value" :  vm.items[i].value});
            	    vm.serviceType.serviceTypeAttribute.push({"id" : vm.items[i].id , "name" :  vm.items[i].name,   "attributeDataType" :  vm.items[i].attributeDataType,"value" :  vm.items[i].value});
            	    
            	}
                ServiceType.update(vm.serviceType, onSaveSuccess, onSaveError);
            } else {
            	vm.serviceType.serviceTypeAttribute=[];
            	
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
            	    //vm.serviceType.serviceTypeAttribute.push({"name" :  vm.items[i].name, "value" :  vm.items[i].value});
            		vm.serviceType.serviceTypeAttribute.push({"id" : vm.items[i].id ,"name" :  vm.items[i].name, "attributeDataType" :  vm.items[i].attributeDataType, "value" :  vm.items[i].value});
             	    
            	}
                ServiceType.save(vm.serviceType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:serviceTypeUpdate', result);
            vm.isSaving = false;
			$state.go('service-type');
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
      /* vm.addmore = function() {
            var newItemNo = vm.items.length+1;
            vm.items.push({'id':newItemNo});
          };*/
        vm.addmore = function() {
            //vm.items.push({name: '',dataType:'', value:''});
        	 vm.items.push({name: '',attributeDataType:{}, value:''});
          };
          
          vm.remove = function(index) {
              vm.items.splice(index, 1);
            };
            
     /*  vm.remove = function() {
            var lastItem = vm.items.length-1;
            vm.items.splice(lastItem);
          };*/

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updatedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
