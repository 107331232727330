(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrCase', TrCase);

	TrCase.$inject = [ '$resource', 'DateUtils' ];

	function TrCase($resource, DateUtils) {
		var resourceUrl = 'api/tr-cases/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCasePriority', TrCasePriority);

	TrCasePriority.$inject = [ '$resource' ];

	function TrCasePriority($resource) {
		var service = $resource('/api/tr-cases/priority/:priority', {
			priority : '@priorirty'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseSearch', TrCaseSearch);

	TrCaseSearch.$inject = [ '$resource' ];

	function TrCaseSearch($resource) {
		var service = $resource('/api/tr-cases/searchvalue/:search', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseImages', TrCaseImages);

	TrCaseImages.$inject = [ '$resource' ];

	function TrCaseImages($resource) {
		var service = $resource('/api/case-images/:caseId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseRecordings', TrCaseRecordings);

	TrCaseRecordings.$inject = [ '$resource' ];

	function TrCaseRecordings($resource) {
		var service = $resource('/api/case-recordings/:caseId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllCaseTypes', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/case-alltypes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCasePrioritySearch', TrCasePrioritySearch);

	TrCasePrioritySearch.$inject = [ '$resource' ];

	function TrCasePrioritySearch($resource) {
		var service = $resource('/api/tr-cases/prioritysearch/:priority/:searchtext', {
			priority : '@priorirty',
			searchtext : '@searchtext'
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseUser', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.gettrcasesuser = function(callback) {
			$http.get('api/tr-cases/live-logs').then(function(response) {
				callback(response.data);
			});
		};

		service.gettrcasesuserbyid = function(id, callback) {
			$http.get('api/tr-cases/live-logs/' + id).then(function(response) {
				callback(response.data);
			});
		};

		service.searchtrcasesuserbyvalue = function(id, callback) {
			$http.get('api/tr-cases/search/' + id).then(function(response) {
				callback(response.data);
			});
		};
		service.gettrcasesanduserbypriority = function(priority, callback) {
			$http.get('api/tr-cases/live-logs/priority/' + priority).then(function(response) {
				callback(response.data);
			});
		};

		service.getAgents = function(callback) {
			$http.get('api/dashboard/agentdashboard').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseEdit', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getalltrcases = function(callback) {
			$http.get('api/tr-allcases').then(function(response) {
				callback(response.data);
			});
		};

		service.edittrcase = function(data, callback) {
			$http.post('api/tr-caseedit/' + data.userId + '/' + data.caseId, {

			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-caseedit/'+data.userId+'/'+data.caseId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	} ]);
})();

(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrCaseUpload', TrCaseUpload);

	TrCaseUpload.$inject = [ '$http' ];

	function TrCaseUpload($http) {

		var service = {};
		service.trcaseupload = function(data, callback) {

			$http.post('api/caseimport', data, {
				transformRequest : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();




(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseSearchForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.searchtrcaseformap = function(search, callback) {
			$http.get('api/tr-casesformap/searchvalue/' + search).then(function(response) {
				callback(response.data);
			});
		};
		
		service.prioritysearchtrcaseformap = function(priority, callback) {
			$http.get('api/tr-casesformap/priority/' + priority).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();


(function() {
    'use strict';
    
    angular
        .module('trakeyeApp')
        .factory('TrCaseLastLocation', ['$rootScope','$http',function($rootScope,$http){
        	var service ={};
        	
        	service.getlastlogs = function (callback){
        		$http.get('api/location-logs/lastuserpath').then(function(response){
            		callback(response.data);
            	});
        	};
        	return service;
        }]);

    
    
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('CasesForMapByAdmin', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.casesformapbyadmin = function(adminid, callback) {
			$http.get('api/tr-casesformap/' + adminid).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAllCases = function(callback) {
			$http.get('api/tr-casesformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();
