(function() {
	'use strict';

	angular.module('trakeyeApp').controller('LocationLogController', LocationLogController);

	LocationLogController.$inject = [ '$filter', '$scope', '$compile', '$state', 'LocationLog', 'ParseLinks', 'AlertService', 'pagingParams', '$stateParams', 'paginationConstants', 'LocationLogMap',
			'LocationLogMapPath', 'UsersGeoFenceData', 'AssetsList', '$interval' ];

	function LocationLogController($filter, $scope, $compile, $state, LocationLog, ParseLinks, AlertService, pagingParams, $stateParams, paginationConstants, LocationLogMap, LocationLogMapPath,
			UsersGeoFenceData, AssetsList, $interval) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.drawUserPath = drawUserPath;
		vm.pathuserid = null;
		vm.logsbystatus = [];
		vm.all = all;
		vm.active = active;
		vm.inactive = inactive;
		vm.idle = idle;
		vm.filterUsers = filterUsers;
		vm.fromDate = null;
		vm.toDate = null;
		vm.today = today;
		vm.today();
		vm.onChangeDate = onChangeDate;
		var openedInfoWindow = null;
		var latlng;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		var dateFormat = 'yyyy-MM-dd';
		vm.selectedLogs = "ALL";
		var markerUserName = "";
		$scope.alllength;
		$scope.activelength;
		$scope.inactivelength;
		$scope.idlelength;
		// vm.loadLogsTable();
		setTimeout(function() {
			vm.updateUsers();
			vm.locateUsers;
		}, 1000);

		$scope.tab = 1;
		$scope.setTab = function(newTab) {
			$scope.tab = newTab;
			if ($scope.tab == 2) {
				vm.locateUsers;
			}
		};
		$scope.isSet = function(tabNum) {
			return $scope.tab === tabNum;
		};

		vm.updateUsers = function() {
			vm.locateUsers(function() {
				if (vm.selectedLogs === "ALL") {
					vm.all();
				} else if (vm.selectedLogs === "ACTIVE") {
					vm.active();
				} else if (vm.selectedLogs === "INACTIVE") {
					vm.inactive();
				} else if (vm.selectedLogs === "IDLE") {
					vm.idle();
				}
			})
		}
		var updateLogs = $interval(function() {

			vm.updateUsers();
			vm.locateUsers;

		}, 60000);
		var userPaths = {};
		var locations = {};

		function onChangeDate() {
			vm.drawUserPath(vm.pathuserid);
		}

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
		}

		function loadLogsTable() {

			var fromDate = $filter('date')(vm.fromDate, dateFormat);
			var toDate = $filter('date')(vm.toDate, dateFormat);
			var data = {
				fromDate : vm.fromDate.getTime(),
				toDate : vm.toDate.getTime()
			}

			LocationLogMap.getlogsbydate(data, function(response, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.locationLogs = response;
				vm.page = pagingParams.page;
			});
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
		}

		$scope.$watch('login', function(searchkey, oldval) {
			if (searchkey || oldval)
				drawLatestlocations($filter('filter')($scope.locations, {
					userName : searchkey
				}));
		})
		$scope.openInfoWindow = function(e, selectedMarker) {
			e.preventDefault();
			google.maps.event.trigger(selectedMarker, 'click');
		}
		function drawUserPath(userId) {
			var data = {
				fromDate : vm.fromDate.getTime(),
				toDate : vm.toDate.getTime(),
				userId : userId
			}
			LocationLogMapPath.getmaplogs(data, function(response) {
				vm.pathuserid = response.id;
				drawlines(response);
			});
		}

		vm.locateUsers = function(callback) {
			LocationLogMapPath.locateUsers(function(response) {
				$scope.trCases = response.trCases;
				$scope.locations = response.liveLogs;
				callback();
			});
		}

		function filterUsers() {
			if (vm.selectedLogs === "ALL") {

				drawLatestlocations($filter('filter')($scope.locations, {
					'login' : vm.searchUser
				}));

			} else {
				$scope.alllength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : vm.selectedLogs
				}, true), {
					'login' : vm.searchUser
				})).length;
				drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
					'status' : vm.selectedLogs
				}, true), {
					'login' : vm.searchUser
				}));
			}

		}

		function all() {
			vm.selectedLogs = "ALL";
			if ($stateParams.login == undefined) {
				$scope.alllength = ($filter('filter')($scope.locations, {
					'login' : $stateParams.login
				})).length;
				$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "ACTIVE"
				}, true), {
					'login' : vm.searchUser
				})).length;
				$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "INACTIVE"
				}, true), {
					'login' : vm.searchUser
				})).length;
				$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "IDLE"
				}, true), {
					'login' : vm.searchUser
				})).length;

				drawLatestlocations($filter('filter')($scope.locations, {
					'login' : vm.searchUser
				}));
			} else {
				$scope.alllength = ($filter('filter')($scope.locations, {
					'login' : $stateParams.login
				})).length;
				$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "ACTIVE"
				}, true), {
					'login' : vm.searchUser
				})).length;
				$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "INACTIVE"
				}, true), {
					'login' : vm.searchUser
				})).length;
				$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
					'status' : "IDLE"
				}, true), {
					'login' : vm.searchUser
				})).length;

				drawLatestlocations($filter('filter')($scope.locations, {
					'login' : $stateParams.login
				}));
			}

		}
		function active() {
			vm.selectedLogs = "ACTIVE";
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login' : $stateParams.login
			})).length;
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "INACTIVE"
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "IDLE"
			}, true), {
				'login' : vm.searchUser
			})).length;

			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			}));

		}

		function inactive() {
			vm.selectedLogs = "INACTIVE";
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login' : $stateParams.login
			})).length;
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "ACTIVE"
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "IDLE"
			}, true), {
				'login' : vm.searchUser
			})).length;
			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			}));
		}

		function idle() {
			vm.selectedLogs = "IDLE";
			$scope.idlelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.alllength = ($filter('filter')($scope.locations, {
				'login' : $stateParams.login
			})).length;
			$scope.activelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "ACTIVE"
			}, true), {
				'login' : vm.searchUser
			})).length;
			$scope.inactivelength = ($filter('filter')($filter('filter')($scope.locations, {
				'status' : "INACTIVE"
			}, true), {
				'login' : vm.searchUser
			})).length;

			drawLatestlocations($filter('filter')($filter('filter')($scope.locations, {
				'status' : vm.selectedLogs
			}, true), {
				'login' : vm.searchUser
			}));
		}

		function drawLatestlocations(response) {

			var markers = [ {
				Lat : "0.0",
				Lng : "0.0",
				name : "default"
			} ];

			if (response) {
				var activeInfoWindow;
				initializeMap(
						response,
						function(latlang) {
							locations.latlng = latlang;
							var bounds = new google.maps.LatLngBounds();
							setupMap(function() {
								$.each(response, function(index, value) {
											var infoWindow = new google.maps.InfoWindow();

											var infoWindow2 = new google.maps.InfoWindow();

											// Create a custom marker

											var imgbg = document.createElement('img');
											imgbg.className = "marker_back_image";

											if (value.status == 'ACTIVE') {
												imgbg.src = 'content/images/greenmarker.png'
											} else if (value.status == 'INACTIVE') {
												imgbg.src = 'content/images/redmarker.png'
											} else if (value.status == 'IDLE') {
												imgbg.src = 'content/images/yellowmarker.png'
											}
											
											if(value.address == 'SOS'){
												imgbg.src = 'content/images/alert.png'
											}

											if ($scope.trCases != undefined && $scope.trCases.length > 0) {

												$.each($scope.trCases, function(index, value) {

													var infoWindow = new google.maps.InfoWindow();
													var startMarker = new google.maps.Marker({
														map : locations.map,
														position : new google.maps.LatLng(value.pinLat, value.pinLong),
														title : value.description,

													});
													var infoWindow2 = new google.maps.InfoWindow();
													if (value.priority == 'LOW') {
														startMarker.setIcon('content/images/Pointers-L4.png');
													} else if (value.priority == 'MEDIUM') {

														startMarker.setIcon('content/images/Pointers-M4.png');
													} else if (value.priority == 'HIGH') {
														startMarker.setIcon('content/images/Pointers-H4.png');
													} else if (value.priority == 'CRITICAL') {
														startMarker.setIcon('content/images/Pointer-C4.png');
													}
													var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
													startMarker.content = '<div class="infoWindowContent"><b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser
															+ '<br> <b> Assigned To :</b> ' + value.assignedToUser + '<br> <b>Address :</b> ' + value.address + '</div>';

													$compile(startMarker.content)($scope);

													google.maps.event.addListener(startMarker, 'mouseover', function() {
														infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
														infoWindow.open(locations.map, startMarker);
													});
													// on mouseout
													google.maps.event.addListener(startMarker, 'mouseout', function() {
														infoWindow.close();
													});

													var activeInfoWindow;
													// add content to
													// InfoWindow for
													// click event
													infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
												})
											}

											function customMarker() {
												this.latlng = new google.maps.LatLng(value.latitude, value.longitude);
												this.setMap(locations.map);
												this.title = value.login;
												this.icon = imgbg;
											}

											bounds.extend(new google.maps.LatLng(value.latitude, value.longitude));

											/*var userContent;// working before browser update
											var travelPathLink = '<a userId=' + value.userid + ' class="userpath" >Load travelled path</a>';
											if (value.userImage != null) {
												userContent = '<div class="infoWindowhead">' + value.login
														+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
														+ '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
														+ '</p><p>Battery :' + value.batteryPercentage + ' %</p><p>' + travelPathLink + '</p></div></div></div>';
											} else {
												userContent = '<div class="infoWindowhead">'
														+ value.login
														+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="content/images/userimg.gif" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>'
														+ value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :' + value.batteryPercentage + ' %</p><p>' + travelPathLink
														+ '</p></div></div></div>';
											}*/
											
											var userContent= document.createElement('div'),
					                          button;
											

											if (value.userImage != null) {
												userContent.innerHTML = '<div class="infoWindowhead">' + value.login
													+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
													+ '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
													+ '</p><p>Battery :' + value.batteryPercentage ;
							                      button = userContent.appendChild(document.createElement('input'));
							                      button.type = 'button';
							                      button.value = 'Load travelled path';
							                      button.className = "button"; 
							                      
						                    	  google.maps.event.addDomListener(button, 'click', function () {
						                    		  markerUserName = value.login;
						                    		  userPath(value.userid);
						                          })
											}else {
												/*userContent.innerHTML = '<div class="infoWindowhead">' + value.login
												+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
												+ '</p><p>Battery :' + value.batteryPercentage+' %</p></div></div><div class="col-md-4"> <img src="content/images/userimg.gif" '
												+ '" style="width: 100px; height: 120px;"></div></div>'  ;*/
												
												userContent.innerHTML = '<div class="infoWindowhead">' + value.login
												+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone
												+ '</p><p>Battery :' + value.batteryPercentage+' %</p><p> Latitude :' + value.latitude+'</p> <p> Longitude :' + value.longitude+'</p> </div></div><div class="col-md-4"> <img src="content/images/userimg.gif" '
												+ '" style="width: 100px; height: 120px;"></div></div>'  ;
												
						                      button =userContent.appendChild(document.createElement('input'));
						                      button.type = 'button';
						                      button.value = 'Load travelled path';
						                      button.className = "button"; // set the CSS class
												
					                    	  google.maps.event.addDomListener(button, 'click', function () {
					                    		  markerUserName = value.login;
					                    		  userPath(value.userid);
					                          })
					
												
											}
											
											

											
											function userPath(id) {
												var ele = document.getElementById("userPathModal");
												$(ele).modal('show');
												vm.drawUserPath(id);
												var cleratodatefromdate = vm.today();
												// cleratodatefromdate.remove();
								              }
											var infowindowPopup = new google.maps.InfoWindow({
												content : userContent,
												pixelOffset : new google.maps.Size(0, -50),
											});

											customMarker.prototype = new google.maps.OverlayView();

											customMarker.prototype.draw = function() {
												var self = this;
												var div = this.div;

												if (!div) {

													div = this.div = document.createElement('div');
													div.id = 'marker';
													div.style.width = '100px';
													div.style.height = '100px';

													var div_pointer = document.createElement('div');
													div_pointer.className = 'triangle';

													var image_container = document.createElement('div');
													image_container.className = 'image_container';

													var img = document.createElement('img');
													img.className = "marker_image";
													var image2 = "data:image/png;base64,";
													image2 += value.userImage;
													if (value.userImage == undefined) {
														image2 = "content/images/userimg.gif";
													}

													img.src = image2;

													var name_container = document.createElement('div');
													name_container.className = 'name_container';

													var exit = document.createElement('div');
													exit.className = 'exit';
													exit.innerHTML = '<img className="exit_image" style="width:30px; height:30px;" src="https://cdn0.iconfinder.com/data/icons/large-glossy-icons/512/Delete.png">'
															+ '</img>';
													exit.style.display = 'none';

													div.appendChild(image_container);
													image_container.appendChild(img);
													image_container.appendChild(imgbg);

													div.appendChild(exit);

													name_container.onmouseover = function() {
														name_container.style.opacity = '0.6';
														div.style.zIndex = '1000'
													};
													name_container.onmouseout = function() {
														name_container.style.opacity = '0';
														div.style.zIndex = '100'
													};

													// add content to
													// InfoWindow for
													// click event
													infoWindow2.setContent('<div class="infoWindowhead">' + value.login + '</div>' + userContent);
													if (activeInfoWindow != null)
														activeInfoWindow.close();

													google.maps.event.addDomListener(imgbg, 'click', function(event) {
														infowindowPopup.setPosition(new google.maps.LatLng(value.latitude, value.longitude));
														infowindowPopup.open(locations.map);

														if (activeInfoWindow != null)
															activeInfoWindow.close();
													/* 	$(".userpath").click(function() { // working before browser update

															var ele = document.getElementById("userPathModal");
															$(ele).modal('show');
															vm.drawUserPath($(this).attr('userid'));
															var cleratodatefromdate = vm.today();
															// cleratodatefromdate.remove();
														});*/
														activeInfoWindow = infowindowPopup;

													});

													google.maps.event.addDomListener(imgbg, "click", function(event) {
														google.maps.event.trigger(self, "click");

														infowindowPopup.open(locations.map);
													});

													var panes = this.getPanes();

													panes.overlayImage.appendChild(div);

												}

												var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

												if (point) {
													div.style.left = (point.x - 50) + 'px';
													div.style.top = (point.y - 125) + 'px';
												}
											}

											customMarker.prototype.remove = function() {

												if (this.div) {
													this.div.parentNode.removeChild(this.div);
													this.div = null;
												}

											}

											customMarker.prototype.getPosition = function() {
												return this.latlng;
											}
											markers.forEach(function(marker) {
												// image =
												// value.userImage;

												var overlay = new customMarker();
											});
											// end

										});
								locations.map.fitBounds(bounds);
							});


						AssetsList.getAll(function(response){
							$.each(response, function(index, value) {
								var assetCorRes = value.assetCoordinates;
								var assetCorVal = [];
								for (var i = 0; i < assetCorRes.length; i++) {
									assetCorVal.push({lat:assetCorRes[i].latitude, lng:assetCorRes[i].longitude});;
								}
								drawPolylineForGeoFence(assetCorVal, locations.map, value.name);
							});
						});

						function drawPolylineForGeoFence(latlngsToDraw,map, details) {
								var spreadAssetCoordinates = [];
								$.each(latlngsToDraw, function(index, value) {

									spreadAssetCoordinates.push({
										lat : value.lat,
										lng : value.lng
									});
								});

								if (spreadAssetCoordinates.length > 0) {
									var midLatLng = {
											lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
											lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
									};
									var infowindow = new google.maps.InfoWindow(
											{
												content : '<div class="infoWindowhead">'
												+ "name"
												+ '<div class="infoWindowContent"> <b>Description :</b> '
												+ "des" + '</div></div>'
											});
									
									infowindow.setPosition(midLatLng);
									var ColorValue = '#000000';	
									
									var polylinepath = new google.maps.Polyline({
										path : spreadAssetCoordinates,
										geodesic : true,
										strokeColor : ColorValue,
										strokeWeight : 3
									});

									polylinepath.setOptions({
										strokeColor : ColorValue
									});
									
									google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
										infowindow.close();
									});
									
									google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
										if (openedInfoWindow != null){
											openedInfoWindow.close();
										}
										infowindow.setContent('<div class="infoWindowhead">'
													+ '<b></b>' + details + '</br></div>'
											);
											
											infowindow.mousemove;
											infowindow.open(map);
											infowindow.setPosition(event.latLng);
											openedInfoWindow = infowindow;
									});
									polylinepath.setMap(map);
								}
							}

						});
			}
			function setupMap(callback) {
				var myOptions = {
					zoom : 13,
					center : locations.latlng,
					mapTypeId : google.maps.MapTypeId.ROADMAP
				};
				locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
				locations.overlay = new google.maps.OverlayView();
				locations.overlay.draw = function() {
				};
				locations.overlay.setMap($scope.map);
				locations.element = document.getElementById('map_canvas');
				callback();
			}
		}

		function drawlines(paths) {
			vm.userPathName = paths.userName;
			var userdata = paths;
			if (paths.path.length > 0) {

				var data = [ {
					"latitude" : paths.path[0].lat,
					"longitude" : paths.path[0].lng
				} ];
				// console.log("empty" + data );
			} else {
				var data = [ {
					"latitude" : 12.9716,
					"longitude" : 77.5946
				} ];
				// var data = paths.path;
				// console.log(data);
			}

			initializeMap(data, function(latlang) {
				userPaths.latlng = latlang;
				setupMap(function() {
					
					if (paths && paths.path && paths.path.length > 0) {
						loadUserPaths(paths);
					} else {
//						resizeMap();
						$scope.totalDistanceTravelled = "0 kms";
						console.log('there are no user agents found');
					}
				});
				function setupMap(callback) {
					var myOptions = {
						zoom : 13,
						center : userPaths.latlng,
						mapTypeId : google.maps.MapTypeId.ROADMAP
					};
					userPaths.map = new google.maps.Map(document.getElementById("userPath"), myOptions);
					userPaths.overlay = new google.maps.OverlayView();
					userPaths.overlay.draw = function() {
					};
					userPaths.overlay.setMap(userPaths.map);
					userPaths.element = document.getElementById('userPath');
					callback();
				  
				}
				
				

				function loadUserPaths(paths) {
					var pathIn = [];
					var pathOut = [];
					var pathArr = paths.path;
					var prevCor = null;
					
					for (var i = 0; i < pathArr.length; i++) {
						if(pathArr[i].geofenceId > 0){
							pathIn.push(pathArr[i]);
							
							if(prevCor != null){
								var coordinatesList = [];
								coordinatesList.push(prevCor);
								coordinatesList.push(pathArr[i]);
								drawPolylineForGeoFence(coordinatesList, userPaths.map, "green", "Geofence Inside");	
							}
						}else{
							pathOut.push(pathArr[i]);

  							if(prevCor != null){
  								var coordinatesList = [];
  								coordinatesList.push(prevCor);
  								coordinatesList.push(pathArr[i]);
  								drawPolylineForGeoFence(coordinatesList, userPaths.map, "orange", "Geofence Outside");	
							}
						}
						prevCor = pathArr[i];
					}
//					var flightPath1 = new google.maps.Polyline({
//						path : pathArr,
//						geodesic : true,
//						strokeColor : "#014d01",
//						strokeOpacity : paths.stroke.weight,
//						strokeWeight : 3,
//						icons : [ {
//							icon : {
//								path : google.maps.SymbolPath.FORWARD_CLOSED_ARROW
//							},
//							offset : '25px',
//							repeat : '50px'
//						} ]
//					});
//					var flightPath2 = new google.maps.Polyline({
//						path : pathOut,
//						geodesic : true,
//						strokeColor : "#cd6600",
//						strokeOpacity : paths.stroke.weight,
//						strokeWeight : 3,
//						icons : [ {
//							icon : {
//								path : google.maps.SymbolPath.FORWARD_CLOSED_ARROW
//							},
//							offset : '25px',
//							repeat : '50px'
//						} ]
//					});
//					flightPath1.setMap(userPaths.map);
//					flightPath2.setMap(userPaths.map);
					
					drawMarkers(paths,pathArr, pathIn, "green");
					drawMarkers(paths,pathArr, pathOut, "orange");
					
					UsersGeoFenceData.getGeoFenceData(markerUserName, function(response){
  						$.each(response, function(index, value) {
  							var coordinatesList = JSON.parse(value.coordinates);
  							var assetCorRes = value.assets[0].assetCoordinates;
  							var assetCorVal = [];
  							for (var i = 0; i < assetCorRes.length; i++) {
  								assetCorVal.push({lat:assetCorRes[i].latitude, lng:assetCorRes[i].longitude});;
							}
  							drawPolylineForGeoFence(assetCorVal, userPaths.map, "black", value.assets[0].name);
  							coordinatesList.push(coordinatesList[0]);
  							drawPolylineForGeoFence(coordinatesList, userPaths.map, "blue", value.name);
  						});
  					});
					
					function drawPolylineForGeoFence(latlngsToDraw,map, color, details) {
						var spreadAssetCoordinates = [];
						$.each(latlngsToDraw, function(index, value) {

							spreadAssetCoordinates.push({
								lat : value.lat,
								lng : value.lng
							});
						});

						if (spreadAssetCoordinates.length > 0) {
							var midLatLng = {
									lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
									lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
							};
							var infowindow = new google.maps.InfoWindow(
									{
										content : '<div class="infoWindowhead">'
										+ "name"
										+ '<div class="infoWindowContent"> <b>Description :</b> '
										+ "des" + '</div></div>'
									});
							
							infowindow.setPosition(midLatLng);
							var ColorValue = '#ff0000';
							if(color == "red"){
								ColorValue = '#ff0000';
							}else if(color == "black"){
								ColorValue = '#000000';	
							}else if(color == "blue"){
								ColorValue = '#68A8DE';
							}else if(color == "green"){
								ColorValue = '#00FF00';
							}else if(color == "orange"){
								ColorValue = '#cc8400';
							}else{
								ColorValue = '#208000';
							}
							var polylinepath = new google.maps.Polyline({
								path : spreadAssetCoordinates,
								geodesic : true,
								strokeColor : ColorValue,
								strokeOpacity : paths.stroke.weight,
								strokeWeight : 3
							});

							polylinepath.setOptions({
								strokeColor : ColorValue
							});
							
							google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
								infowindow.close();
							});
							
							google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
								if (openedInfoWindow != null){
							    	  openedInfoWindow.close();
							      }
								   infowindow.setContent('<div class="infoWindowhead">'
			                        		+ '<b></b>' + details + '</br></div>'
			                        );
			                        
			                        infowindow.mousemove;
									infowindow.open(map);
									infowindow.setPosition(event.latLng);
									openedInfoWindow = infowindow;
							});
							polylinepath.setMap(map);
						}
					}
				}

				// added for calculating previous point start
				function previos_dist(i, markers) {
					var locDiff = 0;
					if (i > 0) {
						var data1 = markers[i];
						var data2 = markers[i - 1];
						var radlat1 = Math.PI * data1.lat / 180
						var radlat2 = Math.PI * data2.lat / 180
						var theta = data1.lng - data2.lng
						var radtheta = Math.PI * theta / 180
						var locDiff = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
						locDiff = Math.acos(locDiff)
						locDiff = locDiff * 180 / Math.PI
						locDiff = locDiff * 60 * 1.1515
						locDiff = locDiff * 1.609344
						locDiff = locDiff * 1000
					}
					return locDiff;
				}
				// added for calculating previous point end

				/*
				 * function calculateAndDisplayRoute(directionsService, pointA,
				 * pointB) { directionsService.route({ origin: pointA,
				 * destination: pointB, avoidTolls: true, avoidHighways: false,
				 * travelMode: google.maps.TravelMode.DRIVING }, function
				 * (response, status) { if (status ==
				 * google.maps.DirectionsStatus.OK) {
				 * //directionsDisplay.setDirections(response); var pointsArray =
				 * (response.routes[0].overview_path); var j = 0; for ( j = 0; j <
				 * pointsArray.length; j++){ console.log(pointsArray[j].lat ());
				 * console.log(pointsArray[j].lng ()); } } else {
				 * console.log('Directions request failed due to ' + status); }
				 * }); }
				 */

				function drawMarkers(userdata, paths, userpath, colorVal) {
					var imgUrl = "content/images/dot.png";
					if(colorVal == "green"){
						imgUrl = "content/images/green_dot.png";
					}else{
						imgUrl = "content/images/orange_dot.png";
					}
					// path marker code start here////
//					var path_data = userpath;
					var markers = userpath;
//					var directionsService = new google.maps.DirectionsService;
					var infoWindow = new google.maps.InfoWindow();
					var lat_lng = new Array();
//					var latlngbounds = new google.maps.LatLngBounds();
					var icon = {
						url : imgUrl, // url
						scaledSize : new google.maps.Size(5, 5), // scaled
						// size
						origin : new google.maps.Point(0, 0), // origin
						anchor : new google.maps.Point(0, 0)
					// anchor
					};
					
					for (var i = 0; i < markers.length; i++) {
						var data = markers[i];
						var locDiff = previos_dist(i, markers);
						/*
						 * if(locDiff >100){ console.log(locDiff);
						 * 
						 * var data1 = markers[i]; var data2 = markers[i-1];
						 * 
						 * 
						 * calculateAndDisplayRoute(directionsService,data1,data2); }
						 */
						// added for calculating previous point
						var myLatlng = new google.maps.LatLng(data.lat, data.lng);
						lat_lng.push(myLatlng);
						var marker = new google.maps.Marker({
							position : myLatlng,
							map : userPaths.map,
							icon : icon
						});
						// latlngbounds.extend(marker.position);
						(function(marker, data) {

							var logdate;
							if (data.updatedDateTime != 0) {
								logdate = new Date(data.updatedDateTime);

							} else {
								logdate = new Date(data.createdDateTime);
							}

							var logDateTime = $filter('date')(logdate, 'dd MMM HH:mm:ss');

							var travel_data = '<div class="infoWindowhead">' + logDateTime + '</div><div class="infoWindowContent"><div class="rows"><p><strong>Battery</strong> :'
									+ data.batteryPercentage + ' %</p><!--<p><strong>Address: </strong>' + data.address + '</p>--><p><strong>Coordinates(Lat , Long): </strong>' + data.lat.toFixed(4)
									+ ' , ' + data.lng.toFixed(4) + '</p><!--<p><strong>DistFromPrevLoc: </strong>' + locDiff.toFixed(2) + ' Meters</p>--></div></div>'
							
							google.maps.event.addListener(marker, "mouseover", function(e) {
								infoWindow.setContent(travel_data);
								infoWindow.open(userPaths.map, marker);
							});
							google.maps.event.addListener(marker, "mouseout", function(e) {
								infoWindow.close(userPaths.map, marker);
							});
						})(marker, data);
						
						
					}

					// path marker code start here////

					// old code
//					var paths = userpath.path;
					var infoWindow = new google.maps.InfoWindow();
					var distanceIn = userdata.distanceIn;
					var distanceOut = userdata.distanceOut;
//					var distance = 0.0;
//					var path = flightPath.getPath();

					/*
					 * for (var i = 0; i < path.getLength() - 1; i++) { distance +=
					 * google.maps.geometry.spherical.computeDistanceBetween(path.getAt(i),
					 * path.getAt(i + 1)); }
					 */
					var startMarker = new google.maps.Marker({
						map : userPaths.map,
						position : new google.maps.LatLng(paths[0].lat, paths[0].lng),
						title : 'Started here'
					});
					startMarker.content = '<div class="infoWindowhead">' + userdata.userName + '</div>';

					google.maps.event.addListener(startMarker, 'mouseover', function() {
						infoWindow.setContent(startMarker.content + '<div class="infoWindowContent">' + startMarker.title + '</div>');
						infoWindow.open(userPaths.map, startMarker);

					});
					google.maps.event.addListener(startMarker, 'mouseout', function() {
						infoWindow.close(userPaths.map, startMarker);
					});

					var infoWindow1 = new google.maps.InfoWindow();
					var endMarker = new google.maps.Marker({
						map : userPaths.map,
						position : new google.maps.LatLng(paths[paths.length - 1].lat, paths[paths.length - 1].lng),
						title : "Inside Geofence - " + (distanceIn).toFixed(2) + " KM and Outside Geofence " + (distanceOut).toFixed(2)+" KM"

					})

					$scope.totalDistanceTravelled = "Total Distance -"+(distanceIn+distanceOut).toFixed(2);
					$scope.totalGeoTravelled = "Inside Geofence - "+ (distanceIn).toFixed(2) + " KM and Outside Geofence - " + (distanceOut).toFixed(2) + " KM";

					endMarker.content = '<div class="infoWindowhead">' + userdata.userName + '</div>';

					google.maps.event.addListener(endMarker, 'mouseover', function() {

						infoWindow1.setContent(endMarker.content + '<div class="infoWindowContent">' + endMarker.title + '</div>');
						infoWindow1.open(userPaths.map, endMarker);

					});
					google.maps.event.addListener(endMarker, 'mouseout', function() {
						infoWindow1.close(userPaths.map, endMarker);
					});
//					resizeMap();
					// old code end
				}

			});

			
//			function resizeMap() {
//				setTimeout(function() {
//					google.maps.event.trigger(userPaths.map, "resize");
//					userPaths.map.setCenter(userPaths.latlng);
//				}, 100);
//			}
		}
		$scope.$on('$destroy', function() {
			$interval.cancel(updateLogs);
		});

		function initializeMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].latitude, latlangs[0].longitude);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function(position) {

						google.maps.event.trigger(locations.map, "resize");
						var centerlatlng = {
							lat : 12.9716,
							lng : 77.5946
						};
						locations.map.setCenter(centerlatlng);
					}, function() {
						setTimeout(function() {
							google.maps.event.trigger(locations.map, "resize");
							var centerlatlng = {
								lat : 12.9716,
								lng : 77.5946
							};
							locations.map.setCenter(centerlatlng);
						}, 100);
						// callback(new google.maps.LatLng("12.9716",
						// "77.5946"));
					});
				} else {
					setTimeout(function() {
						google.maps.event.trigger(locations.map, "resize");
						var centerlatlng = {
							lat : 12.9716,
							lng : 77.5946
						};
						locations.map.setCenter(centerlatlng);
					}, 100);
					// callback(new google.maps.LatLng("12.9716", "77.5946"));
				}
			}
		}

		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;

		}

		$scope.reset = function() {
			var cleratodatefromdate = vm.today();
			// cleratodatefromdate.remove();
		}

	}
})();
