(function () {
    'use strict';

    angular.module('trakeyeApp').controller('AssetController', AssetController);

    AssetController.$inject = ['$scope', '$state', 'Asset', 'ParseLinks',
		'AlertService', 'pagingParams', 'paginationConstants', 'AssetType', 'AllGeofences',
		'AssetSearch', 'AssetSearchForMap', 'AssetsForMap', 'AllCusAssetTypeAttr', 'AssetByID',
		'AssetUser', '$compile', '$interval'];

    function AssetController($scope, $state, Asset, ParseLinks, AlertService,
        pagingParams, paginationConstants, AssetType, AllGeofences, AssetSearch, AssetSearchForMap,
        AssetsForMap, AllCusAssetTypeAttr, AssetByID, AssetUser, $compile, $interval) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;

        vm.generatedLat = 0.0;
        vm.generatedLng = 0.0;
        vm.parentName = "";
        vm.childName = "";
        vm.generatedAssetName;
        vm.filterAssetsformap = filterAssetsformap;
        vm.assets = [];
        $scope.tab = 1;
        var latlng;
        var locations = {};
        var userPaths = {};
        loadPage();
        var assetFromToDistance = 0.0;
        var prevInfoWindow;
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };
        vm.customAttributesMap = [];
        vm.multiassets = [];
        vm.fromAssetsView = [];
        vm.toAssetsView = [];
        vm.toAssetsMap = [];
        var openedInfoWindow = null;
        var assetsArray = [];
        var selctedAssets = [];
        var selectedAssetForIssue = [];
        var selectedFromAsset = 0;
        var selectedFromAssetMap = 0;
        var selectedToAssetMap = 0;
        var selectedToAsset = 0;
        var markers = [];
        var polylines = [];
        var isFromAssetSelected = false;
        var isToAssetSelected = false;
        var isPointASelected = false;
        var isPointBSelected = false;
        var pointALoc = null;
        var pointBLoc = null;
        var isFromAssetMapSelected = false;
		var prevSelectedMarker = null;
        var isToAssetMapSelected = false;
        var parentAssetList = [];
        var savedAssetFromMapLoc = null;
        vm.EditAssetName = '';

        document.getElementById('pointB').style.borderColor = "#A0A0A0";
        document.getElementById('pointB').style.color = "#A0A0A0";
        document.getElementById('pointResult').style.borderColor = "#A0A0A0";
        document.getElementById('pointResult').style.color = "#A0A0A0";

        if ($scope.tab == 1) {
            filterSearch();
        } else if ($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4 || $scope.tab == 5 || $scope.tab == 6) {
			filterAssetsformap();
        }
        var updateAssets = $interval(function () {
            if ($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4 || $scope.tab == 5 || $scope.tab == 6){
			    filterAssetsformap();
			}

            // }, 1000000);
        }, 100000000);
        $(".select2").select2();

        $("#fromAssetMap").change(function () {
            selectedFromAssetMap = $("#fromAssetMap").val();
            var child = null;
            vm.toAssetsMap = [];
            var multiassets = vm.multiassets;
            multiassets.forEach(function (item, index) {
                if (selectedFromAssetMap == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            if (selectedFromAssetMap.length > 0) {
                for (var i = 0; i < assetsArray.length; i++) {
                    if (assetsArray[i].id == selectedFromAssetMap) {
                        //						if(markerVal != null){
                        //							markerVal.setMap(null);
                        //						}

                        selectedAssetForIssue = assetsArray[i];
                        var cableLengthFromBaseForSelectedAsset = 0.0;
                        var assetTypeAttributeValuesList = selectedAssetForIssue.assetTypeAttributeValues;
                        for (var index in assetTypeAttributeValuesList) {
                            if (assetTypeAttributeValuesList[index].name == "Cable length") {
                                cableLengthFromBaseForSelectedAsset = assetTypeAttributeValuesList[index].attributeValue;
                            }
                        }
                        $("#myText").html("Fiber length from POP : " + cableLengthFromBaseForSelectedAsset);
                        var lati = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length - 1].latitude;
                        var lngi = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length - 1].longitude;
                        createMarkerForSelectedAsset(lati, lngi, selectedAssetForIssue.name);
                        isFromAssetMapSelected = true;
                        document.getElementById("distanceValue").value = "";
                    }
                }
                //				isToAssetMapSelected = false;
                //				$("#toAssetMap")[0].selectedIndex = 0;
                parent = child.ownedBy;
                if (parent.length > 0) {
                    vm.toAssetsMap.push(recursiveOwnedByAssetsMap(parent, multiassets));
                }
                loadPage();
            } else {
                isFromAssetMapSelected = false;
                $("#myText").html("");
            }

        })

        /*$("#toAssetMap").change(function(){
			if(isFromAssetMapSelected){
				var child = {};
				var parent = {};
				var cableLengthFromBaseForFromAsset = 0.0;
				var cableLengthFromBaseForToAsset = 0.0;
				selectedToAssetMap = $("#toAssetMap").val();
				if(selectedToAssetMap != "" || selectedToAssetMap != null){
					var multiassets = vm.multiassets;
					multiassets.forEach(function(item, index) {
						if(selectedFromAssetMap == multiassets[index].id){
							child = multiassets[index];
						}
					});
					if(selectedFromAssetMap.length > 0){
						for(var i = 0; i < assetsArray.length;i++){
							if(assetsArray[i].id == selectedFromAssetMap){
//								if(markerVal != null){
//									markerVal.setMap(null);
//								}

								selectedAssetForIssue = assetsArray[i];

								var assetTypeAttributeValuesList = selectedAssetForIssue.assetTypeAttributeValues;
								for ( var index in assetTypeAttributeValuesList) {
									if(assetTypeAttributeValuesList[index].name == "Cable length"){
										cableLengthFromBaseForFromAsset = assetTypeAttributeValuesList[index].attributeValue;
									}
								}
								$("#myText").html("Fiber length from "+ child.name + " : "+ cableLengthFromBaseForFromAsset);
								var lati = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length-1].latitude;
								var lngi = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length-1].longitude;
								createMarkerForSelectedAsset(lati, lngi, selectedAssetForIssue.name);
								isToAssetMapSelected = true;
								document.getElementById("distanceValue").value = "";
							}
						}

					}
					multiassets.forEach(function(item, index) {
						if(selectedToAssetMap == multiassets[index].id){
							parent = multiassets[index];
						}
					});
					if(selectedToAssetMap.length > 0){
						for(var i = 0; i < assetsArray.length;i++){
							if(assetsArray[i].id == selectedToAssetMap){
//								if(markerVal != null){
//									markerVal.setMap(null);
//								}

								selectedAssetForIssue = assetsArray[i];
								var assetTypeAttributeValuesList = selectedAssetForIssue.assetTypeAttributeValues;
								for ( var index in assetTypeAttributeValuesList) {
									if(assetTypeAttributeValuesList[index].name == "Cable length"){
										cableLengthFromBaseForToAsset = assetTypeAttributeValuesList[index].attributeValue;
									}
								}
								$("#myText").html("Fiber length from "+ parent.name + " : "+ (cableLengthFromBaseForFromAsset - cableLengthFromBaseForToAsset));
								var lati = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length-1].latitude;
								var lngi = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length-1].longitude;
								createMarkerForSelectedAsset(lati, lngi, selectedAssetForIssue.name);
								isToAssetMapSelected = true;
							}
						}

					}
				}else{
					isToAssetMapSelected = false;
				}
			}
		})*/

        //		$("#toAssetMap").click(function() {
        //			if(!isFromAssetMapSelected){
        //				alert("No From Asset Selected");
        //			}
        //		})

        $("#fromAssetsView").change(function () {
            var child = {};
            vm.toAssetsView = [];
            var multiassets = vm.multiassets;
            selectedFromAsset = $(this).val();
            multiassets.forEach(function (item, index) {
                if (selectedFromAsset == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            isFromAssetSelected = true;

            isToAssetSelected = false;
            $("#toAssetsView")[0].selectedIndex = 0;
            parent = child.ownedBy;
            if (parent.length > 0) {
                vm.toAssetsView.push(recursiveOwnedByAssets(parent, multiassets));
            }
            loadPage();
        })

        var recursiveOwnedByAssets = function (childVal, multiassets) {
            var child = childVal[0];
            multiassets.forEach(function (item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    vm.toAssetsView.push(recursiveOwnedByAssets(parentVal, multiassets));
                }
            }
            return child;
        };
        var recursiveOwnedByAssetsMap = function (childVal, multiassets) {
            var child = childVal[0];
            multiassets.forEach(function (item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    vm.toAssetsMap.push(recursiveOwnedByAssetsMap(parentVal, multiassets));
                }
            }
            return child;
        };

        $("#toAssetsView").change(function () {

            var toAssetId = $(this).val();
            var toAssetAV = [];
            var child = {};
            var childAV = [];
            var fromAssetDistance = 0.0;
            var toAssetDistance = 0.0;
            var multiassets = vm.multiassets;
            latlngs = [];
            redLatLngs = [];
            var isValidLength = true;
            selectedToAsset = $(this).val();
            if (selectedToAsset != "" || selectedToAsset != null) {
                isToAssetSelected = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAsset) {
                        if (isFromAssetSelected) {
                            child = multiassets[i];
                            childAV = multiassets[i].assetTypeAttributeValues;
                        }
                    }
                }
                childAV.forEach(function (item, index) {
                    if (item.name == "Distance") {
                        fromAssetDistance = parseFloat(item.attributeValue);
                    }
                });

                multiassets.forEach(function (item, index) {
                    if (toAssetId == multiassets[index].id) {
                        toAssetAV = multiassets[index].assetTypeAttributeValues;
                    }
                });

                toAssetAV.forEach(function (item, index) {
                    if (item.name == "Distance") {
                        toAssetDistance = parseFloat(item.attributeValue);
                    }
                });
                assetFromToDistance = (fromAssetDistance - toAssetDistance);
            } else {
                isToAssetSelected = false;
            }
        })

        $("#toAssetsView").click(function () {
            if (!isFromAssetSelected) {
                alert("No From Asset Selected");
            }
        })

        function createMarkerForSelectedAsset(lati, lngi, name) {
			
			if(prevSelectedMarker != null){
				prevSelectedMarker.setMap(null);
			}
			
            var pos = {
                lat: lati,
                lng: lngi
            };
            var marker = new google.maps.Marker({
                position: pos,
                map: locations.map,
                animation: google.maps.Animation.DROP,
                content: markerContent,
                icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                label: {
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    text: name,
                    backgroundcolor: "#ff9800"
                },
                title: name
            });
            var markerContent = '<div class="infoWindowhead">' +
                name +
                '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                lati +
                '<br> <b>Lng :</b> ' +
                lngi +
                '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + lati + ',' + lngi + '>Go To</a></b>' +
                '</div>';

            //			markerVal = marker;
            var infowindow = new google.maps.InfoWindow({
                content: markerContent
            });
            locations.map.setCenter(pos);
            marker.addListener('click', function () {
                infowindow.open(locations.map, marker);
            });
			
			prevSelectedMarker = marker;
        }
        $scope.$on('$destroy', function () {
            $interval.cancel(updateAssets);
        });
        $scope.clearSearch = function () {
            $scope.searchAll = null;
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }


        $scope.gotoGeneratedLocation = function () {
            hideModal();
            $state.go('tr-case.new', {
                'lat': vm.generatedLat,
                'lng': vm.generatedLng,
                'assetdetails': vm.childName,
                'desc': "Faulty cable between " + vm.parentName + " and " + vm.childName
            });
        };

        $scope.alertText = function (text) {
            if (text == 'PointA') {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
                isPointASelected = true;
                isPointBSelected = false;
                pointBLoc = null;
                vm.multiassets.forEach(function (value) {
                    if (value.assetType != null && value.assetType.layout == 'SPREAD') {
                        drawPolyline(value);
                    }
                });
            } else if (text == 'PointB') {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
                if (isPointASelected) {
                    isPointBSelected = true;
                    vm.multiassets.forEach(function (value) {
                        if (value.assetType != null && value.assetType.layout == 'SPREAD') {
                            drawPolyline(value);
                        }
                    });
                }
            } else if (text == 'LinkResult') {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                if (isPointASelected && isPointBSelected) {

                    isPointASelected = false;
                    isPointBSelected = false;
                    if (pointALoc == null) {
                        alert("Please select Point A");
                    } else if (pointBLoc == null) {
                        alert("Please select Point B");
                    } else {
                        var pointA = new google.maps.LatLng(pointALoc.lat, pointALoc.lng);
                        var pointB = new google.maps.LatLng(pointBLoc.lat, pointBLoc.lng);

                        var newAsset = {
                            name: '',
                            assetCoordinates: []
                        };
                        newAsset.name = "new asset " + Date.now();

                        var directionsService = new google.maps.DirectionsService;
                        var myOptions = {
                            zoom: 14,
                            center: pointA,
                            mapTypeId: google.maps.MapTypeId.TERRAIN
                        };
                        locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
                        var polylineOptionsActual = new google.maps.Polyline({
                            strokeColor: '#ff0000',
                            strokeOpacity: 0.5,
                            strokeWeight: 4
                        });
                        var directionsDisplay = new google.maps.DirectionsRenderer({
                            map: locations.map,
                            polylineOptions: polylineOptionsActual,
                            suppressMarkers: true
                        });

                        directionsService.route({
                            origin: pointA,
                            destination: pointB,
                            avoidTolls: true,
                            avoidHighways: false,
                            travelMode: google.maps.TravelMode.DRIVING
                        }, function (response, status) {
                            if (status == google.maps.DirectionsStatus.OK) {
                                directionsDisplay.setDirections(response);
                                var responsePath = response.routes[0].overview_path;
                                vm.fixedArray = [];
                                responsePath.forEach(function (value) {
                                    var coordinates = {};
                                    coordinates.latitude = value.lat();
                                    coordinates.longitude = value.lng();
                                    vm.fixedArray.push(coordinates);
                                });
                                newAsset.assetCoordinates = vm.fixedArray;
                                newAsset.layout == 'SPREAD'

                                AssetType.query({}, function (data) {
                                    data.forEach(function (item) {
                                        if (item.name == "Two point cable") {
                                            newAsset.assetType = item;

                                            Asset.save(newAsset, onSaveSuccess, onSaveError);

                                            function onSaveSuccess(result) {

                                                document.getElementById('pointB').style.borderColor = "#A0A0A0";
                                                document.getElementById('pointB').style.color = "#A0A0A0";
                                                document.getElementById('pointResult').style.borderColor = "#A0A0A0";
                                                document.getElementById('pointResult').style.color = "#A0A0A0";

                                                $scope.$emit('trakeyeApp:assetSave', result);
                                                vm.isSaving = false;
                                                $state.go('asset');
                                                hideEditModal();

                                                AssetByID.get(result.id, function (response) {
                                                    vm.multiassets.push({
                                                        lat: response.assetCoordinates[response.assetCoordinates.length - 1].latitude,
                                                        lng: response.assetCoordinates[response.assetCoordinates.length - 1].longitude,
                                                        assetTypeAttributeValues: response.assetTypeAttributeValues,
                                                        name: response.name,
                                                        id: response.id,
                                                        assetType: {
                                                            layout: response.assetType.layout,
                                                            colorcode: response.assetType.colorcode
                                                        },
                                                        ownedBy: response.ownedBy,
                                                        typeName: response.assetType.name,
                                                        ticked: true,
                                                        assetCoordinates: response.assetCoordinates,
                                                        imgPath: response.assetType.imagePath
                                                    });
                                                    polylineOptionsActual.setMap(null);

                                                    vm.multiassets.forEach(function (value) {
                                                        drawPolyline(value);
                                                    });
                                                });
                                            }

                                            function onSaveError() {
                                                vm.isSaving = false;
                                            }
                                        }
                                    });

                                });


                            } else {
                                window.alert('Directions request failed due to ' + status);
                            }
                        });
                    }
                }
            }
        };

        $scope.saveEditAsset = function () {
            vm.isSaving = false;
            var assetAV = vm.asset.assetTypeAttributeValues;

            assetAV.forEach(function (item, index) {
                if (item.name == "Fiber Core") {
                    item.attributeValue = $scope.selectJustId;

                }
                if (item.name == "Cable Length From Base") {
                    item.attributeValue = $("#otdr-val").val();
                }
            });
            vm.asset.assetTypeAttributeValues = assetAV;
            if (vm.asset != null) {
                Asset.update(vm.asset, onSaveSuccess, onSaveError);

                function onSaveSuccess(result) {
                    $scope.$emit('trakeyeApp:assetUpdate', result);
                    vm.isSaving = false;
                    $state.go('asset');
                    hideEditModal();
                    filterAssetsformap();

                }

                function onSaveError() {
                    vm.isSaving = false;
                }
            }

            savedAssetFromMapLoc = new google.maps.LatLng(vm.asset.assetCoordinates[vm.asset.assetCoordinates.length - 1].latitude,
                vm.asset.assetCoordinates[vm.asset.assetCoordinates.length - 1].longitude);
            vm.asset = null;
        };



        $scope.gotoGeneratedLocation2 = function () {
            var transform = $(".gm-style>div:first>div:first>div:last>div").css("transform")
            var comp = transform.split(",") // split up the transform matrix
            var mapleft = parseFloat(comp[4]) // get left value
            var maptop = parseFloat(comp[5]) // get top value
            $(".gm-style>div:first>div:first>div:last>div").css({
                "transform": "none",
                "left": mapleft,
                "top": maptop,
            });


            $(".gm-style>div:first>div:first>div:nth-child(2)>div:first>div").css({
                "transform": "none",
                "left": mapleft,
                "top": maptop,
            });

            html2canvas($('#mainDiv'), {
                useCORS: true,
                onrendered: function (canvas) {
                    var dataUrl = canvas.toDataURL('image/png');
                    var doc = new jsPDF('l', 'px', 'a3');
                    doc.addImage(dataUrl, 'PNG', 10, 10, 605, 400);
                    doc.save('SLD_Export.pdf');
                }
            });
        }

        $scope.gotoGeneratedLocation1 = function () {
            html2canvas([document.getElementById('location-map-asset')], {
                useCORS: true,
                optimized: false,
                allowTaint: false,
                onrendered: function (canvas) {
                    var imgData = canvas.toDataURL(
                        'image/png');

                    var doc = new jsPDF('l', 'px', 'a4');
                    doc.addImage(imgData, 'PNG', 20, 20, 1000, 640);
                    doc.save('sample-file.pdf');
                }
            });

        };

        function saveAs(uri, filename) {
            var link = document.createElement('a');
            if (typeof link.download === 'string') {
                link.href = uri;
                link.download = filename;

                // Firefox requires the link to be in the body
                document.body.appendChild(link);

                // simulate click
                link.click();

                // remove the link when done
                document.body.removeChild(link);
            } else {
                window.open(uri);
            }
        }

        function hideModal() {
            $("#mapModalForFiberTracking").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapModalForFiberTracking").hide();
        }

        function hideEditModal() {
            $("#userPathModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#userPathModal").hide();
        }
        $('#userPathModal').on('hidden.bs.modal', function () {
            //			filterAssetsformap();
        })

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4 || $scope.tab == 5 || $scope.tab == 6) {
                filterAssetsformap();
            } else if ($scope.tab == 1) {
                filterSearch();
            }

        };

        function select2() {
            $(".select2").select2();
            $(".select2").on('select2:select', function (e) {
                alert($(".select2").val());
            });
        }

        function filterAssetsformap() {
            if (locations.map != null) {
                var myOptions = {
                    zoom: 14,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.TERRAIN
                };
                locations.map = new google.maps.Map(document
                    .getElementById("map_canvas"), myOptions);
            }

            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search)) {
                vm.multiassets = [];
                assetsArray = [];

                AssetSearchForMap.searchassetsformap($scope.search, function (
                    response) {
                    vm.fromAssetsView = [];
                    response.forEach(function (asset) {
                        vm.fromAssetsView.push({
                            id: asset.id,
                            name: asset.name,
                            ticked: false,
                            ownedBy: asset.ownedBy
                        });
                        if (selctedAssets.indexOf(asset.id) !== -1) {
                            vm.multiassets.push({
                                lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                name: asset.name,
                                id: asset.id,
                                assetType: {
                                    layout: response.layout
                                },
                                ownedBy: asset.ownedBy,
                                typeName: asset.assetType.name,
                                ticked: true,
                                assetCoordinates: asset.assetCoordinates,
                                imgPath: asset.assetType.imagePath
                            });
                        } else {
                            vm.multiassets.push({
                                lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                name: asset.name,
                                id: asset.id,
                                assetType: {
                                    layout: response.layout
                                },
                                ownedBy: asset.ownedBy,
                                typeName: asset.assetType.name,
                                ticked: false,
                                assetCoordinates: asset.assetCoordinates,
                                imgPath: asset.assetType.imagePath
                            });
                        }
                        assetsArray.push(asset);
                    });
                    drawLatestAssets(response);
                });

            } else {
                vm.multiassets = [];
                assetsArray = [];
                AssetsForMap.assetsformap(function (response) {
                    vm.fromAssetsView = [];
                    response.forEach(function (asset) {
                        vm.fromAssetsView.push({
                            id: asset.id,
                            name: asset.name,
                            ticked: false,
                            ownedBy: asset.ownedBy
                        });

                        try {
                            if (selctedAssets.indexOf(asset.id) !== -1) {
                                vm.multiassets.push({
                                    lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                    lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                    assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                    name: asset.name,
                                    id: asset.id,
                                    assetType: {
                                        layout: response.layout
                                    },
                                    ownedBy: asset.ownedBy,
                                    typeName: asset.assetType.name,
                                    ticked: true,
                                    assetCoordinates: asset.assetCoordinates,
                                    imgPath: asset.assetType.imagePath
                                });
                            } else {
                                vm.multiassets.push({
                                    lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                    lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                    assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                    name: asset.name,
                                    id: asset.id,
                                    assetType: {
                                        layout: response.layout
                                    },
                                    ownedBy: asset.ownedBy,
                                    typeName: asset.assetType.name,
                                    ticked: false,
                                    assetCoordinates: asset.assetCoordinates,
                                    imgPath: asset.assetType.imagePath
                                });
                            }
                            assetsArray.push(asset);
                        } catch (err) {

                        }

                    });
                    drawLatestAssets(response);
                });
            }

            if (savedAssetFromMapLoc != null) {
                if (locations.map != null) {
                    var myOptions = {
                        zoom: 17,
                        center: savedAssetFromMapLoc
                    };
                    locations.map = new google.maps.Map(document
                        .getElementById("map_canvas"), myOptions);
                }
            }

			if($scope.tab == 6){
	        	AllGeofences.getAll(function(response){
	        		response.forEach(function(value){
	                	try {
	                		var coordinatesList = JSON.parse(value.coordinates);
	                		drawPolylineForGeoFence(coordinatesList, locations.map, "blue", value);
						} catch (e) {
						}
	                });
	        	});
			}

        }
 		

        function drawPolylineForGeoFence(latlngsToDraw,map, details, geofence) {
			var spreadAssetCoordinates = [];
			$.each(latlngsToDraw, function(index, value) {

				spreadAssetCoordinates.push({
					lat : value.lat,
					lng : value.lng
				});
			});

			if(spreadAssetCoordinates.length == 1){

				var midLatLng = {
						lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
						lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
				};
				// Create our info window content
				  var infoStr = "";
				  var geoAssets = geofence.assets;
            
					var wdmCount = 0;
					var nttnCount = 0;
					var othersCount = 0;	
					var backtobackCount = 0;
					var routerCount = 0;
					
					var assetStr = "";
	                
					geoAssets.forEach(function(item1){
							if(item1.assetType.name == 'WDM'){
								wdmCount++;
								assetStr += '<b>WDM</b> : ' + item1.name + '<br>'
							}else if(item1.assetType.name == 'NTTN'){
								nttnCount++;
								assetStr += '<b>NTTN</b> : ' + item1.name + '<br>'
							}else if(item1.assetType.name == 'Router'){
								routerCount++;
								assetStr += '<b>Router</b> : ' + item1.name + '<br>'
							}else if(item1.assetType.name == 'BackToBack'){
								backtobackCount++;
								assetStr += '<b>BackToBack</b> : ' + item1.name + '<br>'
							}else if(item1.assetType.name == 'Others'){
								othersCount++;
								assetStr += '<b>Others</b> : ' + item1.name + '<br>'
							}
			
	             	 });

					var infotable = '<table border="1" style="width:100%;text-align:center">'
					+'<tr>'
						+'<th style="padding: 5px;text-align:center">WDM</th>'
						+'<th style="padding: 5px;text-align:center">NTTN</th>'
						+'<th style="padding: 5px;text-align:center">Router</th>'
						+'<th style="padding: 5px;text-align:center">BackToBack</th>'
						+'<th style="padding: 5px;text-align:center">Others</th>'
					+'</tr>'
					+'<tr>'
						+'<td style="padding: 5px;text-align:center">'+ wdmCount +'</td>'
						+'<td style="padding: 5px;text-align:center">'+ nttnCount +'</td>'
						+'<td style="padding: 5px;text-align:center">'+ routerCount +'</td>'
						+'<td style="padding: 5px;text-align:center">'+ backtobackCount +'</td>'
						+'<td style="padding: 5px;text-align:center">'+ othersCount +'</td>'
					+'</tr>'
				+'</table>';
				
			    var infoWindowContent = '<div class="info_content">' +
			        '<b>'+geofence.name+'</b>' +
			        '<br><br>' + infotable +
			        '</div>';

			    // Initialise the inforWindow
			    var infoWindow = new google.maps.InfoWindow({
			        content: infoWindowContent
			    });
			                
			    var labelVal = geofence.name.charAt(0).toUpperCase();
			    
			    var iconUrl = '';
			    
			    if(wdmCount > 0 && nttnCount > 0 && routerCount > 0){
			    	iconUrl = 'content/images/green_circle.png';
			    }else if(wdmCount > 0 && nttnCount > 0){
			    	iconUrl = 'content/images/blue_circle.png';
			    }else if(nttnCount > 0 && routerCount > 0){
			    	iconUrl = 'content/images/pink_circle.png';
			    }else if(nttnCount > 0){
			    	iconUrl = 'content/images/orange_circle.png';
			    }else{
			    	iconUrl = 'content/images/red_circle.png';
			    }
			    
			    // Add a marker to the map based on our coordinates
			    var marker = new google.maps.Marker({
			        position: midLatLng,
			        map: map,
			        icon: {
                        scaledSize: new google.maps.Size(
                            36, 36),
                        origin: new google.maps.Point(0, 0),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            24, 24),

                    },
			        label: {text: labelVal, color: "white", fontSize: "20px", fontWeight: "bold"},
			        title: geofence.name
			    });
			    
			    // Display our info window when the marker is clicked
			    google.maps.event.addListener(marker, 'click', function(e) {
					if (openedInfoWindow != null){
						openedInfoWindow.close();
					}
			        infoWindow.open(map, marker);
			        openedInfoWindow = infoWindow;
			    });
			    
			    
			}else if(spreadAssetCoordinates.length > 1) {
				var midLatLng = {
						lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
						lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
				};
				var infowindow = new google.maps.InfoWindow(
						{
							content : '<div class="infoWindowhead">'
							+ "name"
							+ '<div class="infoWindowContent"> <b>Description :</b> '
							+ "des" + '</div></div>'
						});
				
				infowindow.setPosition(midLatLng);
				var ColorValue = '#33001a';	

				var polylinepath = new google.maps.Polyline({
					path : spreadAssetCoordinates,
					geodesic : true,
					strokeColor : ColorValue,
					strokeWeight : 3
				});

				polylinepath.setOptions({
					strokeColor : ColorValue
				});
				
				google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
					infowindow.close();
				});
				
				google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
					if (openedInfoWindow != null){
						openedInfoWindow.close();
					}
					infowindow.setContent('<div class="infoWindowhead">'
								+ '<b></b>' + geofence.name + '</br></div>'
						);
						
						infowindow.mousemove;
						infowindow.open(map);
						infowindow.setPosition(event.latLng);
						openedInfoWindow = infowindow;
				});
				polylinepath.setMap(map);
			}
		}
        function filterSearch() {
            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search)) {
                AssetSearch.query({
                    search: $scope.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ','
						+
                        (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assets = data;
                    vm.page = pagingParams.page;

                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            Asset.query({
                page: pagingParams.page - 1,
                search: $scope.search,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ','
					+
                    (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    // result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.assets = data;

                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            AssetUser.gettrcasesuser(function (response) {
                $scope.AssetUser = response;

            });
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search
            });
        }

        function drawLatestAssets(response) {

            if (response) {
                initializeAssetMap(
                    response,
                    function (latlang) {
                        userPaths.latlng = latlang;
                        locations.latlng = latlang;
                        setupAssetMap(function () {
                            $.each(response,
                                function (index, value) {
                                    if ($scope.tab != 5) {
                                        if (value.assetType != null && value.assetType.layout == 'FIXED' && $scope.tab != 6) {
                                            if (value.assetCoordinates.length > 1) {
                                                var assetLocations = value.assetCoordinates;
                                                drawPolyline(value);
                                                var value1 = value;
                                                value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

                                                if (value.ownedBy.length > 0) {

                                                    var disFromParent = 0.0;
                                                    var cableLengthFromBase = 0.0;
                                                    if (assetLocations.length > 1) {
                                                        for (var index in assetLocations) {
                                                            if (index >= 1) {
                                                                var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, assetLocations[index - 1].latitude, assetLocations[index - 1].longitude);
                                                                if (disCal > 0) {
                                                                    disFromParent += disCal;
                                                                }
                                                            } else if (index == 0) {
                                                                var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, value.ownedBy[0].assetCoordinates[value.ownedBy[0].assetCoordinates.length - 1].latitude, value.ownedBy[0].assetCoordinates[value.ownedBy[0].assetCoordinates.length - 1].longitude);
                                                                if (disCal > 0) {
                                                                    disFromParent += disCal;
                                                                }
                                                            }
                                                        }
                                                    }


                                                    var assetTypeAttributeValuesList = value1.assetTypeAttributeValues;
                                                    for (var index in assetTypeAttributeValuesList) {
                                                        if (assetTypeAttributeValuesList[index].name == "Cable Length From Base") {
                                                            cableLengthFromBase = assetTypeAttributeValuesList[index].attributeValue;
                                                        }
                                                    }
                                                    var loopLength = cableLengthFromBase - (disFromParent * 1000);
                                                    for (var index in assetTypeAttributeValuesList) {
                                                        if (assetTypeAttributeValuesList[index].name == "Left Loop Length") {
                                                            assetTypeAttributeValuesList[index].attributeValue = loopLength / 2;
                                                        } else if (assetTypeAttributeValuesList[index].name == "Right Loop Length") {
                                                            assetTypeAttributeValuesList[index].attributeValue = loopLength / 2;
                                                        }

                                                    }
                                                    value1.assetTypeAttributeValues = assetTypeAttributeValuesList;
                                                    drawFixedAsset(value1, true);
                                                } else {
                                                    drawFixedAsset(value1, false);
                                                }
                                            } else {
												
                                                if (value.ownedBy.length > 0) {
                                                    drawFixedAsset(value, true);
                                                } else {
                                                    drawFixedAsset(value, false);
                                                }
                                                if (value.ownedBy.length > 0) {
                                                    var value1 = value;

                                                    var locObj0 = value.assetCoordinates[value.assetCoordinates.length - 1];
                                                    value1.assetCoordinates.push(locObj0);

                                                    var locObj1 = value.ownedBy[0].assetCoordinates[value.ownedBy[0].assetCoordinates.length - 1];
                                                    value1.assetCoordinates.push(locObj1);

                                                    drawPolyline(value1);
                                                }
                                            }
                                        }
                                    }
                                    if (value.assetType != null && value.assetType.layout == 'SPREAD') {
                                        drawPolyline(value);
                                    }
                                });
                            if ($scope.tab == 3) {
                                $.each(
                                    $scope.AssetUser,
                                    function (index, value) {
                                        var infoWindow = new google.maps.InfoWindow();
                                        var startMarker = new google.maps.Marker({
                                            map: locations.map,
                                            position: new google.maps.LatLng(
                                                value.latitude,
                                                value.longitude),
                                            title: value.login,

                                        });
                                        var infoWindow2 = new google.maps.InfoWindow();
                                        if (value.status == 'ACTIVE') {
                                            startMarker
                                                .setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png');
                                        } else if (value.status == 'INACTIVE') {
                                            startMarker
                                                .setIcon('http://maps.google.com/mapfiles/ms/icons/red-dot.png');
                                        } else if (value.status == 'IDLE') {
                                            startMarker
                                                .setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png');
                                        }
                                        startMarker.content = '<div class="infoWindowContent"> <b>userLogin :</b> ' +
                                            value.login +
                                            '<br> <b> Status  :</b> ' +
                                            value.status +
                                            '<br> <b>Address :</b> ' +
                                            value.address +
                                            '<br> <b>UserId :</b> ' +
                                            value.userid +
                                            '</div>';

                                        $compile(
                                                startMarker.content)
                                            ($scope);

                                        google.maps.event.addListener(startMarker, 'mouseover',
                                            function () {
                                                if (openedInfoWindow != null) {
                                                    openedInfoWindow.close();
                                                }

                                                infoWindow.setContent('<div class="infoWindowhead">' +
                                                    startMarker.title +
                                                    '</div>' +
                                                    startMarker.content);
                                                infoWindow.open(locations.map, startMarker);
                                                openedInfoWindow = infoWindow;
                                            });

                                        google.maps.event.addListener(startMarker, 'mouseout',
                                            function () {
                                                infoWindow.close();
                                            });

                                        var activeInfoWindow;
                                        infoWindow2.setContent('<div class="infoWindowhead">' +
                                            startMarker.title +
                                            '</div>' +
                                            startMarker.content);

                                    })
                            }
                        });
                    });
            }

            function setupAssetMap(callback) {
                if (locations.map == null) {
                    var myOptions = {
                        zoom: 14,
                        center: locations.latlng,
                        mapTypeId: google.maps.MapTypeId.TERRAIN
                    };

                    locations.map = new google.maps.Map(document
                        .getElementById("map_canvas"), myOptions);
                } else {
                    // Loop through all the markers and polylines to remove

                    for (var i = 0; i < markers.length; i++) {
                        markers[i].setMap(null);
                    }

                    for (i = 0; i < polylines.length; i++) {
                        polylines[i].setMap(null);
                    }

                    markers = [];
                    polylines = [];
                }
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById('map_canvas');
                callback();
            }

            function initializeAssetMap(latlangs, callback) {

                if (latlangs && latlangs.length > 0) {
                    latlng = new google.maps.LatLng(
                        latlangs[0].assetCoordinates[latlangs[0].assetCoordinates.length - 1].latitude,
                        latlangs[0].assetCoordinates[latlangs[0].assetCoordinates.length - 1].longitude);
                    callback(latlng);
                } else {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (
                            position) {

                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude);
                            callback(latlng);
                        }, function () {

                            callback(new google.maps.LatLng("12.9716",
                                "77.5946"));
                        });
                    } else {
                        callback(new google.maps.LatLng("12.9716", "77.5946"));
                    }
                }
            }

            function drawFixedAsset(fixed, hasParent) {

                $.each(
                    fixed.assetCoordinates,
                    function (index, value1) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }

                        var infoWindow = new google.maps.InfoWindow();
                        if (hasParent) {
                            var startMarker = new google.maps.Marker({

                                map: locations.map,
                                position: new google.maps.LatLng(
                                    value1.latitude,
                                    value1.longitude),
                                title: fixed.name,
                                icon: {
                                    scaledSize: new google.maps.Size(
                                        30, 30),
                                    origin: new google.maps.Point(
                                        0, 0),
                                    url: fixed.assetType.imagePath,
                                    anchor: new google.maps.Point(
                                        16, 16),

                                },
                            });
                        } else {
                            var startMarker = new google.maps.Marker({

                                map: locations.map,
                                position: new google.maps.LatLng(
                                    value1.latitude,
                                    value1.longitude),
                                title: fixed.name,
                                label: {
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    text: fixed.name
                                },
                                icon: {
                                    scaledSize: new google.maps.Size(
                                        30, 30),
                                    origin: new google.maps.Point(
                                        0, 0),
                                    url: fixed.assetType.imagePath,
                                    anchor: new google.maps.Point(
                                        16, 16),

                                },
                            });
                        }
                        if (fixed.status != null &&
                            fixed.status === false) {
                            startMarker.setLabel({
                                color: 'red',
                                fontSize: '30px',
                                fontWeight: '20px',
                                text: '\u2297'
                            });
                        }
                        // Add marker to the array.
                        markers.push(startMarker);

                        var infoWindow2 = new google.maps.InfoWindow();
                        var infoStr = "";
                        var otdr = "";
                        var assetTypeAttributeValuesList = fixed.assetTypeAttributeValues;
                        assetTypeAttributeValuesList.sort(function (a, b) {
                            var nameA = a.name.toLowerCase(),
                                nameB = b.name.toLowerCase()
                            if (nameA < nameB) // sort string ascending
                                return -1
                            if (nameA > nameB)
                                return 1
                            return 0 // default return value (no sorting)
                        });
                        for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                            var home = assetTypeAttributeValuesList[i];
                            infoStr += '<br><b>' +
                                home.name +
                                '</b> : ' +
                                home.attributeValue;
                            otdr = home;
                        }

                        var userContent = document.createElement('div'),
                            button;

                        userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
                            '</div><div class="infoWindowContent">' +
                            '<b>Vendor :</b> ' +
                            fixed.firstName +
                            '<br><b>Description :</b> ' +
                            fixed.description +
                            '<br><b>Lat</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
                            '<br><b>Long</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
                            '<br>' + infoStr + '</div>';

                        button = userContent.appendChild(document.createElement('input'));
                        button.type = 'button';
                        button.value = 'Edit';
                        button.className = "button";
                        button.style.width = '300px';
                        button.style.textAlign = 'right';

                        google.maps.event.addDomListener(button, 'click', function () {
                            $("#userPathModal").show();
                            vm.otdr = otdr;
                            $("#myEditAssetText").html("Edit Asset Name : " + fixed.name);

                            var assetAV = fixed.assetTypeAttributeValues;

                            assetAV.forEach(function (item) {
                                if (item.name == "Fiber Core") {
                                    AllCusAssetTypeAttr.getCusAssetTypeAttr(function (response) {
                                        response.forEach(function (arrayItem) {
                                            if (arrayItem.name == item.assetTypeAttribute.attributeDataType.name) {
                                                vm.customAttributesMap = arrayItem.customAssetTypeAttributeValues;
                                            }
                                        });
                                    });
                                    $scope.selectJustId = item.attributeValue;
                                }
                                if (item.name == "Cable Length From Base") {
                                    $("#otdr-val").val(parseFloat(item.attributeValue));
                                }
                            });
                            try {
                                var len = fixed.ownedBy;
                                var latlngs = [];


                                if (len.length > 0) {
                                    var assetCoordinateValues = len[0].assetCoordinates;
                                    latlngs.push({
                                        lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
                                        lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
                                    });

                                    if (recursiveOwnedByAsset(len) != null) {
                                        Array.prototype.push.apply(latlngs,
                                            recursiveOwnedByAsset(len));
                                    }
                                }

                            } catch (err) {
                                var event232 = err;
                            }
                            editAsset(fixed.id, fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude, fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude, latlngs);
                        });

                        google.maps.event.addListener(startMarker,
                            'dblclick',
                            function () {
                                infoWindow2.close();
                            });

                        var activeInfoWindow;
                        var infoWindow2 = new google.maps.InfoWindow({
                            content: userContent
                        });
                        // add listener on InfoWindow for click
                        // event
                        google.maps.event
                            .addListener(
                                startMarker,
                                'click',
                                function () {

                                    if (prevInfoWindow != null)
                                        prevInfoWindow.close();
                                    // Open InfoWindow - on
                                    // click
                                    infoWindow2.open(locations.map, startMarker);

                                    activeInfoWindow = infoWindow2;
                                    prevInfoWindow = infoWindow2;
                                });
                        openedInfoWindow = infoWindow2;
                    });
            }

            function createMarker(myLatLng, map, index) {
                if (index == 0) {
                    var marker = new google.maps.Marker({
                        position: myLatLng,
                        map: map,
                        icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                        animation: google.maps.Animation.DROP
                    });
                } else {
                    var marker = new google.maps.Marker({
                        position: myLatLng,
                        map: map,
                        icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                    });
                }
            }

            function drawingManagerSpread() {
                vm.drawingManager = new google.maps.drawing.DrawingManager({
                    drawingMode: google.maps.drawing.OverlayType.POLYLINE,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: ['polyline']
                    },
                    polylineOptions: {
                        fillColor: '#ffff00',
                        fillOpacity: 1,
                        strokeWeight: 5,
                        clickable: true,

                        editable: true,
                        zIndex: 1,
                        geodesic: true
                    }
                });
            }

            function drawingManagerValue(id, option, map) {
                vm.asset = null;
                var parent = {};
                var parentAC = []
                for (var i = 0; i < vm.multiassets.length; i++) {
                    if (vm.multiassets[i].id == id) {
                        parent = vm.multiassets[i];
                        parentAC = vm.multiassets[i].assetCoordinates;
                    }
                }
                vm.asset = parent;
                var latlngs = [];
                for (var i = 0; i < parentAC.length; i++) {
                    latlngs.push(new google.maps.LatLng(parentAC[i].latitude, parentAC[i].longitude));
                }

                var deleteMenu = new DeleteMenu();

                drawPolylineNew(function (polyline) {
                    polyline.setMap(map);
                    google.maps.event.addListener(polyline.getPath(),
                        'insert_at',
                        function () {
                            setGoefenceCoordinate(polyline.getPath());
                        });
                    google.maps.event.addListener(polyline.getPath(),
                        'set_at',
                        function () {
                            setGoefenceCoordinate(polyline.getPath());
                        });
                    google.maps.event.addListener(polyline, 'rightclick', function (event) {


                        // Check if click was on a vertex control point
                        if (event.vertex == undefined) {
                            return;
                        }
                        deleteMenu.open(map, polyline.getPath(), event.vertex);
                    });
                    vm.drawingManager.setOptions({
                        drawingControl: false
                    });
                    vm.drawingManager.setMap(null);

                })
            }

            function setValues() {
                vm.coOrdinates = [];

                for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
                    vm.coOrdinates.push({
                        lat: vm.asset.assetCoordinates[i].latitude,
                        lng: vm.asset.assetCoordinates[i].longitude
                    });
                    if (i == vm.asset.assetCoordinates.length - 1) {
                        vm.coOrdinate = JSON.stringify(vm.coOrdinates);
                    }
                }
            }

            function drawPolylineNew(callback) {

                setValues();
                JSON.parse(vm.coOrdinate).forEach(function (value, key) {});
                callback(new google.maps.Polyline({
                    path: JSON.parse(vm.coOrdinate),
                    geodesic: true,
                    strokeColor: '#000000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#000000',
                    fillOpacity: 0.3,
                    editable: true
                }));
            }

            function editAsset(id, lat, lng, latlngs) {

                var ele = document.getElementById("userPathModal");
                $(ele).modal('show');

                var map = new google.maps.Map(document
                    .getElementById('map_canvas_edit_asset'), {
                        center: new google.maps.LatLng(lat, lng),
                        zoom: 18
                    });

                drawingManagerSpread();
                drawingManagerValue(id, 'polylinecomplete', map);
                for (var i = 0; i < latlngs.length; i++) {
                    createMarker(latlngs[i], map, i);
                }

                var multiAssetList = vm.multiassets;
                var latlngs = [];
                for (var i = 0; i < multiAssetList.length; i++) {
                    var assetCoordinateValues = multiAssetList[i].assetCoordinates;
                    latlngs.push({
                        lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
                        lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude,
                        name: multiAssetList[i].name
                    });
                    var myLatLng = [];
                    for (var j = 0; j < assetCoordinateValues.length; j++) {
                        myLatLng.push({
                            lat: assetCoordinateValues[j].latitude,
                            lng: assetCoordinateValues[j].longitude
                        });
                    }
                    if (myLatLng.length > 0) {
                        var midLatLng = {
                            lat: myLatLng[myLatLng.length - 1].lat,
                            lng: myLatLng[myLatLng.length - 1].lng
                        };
                        var infowindow = new google.maps.InfoWindow({
                            content: '<div class="infoWindowhead">' +
                                "name" +
                                '<div class="infoWindowContent"> <b>Description :</b> ' +
                                "des" + '</div></div>'
                        });
                        infowindow.setPosition(midLatLng);
                        var ColorValue = '#F4A500';
                        var polylinepath = new google.maps.Polyline({
                            path: myLatLng,
                            geodesic: true,
                            strokeColor: ColorValue,
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                            fillColor: ColorValue,
                            fillOpacity: 0.3,
                            editable: false
                        });

                        polylinepath.setOptions({
                            strokeColor: ColorValue
                        });

                        polylinepath.setMap(map);
                    }

                }

                for (var i = 0; i < latlngs.length; i++) {
                    var myLatLng = ({
                        lat: latlngs[i].lat,
                        lng: latlngs[i].lng
                    });
                    createMarkerOfAllAssets(myLatLng, map, latlngs[i].name);
                }
                $("#location-map-edit").css("width", "100%");
                $("#map_canvas").css("width", "100%");
            }

            function createMarkerOfAllAssets(myLatLng, map, myLocName) {
                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(
                            24, 24),
                        origin: new google.maps.Point(0, 0),
                        url: 'content/images/yellow.png',
                        anchor: new google.maps.Point(
                            16, 16),

                    },
                    label: {
                        color: 'black',
                        fontWeight: 'medium',
                        fontSize: '12px',
                        text: myLocName,
                        backgroundcolor: "#ff9800"
                    },
                });
            }

            /**
             * A menu that lets a user delete a selected vertex of a path.
             * 
             * @constructor
             */
            function DeleteMenu() {
                this.div_ = document.createElement('div');
                this.div_.className = 'delete-menu';
                this.div_.innerHTML = 'DELETE';

                var menu = this;
                google.maps.event.addDomListener(this.div_, 'click', function () {
                    menu.removeVertex();
                });
            }
            DeleteMenu.prototype = new google.maps.OverlayView();

            DeleteMenu.prototype.onAdd = function () {
                var deleteMenu = this;
                var map = this.getMap();
                this.getPanes().floatPane.appendChild(this.div_);

                // mousedown anywhere on the map except on the menu div will
                // close the
                // menu.
                this.divListener_ = google.maps.event.addDomListener(map.getDiv(), 'mousedown', function (e) {
                    if (e.target != deleteMenu.div_) {
                        deleteMenu.close();
                    }
                }, true);
            };

            DeleteMenu.prototype.onRemove = function () {
                google.maps.event.removeListener(this.divListener_);
                this.div_.parentNode.removeChild(this.div_);

                // clean up
                this.set('position');
                this.set('path');
                this.set('vertex');
            };

            DeleteMenu.prototype.close = function () {
                this.setMap(null);
            };

            DeleteMenu.prototype.draw = function () {
                var position = this.get('position');
                var projection = this.getProjection();

                if (!position || !projection) {
                    return;
                }

                var point = projection.fromLatLngToDivPixel(position);
                this.div_.style.top = point.y + 'px';
                this.div_.style.left = point.x + 'px';
            };

            /**
             * Opens the menu at a vertex of a given path.
             */
            DeleteMenu.prototype.open = function (map, path, vertex) {
                this.set('position', path.getAt(vertex));
                this.set('path', path);
                this.set('vertex', vertex);
                this.setMap(map);
                this.draw();
            };

            /**
             * Deletes the vertex from the path.
             */
            DeleteMenu.prototype.removeVertex = function () {
                var path = this.get('path');
                var vertex = this.get('vertex');

                if (!path || vertex == undefined) {
                    this.close();
                    return;
                }

                path.removeAt(vertex);
                setGoefenceCoordinate(path);
                this.close();
            };

            function setGoefenceCoordinate(path) {

                vm.assetCoordinateValues = [];
                vm.asset.assetCoordinates = [];
                for (var i = 0; i < path.getLength(); i++) {
                    var coordinates = {};
                    coordinates.latitude = path.getAt(i).lat();
                    coordinates.longitude = path.getAt(i).lng();
                    vm.asset.assetCoordinates.push(coordinates);
                }
            }
        }

        function getHeading(lat1, lon1, lat2, lon2) {
            var lat1 = lat1 * Math.PI / 180;
            var lat2 = lat2 * Math.PI / 180;
            var dLon = (lon2 - lon1) * Math.PI / 180;

            var y = Math.sin(dLon) * Math.cos(lat2);
            var x = Math.cos(lat1) * Math.sin(lat2) -
                Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

            var brng = Math.atan2(y, x);

            return (((brng * 180 / Math.PI) + 360) % 360);
        }

        function drawPolyline(spread) {

            var spreadAssetCoordinates = [];
            $.each(spread.assetCoordinates, function (index, value) {

                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });
            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };

                var polylineColor = '';
                var distanceFromPOP = '';
                var fiberCoreVal = '';

                var spreadAttrValues = spread.assetTypeAttributeValues;

                spreadAttrValues.forEach(function (item, index) {
                    if (item.name == "Fiber Color") {
                        if (item.attributeValue == "AboveGround") {
                            polylineColor = '#B22222';
                        } else if (item.attributeValue == "BelowGround") {
                            polylineColor = '#008000';
                        } else {
                            polylineColor = '#000000';
                        }
                    } else if (item.name == "Distance") {
                        distanceFromPOP = item.attributeValue;
                    }
                });

                spreadAttrValues.forEach(function (item, index) {
                    if (item.name == "Asset status") {
                        if (item.attributeValue == "Ducting") {
                            polylineColor = '#ffea00';
                        } else if ((item.attributeValue == "Fiber Pulling") || (item.attributeValue == "Fibering")) {
                            polylineColor = '#ff6b00';
                        } else if ((item.attributeValue == "Fiber live") || (item.attributeValue == "Lightning")) {
                            polylineColor = '#008000';
                        } else if (item.attributeValue == "Digging") {
                            polylineColor = '#BF9878';
                        } else {
                            polylineColor = '#000000';
                        }
                    }
                    if (item.name == "Fiber Core") {
                        fiberCoreVal = item.attributeValue;
                    }
                });

                spreadAttrValues.forEach(function (item, index) {
                    if (item.name == "Type") {
                        if (item.attributeValue == "AG to UG") {
                            polylineColor = '#008000';
                        } else if ((item.attributeValue == "UG to AG")) {
                            polylineColor = '#ff0000';
                        }
                    }
                });

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                });

                if (spread.assetType.layout == "SPREAD") {
                    polylineColor = spread.assetType.colorcode;
                }

				spreadAttrValues.forEach(function (item, index) {
                    if (item.name == "Link Status") {
                        if (item.attributeValue == "True") {
                            polylineColor = '#008000';
                        } else if ((item.attributeValue == "False")) {
                            polylineColor = '#ff0000';
                        }
                    }
                });

                if (polylineColor == "") {
                    polylineColor = '#000000';
                }

                infowindow.setPosition(midLatLng);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                });


                google.maps.event.addListener(locations.map, 'click', function (event) {
                    if ((isPointASelected || isPointBSelected) && $scope.tab == 5) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }

                        var contentForSelection = document.createElement('div'),
                            selectbutton;

                        var pointName = '';

                        if (isPointASelected && !isPointBSelected) {
                            pointName = 'Point A';
                        } else if (isPointASelected && isPointBSelected) {
                            pointName = 'Point B';
                        }

                        contentForSelection.innerHTML = '<div class="infoWindowhead"><b>' + pointName + '</b></div>';

                        selectbutton = contentForSelection.appendChild(document.createElement('input'));
                        selectbutton.type = 'button';
                        selectbutton.value = 'Select';
                        selectbutton.className = "button";

                        google.maps.event.addDomListener(selectbutton, 'click', function () {
                            if (isPointASelected == true && isPointBSelected == false) {
                                pointALoc = {
                                    lat: event.latLng.lat(),
                                    lng: event.latLng.lng()
                                };
                                document.getElementById('pointB').style.borderColor = "#000000";
                                document.getElementById('pointB').style.color = "#000000";
                            } else if (isPointASelected == true && isPointBSelected == true) {
                                pointBLoc = {
                                    lat: event.latLng.lat(),
                                    lng: event.latLng.lng()
                                };
                                document.getElementById('pointResult').style.borderColor = "#000000";
                                document.getElementById('pointResult').style.color = "#000000";
                            }
                        });
                        infowindow = new google.maps.InfoWindow({
                            content: contentForSelection
                        });
                        infowindow.setPosition(event.latLng);
                        infowindow.open(locations.map);
                        openedInfoWindow = infowindow;
                    } else {

                    }
                });
                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    var disVal = 0;
                    var disFromParent = 0;
                    var cLocations = [];
                    vm.multiassets.forEach(function (item, index) {
                        if (spread.id == vm.multiassets[index].id) {
                            cLocations = vm.multiassets[index].assetCoordinates;
                        }
                    });

                    if (cLocations.length > 0) {
                        var isMouseDetected = false;
                        for (var index in cLocations) {
                            if (index > 0) {
                                if (!isMouseDetected) {
                                    var left = getHeading(cLocations[index].latitude, cLocations[index].longitude, event.latLng.lat(), event.latLng.lng());
                                    var right = getHeading(cLocations[index - 1].latitude, cLocations[index - 1].longitude, event.latLng.lat(), event.latLng.lng());
                                    if ((right - left) > 100) {
                                        isMouseDetected = true;
                                        disVal += distanceCal(event.latLng.lat(), event.latLng.lng(), cLocations[index - 1].latitude, cLocations[index - 1].longitude);
                                    } else {
                                        disVal += distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude);
                                    }
                                }

                                disFromParent += distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude);
                            }

                        }
                    }

                    if (distanceFromPOP == "") {
                        distanceFromPOP = "0";
                    }

                    var popName = "";

                    if (spread.ownedBy.length > 0) {
                        var parentVal = spread.ownedBy;
                        if (parentVal != null) {
                            if (parentVal.length > 0) {
                                popName = recursiveOwnedByPopName(parentVal, vm.multiassets);
                            } else {
                                popName = spread.name;
                            }
                        } else {
                            popName = spread.name;
                        }
                    } else {
                        popName = spread.name;
                    }


                    var disFmParentMeters = (parseFloat(disVal) * 1000).toFixed(4);
                    var disFmPopMeters = parseFloat((distanceFromPOP - (parseFloat(disFromParent) * 1000)) + (parseFloat(disVal) * 1000)).toFixed(4);
                    var disFmParentMiles = disFmParentMeters * 0.000621371;
                    var disFmPopMiles = disFmPopMeters * 0.000621371;

                    if (spread.ownedBy != null) {
                        if (spread.ownedBy.length > 0) {
                            infowindow.setContent('<div class="infoWindowhead">' +
                                '<b>Name : </b>' + spread.name + '</br>' +
                                '<b>Distance From ' + spread.ownedBy[0].name + ' (Parent) : </b>' + disFmParentMeters + ' m ( ' + disFmParentMiles.toFixed(4) + ' mi )</br>' +
                                '<b>Distance From  ' + popName + ' (POP) : </b>' + disFmPopMeters + ' m ( ' + disFmPopMiles.toFixed(4) + ' mi )</br>' +
                                '<b>Fiber Core : </b>' + fiberCoreVal + '</div>'
                            );
                        } else {
                            infowindow.setContent('<div class="infoWindowhead">' +
                                '<b>Name : </b> ' + spread.name + '</br>' +
                                '<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>' +
                                '</div>'
                            );
                        }
                    } else {
                        infowindow.setContent('<div class="infoWindowhead">' +
                            '<b>Name : </b> ' + spread.name + '</br>' +
                            '<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>' +
                            '</div>'
                        );
                    }

                    infowindow.setPosition(event.latLng);
                    infowindow.open(locations.map);
                    openedInfoWindow = infowindow;

                });

                if (spread.status != null && spread.status === false) {
                    polylinepath.setOptions({
                        strokeColor: polylineColor
                    });
                }

                polylinepath.setMap(locations.map);

                polylines.push(polylinepath);
            }
        }
        var recursiveOwnedByPopName = function (childVal, multiassets) {
            var popNameVal = "";
            var child = childVal[0];
            multiassets.forEach(function (item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    popNameVal = recursiveOwnedByPopName(parentVal, multiassets);
                } else {
                    popNameVal = child.name;
                }
            } else {
                popNameVal = child.name;
            }
            return popNameVal;
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }


        function moveToLocation(lat, lng, map) {
            var center = new google.maps.LatLng(lat, lng);
            map.panTo(center);
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        var greenPolylineLatLng = [];
        var redPolylineLatLng = [];
        var latlngs = [];
        var redLatLngs = [];
        $(document).ready(function () {
            var map = null;
            var myLatlng;

            $('#mapModalForFiberTracking').on('show.bs.modal', function (event) {

                var fiberDefectLength = parseFloat(document.getElementById("distanceValue").value).toFixed(2);
                var button = $(event.relatedTarget);
                var child;
                var parent;

                var multiassets = vm.multiassets;
                latlngs = [];
                redLatLngs = [];
                var isValidLength = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAssetMap) {
                        if (isFromAssetMapSelected) {
                            var parentCableLengthFromToAsset = 0.0;
                            child = multiassets[i];
                            if (isNaN(fiberDefectLength)) {
                                alert("Enter Value");
                                child = null;
                            }
                            for (var j = 0; j < multiassets.length; j++) {
                                if (multiassets[j].id == selectedToAssetMap) {
                                    var childAVToAsset = multiassets[j].assetTypeAttributeValues;

                                    childAVToAsset.forEach(function (item, index) {
                                        if (item.name == "Cable length") {
                                            parentCableLengthFromToAsset = parseFloat(item.attributeValue);
                                        }
                                    });
                                }
                            }
                            if (child != null) {
                                var childAV;
                                var parentCableLength = 0.0;
                                multiassets.forEach(function (item, index) {
                                    if (child.id == multiassets[index].id) {
                                        childAV = multiassets[index].assetTypeAttributeValues;
                                    }
                                });
                                childAV.forEach(function (item, index) {
                                    if (item.name == "Cable length") {
                                        parentCableLength = parseFloat(item.attributeValue);
                                    }
                                });
                                if (fiberDefectLength > (parentCableLength - parentCableLengthFromToAsset) || fiberDefectLength < 0) {
                                    alert("Please enter a valid length");
                                    isValidLength = false;
                                }
                            }
                            vm.generatedAssetName = child.name;
                            latlngs.push({
                                lat: child.lat,
                                lng: child.lng,
                                name: child.name
                            });
                            redLatLngs.push({
                                lat: child.lat,
                                lng: child.lng,
                                name: child.name
                            });

                            myLatlng = new google.maps.LatLng(child.lat, child.lng);
                            var myOptions = {
                                zoom: 18,
                                zoomControl: true,
                                center: myLatlng,
                                mapTypeId: google.maps.MapTypeId.ROADMAP
                            };
                            map = new google.maps.Map(document.getElementById("map_canvas_for_fiber_tracking"), myOptions);

                        }
                    }
                }
                greenPolylineLatLng = [];
                if (child == null) {
                    alert("No asset selected");
                }
                if (!isValidLength) {
                    child = null;
                }
                parent = child.ownedBy;
                if (parent.length > 0) {
                    if (parent[0].id != selectedToAssetMap) {
                        getParentChildCalVal(child, parent[0], multiassets, fiberDefectLength);
                        recursiveOwnedByAssetAttribute(parent, multiassets, fiberDefectLength);
                    } else {
                        getParentChildCalVal(child, parent[0], multiassets, fiberDefectLength);
                    }
                }

                for (var i = 0; i < latlngs.length; i++) {
                    createMarker(latlngs[i], map, i, "green");
                }

                for (var i = 0; i < redLatLngs.length; i++) {
                    createMarker(redLatLngs[i], map, i, "red");
                }


                $("#location-map").css("width", "100%");
                $("#map_canvas").css("width", "100%");
            });

            var recursiveOwnedByAssetAttribute = function (childVal, multiassets, fiberDefectLength) {
                var child = childVal[0];
                multiassets.forEach(function (item, index) {
                    if (child.id == multiassets[index].id) {
                        child = multiassets[index];
                    }
                });
                var parentVal = child.ownedBy;

                if (parentVal != null) {
                    if (parentVal.length > 0) {
                        getParentChildCalVal(child, parentVal[0], multiassets, fiberDefectLength);
                        recursiveOwnedByAssetAttribute(parentVal, multiassets, fiberDefectLength);
                    }
                }
            };

            // Trigger map resize event after modal shown
            $('#mapModalForFiberTracking').on('shown.bs.modal', function () {
                google.maps.event.trigger(map, "resize");
            });

            var recursiveOwnedByAssetsVal = function (childVal, multiassets) {
                var child = childVal[0];
                multiassets.forEach(function (item, index) {
                    if (child.id == multiassets[index].id) {
                        child = multiassets[index];
                    }
                });
                var parentVal = child.ownedBy;

                if (parentVal != null) {
                    if (parentVal.length > 0) {
                        if (selectedToAsset != parentVal[0].id) {
                            parentAssetList.push(recursiveOwnedByAssetsVal(parentVal, multiassets));
                        } else {
                            multiassets.forEach(function (item, index) {
                                if (parentVal[0].id == multiassets[index].id) {
                                    parentAssetList.push(multiassets[index]);
                                }
                            });
                        }
                    }
                }
                return child;
            };



            $('#mapModalForFiberTrackingAsset').on('show.bs.modal', function (event) {
                var child;
                var parent;
                parentAssetList = [];
                var PolylineLatLng = [];


                var multiassets = vm.multiassets;
                latlngs = [];
                redLatLngs = [];
                var isValidLength = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAsset) {
                        if (isFromAssetSelected) {
                            child = multiassets[i];
                            if (child != null) {
                                var childAV;
                                var parentCableLength = 0.0;
                                multiassets.forEach(function (item, index) {
                                    if (child.id == multiassets[index].id) {
                                        childAV = multiassets[index].assetTypeAttributeValues;
                                    }
                                });
                            }


                            myLatlng = new google.maps.LatLng(child.lat, child.lng);
                            var myOptions = {
                                zoom: 16,
                                zoomControl: true,
                                center: myLatlng,
                                mapTypeId: google.maps.MapTypeId.ROADMAP
                            };
                            map = new google.maps.Map(document.getElementById("map_canvas_for_fiber_tracking_asset"), myOptions);

                        }
                    }
                }
                $("#assetDistanceVal").html("Distance : " + assetFromToDistance.toFixed(2));
                if (child == null) {
                    alert("No from asset selected");
                }
                if (!isFromAssetSelected) {
                    child = null;
                }

                parent = child.ownedBy;
                if (!isToAssetSelected) {
                    parent = null;
                    alert("No to asset selected");
                }
                if (parent.length > 0) {
                    if (selectedToAsset != parent[0].id) {
                        parentAssetList.push(recursiveOwnedByAssetsVal(parent, multiassets));
                    } else {
                        multiassets.forEach(function (item, index) {
                            if (parent[0].id == multiassets[index].id) {
                                parentAssetList.push(multiassets[index]);
                            }
                        });
                    }
                }
                parentAssetList.push(child);

                for (var i = 0; i < parentAssetList.length; i++) {
                    createMarker(parentAssetList[i], map, i, "icon");
                    if (i > 0) {
                        var parentAC = parentAssetList[i].assetCoordinates;
                        if (parentAC.length > 1) {
                            for (var iterable_element in parentAC) {
                                PolylineLatLng.push({
                                    lat: parentAC[iterable_element].latitude,
                                    lng: parentAC[iterable_element].longitude,
                                    name: parentAssetList[i].name
                                });
                            }
                        } else {
                            PolylineLatLng.push({
                                lat: parentAssetList[i].lat,
                                lng: parentAssetList[i].lng,
                                name: parentAssetList[i].name
                            });
                        }
                    }
                }
                for (var i = 0; i < PolylineLatLng.length; i++) {
                    if (i > 0) {
                        var locValues = [];
                        locValues.push(PolylineLatLng[i - 1]);
                        locValues.push(PolylineLatLng[i]);
                        drawPolylineForGeneratedLoc(locValues, map, "black");
                    }
                }
                $("#location-map-asset").css("width", "100%");
                $("#map_canvas").css("width", "100%");
            });

            function getParentChildCalVal(child, parent, multiassets, fiberDefectLength) {

                var childAV;
                var parentAV;
                var parentAC;
                var parentDistance = 0.0;
                var childDistance = 0.0;

                var parentCableLength = 0.0;
                var childCableLength = 0.0;
                var parentDistanceToAsset = 0.0;
                var parentCableLengthToAsset = 0.0;

                latlngs.push({
                    lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                    lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                    name: parent.name
                });
                multiassets.forEach(function (item, index) {
                    if (parent.id == multiassets[index].id) {
                        parentAV = multiassets[index].assetTypeAttributeValues;
                        parentAC = multiassets[index].assetCoordinates;
                    }
                });

                multiassets.forEach(function (item, index) {
                    if (child.id == multiassets[index].id) {
                        childAV = multiassets[index].assetTypeAttributeValues;
                    }
                });

                if (parent.id != selectedToAssetMap) {
                    parentAV.forEach(function (item, index) {
                        if (item.name == "Distance") {
                            parentDistance = parseFloat(item.attributeValue);
                        }

                        if (item.name == "Cable length") {
                            parentCableLength = parseFloat(item.attributeValue);
                        }

                    });
                } else {
                    parentAV.forEach(function (item, index) {
                        if (item.name == "Distance") {
                            parentDistanceToAsset = parseFloat(item.attributeValue);
                        }

                        if (item.name == "Cable length") {
                            parentCableLengthToAsset = parseFloat(item.attributeValue);
                        }

                    });
                }

                childAV.forEach(function (item, index) {
                    if (item.name == "Distance") {
                        childDistance = parseFloat(item.attributeValue);
                    }

                    if (item.name == "Cable length") {
                        childCableLength = parseFloat(item.attributeValue);
                    }
                });

                var cableDiff = parseFloat(childCableLength) - parseFloat(parentCableLength);
                var distanceDiff = parseFloat(childDistance) - parseFloat(parentDistance);
                if (parseFloat(fiberDefectLength) >= parseFloat(parentCableLength)) {
                    greenPolylineLatLng = [];
                    if (child.assetCoordinates.length > 1) {
                        for (var iterable_element in parentAC) {
                            greenPolylineLatLng.push({
                                lat: parentAC[iterable_element].latitude,
                                lng: parentAC[iterable_element].longitude,
                                name: parent.name
                            });
                        }
                    } else {
                        greenPolylineLatLng.push({
                            lat: child.assetCoordinates[child.assetCoordinates.length - 1].latitude,
                            lng: child.assetCoordinates[child.assetCoordinates.length - 1].longitude,
                            name: child.name
                        });
                        if (parentAC.length > 0) {
                            greenPolylineLatLng.push({
                                lat: parentAC[parentAC.length - 1].latitude,
                                lng: parentAC[parentAC.length - 1].longitude,
                                name: parent.name
                            });
                        }
                    }

                    for (var i = 0; i < greenPolylineLatLng.length; i++) {
                        if (i > 0) {
                            var locValues = [];
                            locValues.push(greenPolylineLatLng[i - 1]);
                            locValues.push(greenPolylineLatLng[i]);
                            drawPolylineForGeneratedLoc(locValues, map, "green");
                        }
                    }
                    if (parseFloat(fiberDefectLength) <= parseFloat(childCableLength)) {
                        var issueCableDiff = parseFloat(fiberDefectLength) - parseFloat(parentCableLength);
                        var leftLoopLength = 0.0;
                        var rightLoopLength = 0.0;

                        childAV.forEach(function (item, index) {
                            if (item.name == "Left Loop Length") {
                                leftLoopLength = parseFloat(item.attributeValue);
                            } else if (item.name == "Right Loop Length") {
                                rightLoopLength = parseFloat(item.attributeValue);
                            }
                        });

                        var prevOTDR = parseFloat(childCableLength) - cableDiff;
                        var leftFiberCutDistance = parseFloat(fiberDefectLength) - leftLoopLength;
                        var issueDistance = 0.0;
                        if (leftFiberCutDistance <= (childCableLength - cableDiff)) {
                            issueDistance = 0.0;
                        } else if (parseFloat(fiberDefectLength) > (parseFloat(childCableLength) - rightLoopLength)) {
                            issueDistance = distanceDiff - 0.2;
                        } else {
                            var percentage = ((issueCableDiff - leftLoopLength) * 100) / (cableDiff - (leftLoopLength + rightLoopLength));
                            issueDistance = (distanceDiff / 100) * parseFloat(percentage);
                        }

                        var dis = 0.0;
                        var cLocations = child.assetCoordinates;
                        var isDistanceValid = false;

                        if (cLocations.length > 1) {
                            redPolylineLatLng = [];
                            greenPolylineLatLng = [];
                            for (var index in cLocations) {
                                var prevDis = dis * 1000;
                                var disBetweenPoints = 0.0;
                                if (index >= 1) {
                                    dis += distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude);
                                } else if (index == 0) {
                                    dis += distanceCal(cLocations[index].latitude, cLocations[index].longitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude);
                                }
                                if (issueDistance < (dis * 1000)) {
                                    if (!isDistanceValid) {
                                        var issueDisVal = issueDistance - prevDis;
                                        var newLocVal = {
                                            lat: 0,
                                            lng: 0,
                                            name: 'name'
                                        };
                                        if (index >= 1) {
                                            disBetweenPoints = (distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude) * 1000);
                                            newLocVal = calNewLatLng(child.name, parent.name, cLocations[index - 1].latitude, cLocations[index - 1].longitude, cLocations[index].latitude, cLocations[index].longitude, issueDisVal);
                                        } else if (index == 0) {
                                            disBetweenPoints = (distanceCal(cLocations[index].latitude, cLocations[index].longitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude) * 1000);
                                            newLocVal = calNewLatLng(child.name, parent.name, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude, cLocations[index].latitude, cLocations[index].longitude, issueDisVal);
                                        }
                                        if (newLocVal.lat > 0) {
                                            greenPolylineLatLng.push(newLocVal);
                                        }
                                        isDistanceValid = true;
                                    } else {
                                        redPolylineLatLng.push({
                                            lat: cLocations[index - 1].latitude,
                                            lng: cLocations[index - 1].longitude,
                                            name: child.name
                                        });
                                        redPolylineLatLng.push({
                                            lat: cLocations[index].latitude,
                                            lng: cLocations[index].longitude,
                                            name: child.name
                                        });
                                    }
                                } else {
                                    if (index >= 1) {
                                        greenPolylineLatLng.push({
                                            lat: cLocations[index - 1].latitude,
                                            lng: cLocations[index - 1].longitude,
                                            name: child.name
                                        });
                                        greenPolylineLatLng.push({
                                            lat: cLocations[index].latitude,
                                            lng: cLocations[index].longitude,
                                            name: child.name
                                        });
                                    }
                                }
                            }
                            for (var i = 0; i < redPolylineLatLng.length; i++) {
                                if (i > 0) {
                                    var locValues = [];
                                    locValues.push(redPolylineLatLng[i - 1]);
                                    locValues.push(redPolylineLatLng[i]);
                                    drawPolylineForGeneratedLoc(locValues, map, "red");
                                }
                            }
                            for (var i = 0; i < greenPolylineLatLng.length; i++) {
                                if (i > 0) {
                                    var locValues = [];
                                    locValues.push(greenPolylineLatLng[i - 1]);
                                    locValues.push(greenPolylineLatLng[i]);
                                    drawPolylineForGeneratedLoc(locValues, map, "green");
                                }
                            }
                        } else {
                            calNewLatLng(child.name, parent.name, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude, child.assetCoordinates[child.assetCoordinates.length - 1].latitude, child.assetCoordinates[child.assetCoordinates.length - 1].longitude, issueDistance);
                        }
                    }
                } else {
                    redLatLngs.push({
                        lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                        lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                        name: parent.name
                    });

                    redPolylineLatLng = [];
                    if (child.assetCoordinates.length > 1) {
                        for (var iterable_element in child.assetCoordinates) {
                            redPolylineLatLng.push({
                                lat: child.assetCoordinates[iterable_element].latitude,
                                lng: child.assetCoordinates[iterable_element].longitude,
                                name: child.name
                            });
                        }
                        for (var i = 0; i < redPolylineLatLng.length; i++) {
                            if (i > 0) {
                                var locValues = [];
                                locValues.push(redPolylineLatLng[i - 1]);
                                locValues.push(redPolylineLatLng[i]);
                                drawPolylineForGeneratedLoc(locValues, map, "red");
                            }
                        }
                    } else {
                        redPolylineLatLng.push({
                            lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                            lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                            name: parent.name
                        });
                        redPolylineLatLng.push({
                            lat: child.assetCoordinates[child.assetCoordinates.length - 1].latitude,
                            lng: child.assetCoordinates[child.assetCoordinates.length - 1].longitude,
                            name: child.name
                        });
                        for (var i = 0; i < redPolylineLatLng.length; i++) {
                            if (i > 0) {
                                var locValues = [];
                                locValues.push(redPolylineLatLng[i - 1]);
                                locValues.push(redPolylineLatLng[i]);
                                drawPolylineForGeneratedLoc(locValues, map, "red");
                            }
                        }
                    }
                }
            }

            function createMarker(myLatLng, map, index, color) {
                if (myLatLng.typeName != "Point") {
                    if (color == "red") {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            title: myLatLng.name
                        });
                    } else if (color == "icon") {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: {
                                scaledSize: new google.maps.Size(
                                    30, 30),
                                origin: new google.maps.Point(
                                    0, 0),
                                anchor: new google.maps.Point(
                                    16, 16),

                            },
                            title: myLatLng.name
                        });
                    } else {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                            title: myLatLng.name
                        });
                    }
                    var markerContent = '<div class="infoWindowhead">' +
                        myLatLng.name +
                        '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                        myLatLng.lat +
                        '<br> <b>Lng :</b> ' +
                        myLatLng.lng +
                        '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + myLatLng.lat + ',' + myLatLng.lng + '>Go To</a></b>' +
                        '</div>';

                    var infowindow = new google.maps.InfoWindow({
                        content: markerContent
                    });
                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });
                }
            }

            function createImageMarker(myLatLng, map, imgPath) {
                var marker = new google.maps.Marker({

                    map: locations.map,
                    position: new google.maps.LatLng(
                        myLatLng.lat,
                        myLatLng.lng),
                    title: myLatLng.name,
                    label: {
                        color: '#000000',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        text: myLatLng.name
                    },
                    icon: {
                        scaledSize: new google.maps.Size(
                            30, 30),
                        origin: new google.maps.Point(
                            0, 0),
                        anchor: new google.maps.Point(
                            16, 16),

                    },
                });
                var markerContent = '<div class="infoWindowhead">' +
                    myLatLng.name +
                    '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                    myLatLng.lat +
                    '<br> <b>Lng :</b> ' +
                    myLatLng.lng +
                    '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + myLatLng.lat + ',' + myLatLng.lng + '>Go To</a></b>' +
                    '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content: markerContent
                });
                marker.addListener('click', function () {
                    infowindow.open(map, marker);
                });
            }


            function initializeGMap(lat, lng) {

                myLatlng = new google.maps.LatLng(lat, lng);
                var markerContent = '<div class="infoWindowhead">' +
                    "Generated Location" +
                    '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                    lat +
                    '<br> <b>Lng :</b> ' +
                    lng +
                    '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng + '>Go To</a></b>' +
                    '</div>';

                var marker = new google.maps.Marker({
                    position: myLatlng,
                    animation: google.maps.Animation.DROP,
                    map: map
                });
                var infowindow = new google.maps.InfoWindow({
                    content: markerContent
                });
                marker.addListener('click', function () {
                    infowindow.open(map, marker);
                });
            }

            function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
                var spreadAssetCoordinates = [];
                $.each(latlngsToDraw, function (index, value) {

                    spreadAssetCoordinates.push({
                        lat: value.lat,
                        lng: value.lng
                    });
                });

                if (spreadAssetCoordinates.length > 0) {
                    var midLatLng = {
                        lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                        lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                    };
                    var infowindow = new google.maps.InfoWindow({
                        content: '<div class="infoWindowhead">' +
                            "name" +
                            '<div class="infoWindowContent"> <b>Description :</b> ' +
                            "des" + '</div></div>'
                    });
                    infowindow.setPosition(midLatLng);
                    var ColorValue = '#ff0000';
                    if (color == "red") {
                        ColorValue = '#ff0000';
                    } else if (color == "black") {
                        ColorValue = '#000000';
                    } else {
                        ColorValue = '#208000';
                    }
                    var polylinepath = new google.maps.Polyline({
                        path: spreadAssetCoordinates,
                        geodesic: true,
                        strokeColor: ColorValue,
                        strokeOpacity: 1.0,
                        strokeWeight: 2.5,
                        fillColor: ColorValue,
                        fillOpacity: 0.3,
                        editable: false
                    });

                    polylinepath.setOptions({
                        strokeColor: ColorValue
                    });

                    polylinepath.setMap(map);
                }
            }

            function calNewLatLng(childName, parentName, lat1, lng1, lat2, lng2, issueDistance) {
                vm.parentName = parentName;
                vm.childName = childName;

                var dLon = (lng2 - lng1);
                var y = Math.sin(dLon) * Math.cos(lat2);
                var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
                var bearing = radians_to_degrees((Math.atan2(y, x)));
                bearing = (360 - ((bearing + 360) % 360));

                var earthRadius = 6378.1;
                var bearingR = -degrees_to_radians(bearing);
                var latR = degrees_to_radians(lat1);
                var lonR = degrees_to_radians(lng1);
                var distanceToRadius = (issueDistance / 1000) / earthRadius;
                var newLatR = Math.asin(Math.sin(latR) * Math.cos(distanceToRadius) +
                    Math.cos(latR) * Math.sin(distanceToRadius) * Math.cos(bearingR));
                var newLonR = lonR + Math.atan2(Math.sin(bearingR) * Math.sin(distanceToRadius) * Math.cos(latR),
                    Math.cos(distanceToRadius) - Math.sin(latR) * Math.sin(newLatR));
                var latNew = radians_to_degrees(newLatR);
                var lonNew = radians_to_degrees(newLonR);
                vm.generatedLat = latNew;
                vm.generatedLng = lonNew;
                var newLatLng = {
                    lat: latNew,
                    lng: lonNew,
                    name: "New Location",
                    typeName: "New Location"
                };
                initializeGMap(latNew, lonNew);
                var myLatLngPos = {
                    lat: latNew,
                    lng: lonNew
                };
                map.setCenter(myLatLngPos);
                var locValues = [];
                locValues.push({
                    lat: latNew,
                    lng: lonNew,
                    name: childName
                });
                locValues.push({
                    lat: lat2,
                    lng: lng2,
                    name: childName
                });
                drawPolylineForGeneratedLoc(locValues, map, "red");
                return {
                    lat: latNew,
                    lng: lonNew,
                    name: childName
                };
            }
        });
    }


})();
