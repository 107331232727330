(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GeofenceImportController', GeofenceImportController)
    .directive("fileread", [function () {
    	'use strict';

        return {
            restrict: "A",

            link: function($scope, element){

                element.change(function(event){
                    $scope.$apply(function(){
                      $scope.myFile =element[0].files[0];
                      
                    });
                });
            }

        };
}]);

    GeofenceImportController.$inject = ['$timeout', '$scope','GeofenceUpload','AllAssetsTypes'];

    function GeofenceImportController ($timeout, $scope, GeofenceUpload,AllAssetsTypes) {
        var vm = this;

        vm.upload= upload;
        vm.assetType;
        vm.assettypes = loadPage();
        
        function loadPage() {
			AllAssetsTypes.getAll(function(response) {
				vm.assettypes = response;
			});
		}
        
		 var formData;
	        function upload(){
	        	formData=new FormData();
	        	formData.append('file',$scope.myFile);
	        
	        	GeofenceUpload.geofenceUpload(formData,vm.assetType,function(response,headers){
	        		if(response.status == 200){
	             		vm.error=null;
	             		vm.success='OK';
	             		
	            		$timeout(function(){
	            			vm.success= '';
	            		},4000);
	            		
	            		
	             	} 
	             	if(response.status == 400){
	             		vm.success=null;
	             		vm.error='error';
	             			
	             	} 
	             });
	        	
	        }
			$(":file").filestyle({buttonBefore: true});      
			$(":file").filestyle('buttonText', 'Browse File');
		

    }
})();
