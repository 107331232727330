(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AssetDetailController', AssetDetailController);

    AssetDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity', 'AssetImages'];

    function AssetDetailController($scope, $rootScope, previousState, entity, AssetImages) {
        var vm = this;

        vm.asset = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;

        var unsubscribe = $rootScope.$on('trakeyeApp:assetUpdate', function(result) {
            vm.asset = result;
        });
        $scope.$on('$destroy', unsubscribe);
        $scope.go_back = function() { 
        	  $window.history.back();
        	};

        $scope.allimages = false;
        $scope.toggle = function(){
        	$scope.allimages = !$scope.allimages;
        	
        	if ($scope.allimages){
        		AssetImages.query({
        			assetId : vm.asset.id
        		}, onSuccess,onError);
        		
        		function onSuccess(data){
        			vm.assetImages = data;
        		}
        		
        		function onError(error){
        			AlertService.error(error.data.message);
        		}
        		
        	}
        	
        }
        
        function decodeImage(img){
        	return window.atob(img);
        }
    }
})();
