(function() {
	'use strict';

	angular.module('trakeyeApp').controller('CaseReportController', CaseReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    	};
	}]);

	CaseReportController.$inject = [ '$scope','$sce' ,'$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval' ];

	function CaseReportController($scope, $sce,$state, Report, $rootScope, $timeout, $window, $filter, $interval) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.reportTypeChange = reportTypeChanged;
		vm.users = [];
		vm.batteryusers = [];
		vm.geofences = [];
		$scope.reporthide = false;
		vm.select2 = select2;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		// vm.today();
		vm.select2();
		Report.getUsers('', function(response) {
			vm.batteryusers = response;

		})

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		
		//vm.detailedReport = detailedReport;

		vm.selecterange = selectRange;
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		function selectRange(range) {
			if (range == 'week') {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7)
				vm.fromTime = date.getTime();

			} else if (range == 'month') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			} else if (range == 'year') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setYear(date.getFullYear() - 1);
				vm.fromTime = date.getTime();
			}
		}
		function select2() {
			$(".select2").select2();
		}
		function reportTypeChanged() {
			$scope.reporthide = false;
			if (vm.reportType == 'AGENT') {
				if (vm.users.length == 0) {
					Report.getUsers('', function(response) {
						vm.users = response;
					})
				}
			} else if (vm.reportType == 'GEOFENCE') {
				if (vm.geofences.length == 0) {
					Report.getGeofences('', function(response) {
						vm.geofences = response;
					})
				}
			}

		}
		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		
		
		$scope.report = function() {
			if ((vm.reportType == 'GEOFENCE' && vm.geofence == 'all') || (vm.reportType == 'AGENT' && vm.userAgent == 'all')) {
				vm.id = 0;
			}
				Report.getCasesReport({
					"fromDateTime":vm.fromTime,
					"toDateTime":vm.toTime,
					"caseReportType":vm.reportType
				}, function(response) {
					
					$scope.myhtml = response;
					
							
				});
				
			

		}
		
	}

})();
