(function() {
	'use strict';
	angular.module('trakeyeApp').factory('Asset', Asset);

	Asset.$inject = [ '$resource' ];

	function Asset($resource) {
		var resourceUrl = 'api/assets/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetImages', AssetImages);

	AssetImages.$inject = [ '$resource' ];

	function AssetImages($resource) {
		var service = $resource('/api/asset-images/:assetId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetUser', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.gettrcasesuser = function(callback) {
			$http.get('api/location-logs/latest').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetSearchForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.searchassetsformap = function(search, callback) {
			$http.get('api/assets/searchformap/' + search).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetsForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.assetsformap = function(callback) {
			$http.get('api/assetsformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetsForMapByAdmin', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.assetsformapbyadmin = function(adminid, callback) {
			$http.get('api/assetsformap/' + adminid).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllAssetsTypes', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/asset-alltypes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetSearch', AssetSearch);

	AssetSearch.$inject = [ '$resource' ];

	function AssetSearch($resource) {
		var service = $resource('/api/assets/searchvalue/:search', {
		// searchvalue : '@id'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';
	angular.module('trakeyeApp').factory('AssetUpload', AssetUpload);

	AssetUpload.$inject = [ '$http' ];

	function AssetUpload($http) {

		var service = {};
		service.assetupload = function(data, callback) {

			$http.post('api/assetsimport', data, {
				transformRequest : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetIDs', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function( callback) {
			$http.get('api/assetlistnew').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllCusAssetTypeAttr', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getCusAssetTypeAttr = function(callback) {
			$http.get('api/all-custom-asset-type-attributes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AssetByID', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.get = function(id, callback) {
			$http.get('api/assets/'+id).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('GeoAssetTypeReport', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAssetTypesByGeo = function(callback) {
			$http.get('api/reports/geoassettypereport').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();