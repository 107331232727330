(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetTypeGeofenceReportController', AssetTypeGeofenceReportController)

	AssetTypeGeofenceReportController.$inject = [ '$scope', '$state', 'GeoAssetTypeReport', '$rootScope', '$timeout', '$window', '$filter', '$interval' ];

	function AssetTypeGeofenceReportController($scope, $state, GeoAssetTypeReport, $rootScope, $timeout, $window, $filter, $interval) {
		var vm = this;
		vm.multiassets = [];
		var result = [];
		var grouped = {};
		 GeoAssetTypeReport.getAssetTypesByGeo(function (response){
			
			 
			 response.forEach(function (a) {
                 grouped[a.geofenceId] = grouped[a.geofenceId] || [];
                 grouped[a.geofenceId].push({
                	 geofenceId: a.geofenceId,
                	 geofence: a.geofence,
                	 assetType: a.assetType,
                	 assetTypeCount: a.assetTypeCount
                 });
                 result = Object.values(grouped);
                 
                
             });
			 result.forEach(function (item) {
            	 var wdmCount = 0;
                 var nttnCount = 0;
                 var othersCount = 0;	
                 var backtobackCount = 0;
                 var routerCount = 0;
                 var name = "";
                 var id = "";
            	 item.forEach(function(item1){
            		 id = item1.geofenceId;
            		 name = item1.geofence;
            		if(item1.assetType == 'WDM'){
            			wdmCount = item1.assetTypeCount;
            		}else if(item1.assetType == 'NTTN'){
            			nttnCount = item1.assetTypeCount;
            		}else if(item1.assetType == 'Router'){
            			routerCount = item1.assetTypeCount;
            		}else if(item1.assetType == 'BackToBack'){
            			backtobackCount = item1.assetTypeCount;
            		}else if(item1.assetType == 'Others'){
            			othersCount = item1.assetTypeCount;
            		}
            	 });
            	 vm.multiassets.push({id:id, name: name, WDM:wdmCount,NTTN: nttnCount, Router:routerCount,BackToBack:backtobackCount,Others:othersCount});
            	 
             });
         });
	}

})();